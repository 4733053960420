import { Box, Drawer, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
const getLevelChipColor = (level) => {
    switch (level?.toLowerCase()) {
      case 'urgent':
        return {
          backgroundColor: '#FFE0E0',
          color: '#D32F2F',
          borderColor: '#FFC7C7'
        };
      case 'high':
        return {
          backgroundColor: '#FFF0E0',
          color: '#ED6C02',
          borderColor: '#FFE2C7'
        };
      case 'medium':
        return {
          backgroundColor: '#E3F2FD',
          color: '#1976D2',
          borderColor: '#BBDEFB'
        };
      case 'low':
      default:
        return {
          backgroundColor: '#E8F5E9',
          color: '#2E7D32',
          borderColor: '#C8E6C9'
        };
    }
  };
  
  // Add this function to get state chip colors
  const getStateChipColor = (state) => {
    switch (state?.toLowerCase()) {
      case 'develop':
        return {
          backgroundColor: '#FFF7E6',
          color: '#B76E00',
          borderColor: '#FFE2B7'
        };
      case 'qa':
        return {
          backgroundColor: '#EDF7FF',
          color: '#0065BD',
          borderColor: '#B7DBFF'
        };
      case 'done':
        return {
          backgroundColor: '#E6FFE6',
          color: '#006100',
          borderColor: '#B7FFB7'
        };
      case 'planning':
      default:
        return {
          backgroundColor: '#F3E8FF',
          color: '#5B14B8',
          borderColor: '#E2C6FF'
        };
    }
  };
  
function ActivityDetalisModal({isDrawerOpen,setIsDrawerOpen,selectedActivity}) {
    console.log("The selected activity is::::",selectedActivity)
  return (
    <Drawer
    anchor="right"
    open={isDrawerOpen}
    onClose={() => setIsDrawerOpen(false)}
    sx={{
      '& .MuiDrawer-paper': {
        width: '40%',
        maxWidth: '600px',
        px: 3,
        py: 2,
        mt:10
      }
    }}
  >
    {selectedActivity && (
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Box>
            <Typography variant="h6" component="h2">
              {selectedActivity.title}
            </Typography>
            <Typography color="text.secondary" variant="body2">
              Added on {new Date(selectedActivity.createdAt).toLocaleDateString()}
            </Typography>
          </Box>
          <IconButton onClick={() => setIsDrawerOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
  
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box sx={{ mb: 2 }}>
              <Typography color="text.secondary" variant="body2">Parent</Typography>
              <Typography>{selectedActivity.parent || '-'}</Typography>
            </Box>
  
            <Box sx={{ mb: 2 }}>
              <Typography color="text.secondary" variant="body2">Added by</Typography>
              <Typography>{selectedActivity.ownerName || '-'}</Typography>
            </Box>
  
            <Box sx={{ mb: 2 }}>
              <Typography color="text.secondary" variant="body2">Start at</Typography>
              <Typography>
                {selectedActivity?.startDate ? new Date(selectedActivity?.startDate).toLocaleDateString() : '-'}
              </Typography>
            </Box>
  
            <Box sx={{ mb: 2 }}>
              <Typography color="text.secondary" variant="body2">Due on</Typography>
              <Typography>
                {selectedActivity?.dueOn !="Not Set" ? new Date(selectedActivity?.dueOn).toLocaleDateString() : '-'}
              </Typography>
            </Box>
          </Grid>
  
          <Grid item xs={6}>
            <Box sx={{ mb: 2 }}>
              <Typography color="text.secondary" variant="body2">Priority</Typography>
              <Box sx={{
                display: 'inline-block',
                px: 2,
                py: 0.5,
                borderRadius: '16px',
                border: '1px solid',
                fontSize: '0.875rem',
                ...getLevelChipColor(selectedActivity.level)
              }}>
                {selectedActivity.level || "Low"}
              </Box>
            </Box>
  
            <Box sx={{ mb: 2 }}>
              <Typography color="text.secondary" variant="body2">State</Typography>
              <Box sx={{
                display: 'inline-block',
                px: 2,
                py: 0.5,
                borderRadius: '16px',
                border: '1px solid',
                fontSize: '0.875rem',
                ...getStateChipColor(selectedActivity.state)
              }}>
                {selectedActivity.state === 'Develop' ? 'In Progress' :
                 selectedActivity.state === 'Qa' ? 'In Review' :
                 selectedActivity.state}
              </Box>
            </Box>
  
            <Box sx={{ mb: 2 }}>
              <Typography color="text.secondary" variant="body2">Complexity</Typography>
              <Typography>{selectedActivity.complexity || '-'}</Typography>
            </Box>
  
            <Box sx={{ mb: 2 }}>
              <Typography color="text.secondary" variant="body2">Notes</Typography>
              <Typography>{selectedActivity.description || '-'}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    )}
  </Drawer>
  )
}

export default ActivityDetalisModal