import React, { useState } from 'react';
import { Grid, Typography, Box, Button, TextField, InputAdornment, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectPageSpeedLoading, setPageSpeedData, setPageSpeedLoading, showToast } from '../redux/userSlice';
import PageSpeedGuage from './Common/PageSpeedGuage';
import HealthCheck from "../Assets/healthcheck-icon.png";
import { useNavigate } from 'react-router-dom';
import "../Css/Dashboard.css";
import LanguageIcon from "@mui/icons-material/Language";
import RefreshIcon from "@mui/icons-material/Refresh";
import { updateWorkspaceInfo } from '../graphql/mutations';
import { generateClient } from 'aws-amplify/api';
import axios from 'axios';

const buttonStyle = {
    border: "none",
    color: "#000000de",
    textTransform: "initial",
    fontSize: "1rem",
    backgroundColor: "#F0F0F0",
};
const PerformanceMetrics = ({ data, domain, setDomain, selectedWorkspaceId, fetchWorkspaceInfo }) => {
    const isLoading = useSelector(selectPageSpeedLoading);
    const client = generateClient();
const dispatch=useDispatch();
    const navigate = useNavigate();
    const [isRefreshing, setIsRefreshing] = useState(false);
    const calculateTotalScore = () => {
        if (!data) return 0;
        const total = (
            (data.performance || 0) +
            (data.accessibility || 0) +
            (data.bestPractices || 0) +
            (data.seo || 0)
        ) / 4;
        return total.toFixed(2);
    };

    const metrics = [
        { value: data?.performance || 0, label: 'Performance' },
        { value: data?.accessibility || 0, label: 'Accessibility' },
        { value: data?.bestPractices || 0, label: 'Best Practices' },
        { value: data?.seo || 0, label: 'SEO' }
    ];
    const runPageSpeedAnalysis = async (domainUrl) => {
        dispatch(setPageSpeedLoading(true));
        try {
            const api_key = "AIzaSyBtL911PwgosKvUClz7OnUpf9V8PCEknDs";
            const response = await axios.get(
                `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${encodeURIComponent(
                    domainUrl
                )}&key=${api_key}&category=performance&category=accessibility&category=best-practices&category=seo`
            );

            const { data } = response;
            const metrics = {
                performance: Math.round(
                    (data.lighthouseResult.categories.performance?.score || 0) * 100
                ),
                accessibility: Math.round(
                    (data.lighthouseResult.categories.accessibility?.score || 0) * 100
                ),
                bestPractices: Math.round(
                    (data.lighthouseResult.categories["best-practices"]?.score || 0) * 100
                ),
                seo: Math.round(
                    (data.lighthouseResult.categories.seo?.score || 0) * 100
                ),
            };

            // Store report in database
            const reportData = {
                metrics,
                lastChecked: new Date().toISOString(),
            };

            await client.graphql({
                query: updateWorkspaceInfo,
                variables: {
                    input: {
                        id: selectedWorkspaceId,
                        page_speed_report: JSON.stringify(reportData),
                    },
                },
            });

            dispatch(setPageSpeedData(metrics));
            return metrics;
        } catch (error) {
            console.error("Error fetching PageSpeed data", error);
            dispatch(
                showToast({
                    message: "Failed to analyze domain. Please check the URL and try again.",
                    type: "error",
                })
            );
            return null;
        } finally {
            dispatch(setPageSpeedLoading(false));
            setIsRefreshing(false);
        }
    };

    const handleRefresh = async () => {
        if (!domain) {
            dispatch(
                showToast({
                    message: "Please enter a domain name",
                    type: "error",
                })
            );
            return;
        }

        setIsRefreshing(true);
        const domainName = domain.replace(/^(https?:\/\/)?(www\.)?/, 'https://');
        
        try {
            // Update workspace info with new domain if changed
            await client.graphql({
                query: updateWorkspaceInfo,
                variables: {
                    input: {
                        id: selectedWorkspaceId,
                        samrushDomainName: domainName
                    },
                },
            });

            // Force a new PageSpeed analysis
            await runPageSpeedAnalysis(domainName);
            
        } catch (error) {
            console.error("Error updating workspace:", error);
            dispatch(
                showToast({
                    message: "Failed to update workspace information",
                    type: "error",
                })
            );
        }
    };
    return (
        <Box sx={{
            backgroundColor: 'white',
            // p: 3,
            borderRadius: 1
        }}>
            {/* Header */}
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 3,
                justifyContent: 'space-between'
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', }}>
                    <img src={HealthCheck} style={{ height: 50, width: 50, }} />
                    <h4 className="card-title" style={{
                        fontSize: "1.3rem",
                        margin: "0px",
                        marginTop: "0rem",
                        marginLeft: '1rem'
                    }}>
                        Health Check
                    </h4>

                </Box>
                <Box sx={{ width: "30%", ml: 'auto' }}>
                    {" "}
                    {/* Fixed width container */}
                    <TextField
                        value={domain}
                        onChange={(e) => setDomain(e.target.value)}
                        variant="outlined"
                        fullWidth
                        label="Domain"
                        sx={{
                            borderRadius: 5,
                            "& .MuiOutlinedInput-root": {
                                backgroundColor: "#f8fafc",
                                "&:hover": {
                                    backgroundColor: "#f1f5f9",
                                },
                            },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LanguageIcon sx={{ color: "#64748b" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>

                {/* Regenerate Button */}
                <IconButton
 onClick={handleRefresh}                    sx={{
                        marginLeft: 0.5,
                        borderRadius: 1,
                        backgroundColor: (theme) => theme.palette.primary.main,
                        color: 'white',
                        padding: '15px',
                        width: 50,
                        '&:hover': {
                            backgroundColor: '#1e7ab0',
                        },
                        '&.Mui-disabled': {
                            backgroundColor: '#e0e0e0',
                            color: '#9e9e9e',
                        },
                    }}
                >
                    <RefreshIcon />
                </IconButton>
                {/* <Button sx={{ marginLeft: 5 }}
                    variant="outlined"
                    style={buttonStyle}
                    onClick={() => navigate("/performance")}
                    className="manage-sub-btn"
                >
                    Learn More
                </Button> */}
            </Box>

            {/* Metrics Display */}
            <Grid container spacing={3}>
                {/* Total Score */}
                <Grid item xs={12} md={4}>
                    <Box sx={{ textAlign: 'center' }}>
                        <PageSpeedGuage
                            value={calculateTotalScore()}
                            size={130}
                            thickness={10}
                        />
                        <Typography variant='h6' component={'body'} sx={{ mt: 1, color: '#666' }}>
                            Total Digital Performance Score
                        </Typography>
                    </Box>
                </Grid>

                {/* Individual Metrics */}
                <Grid sx={{ padding: 0 }} item xs={12} md={8}>
                    <Grid container spacing={1}>
                        {metrics.map((metric, index) => (
                            <Grid item xs={6} key={index}>
                                <div style={{ textAlign: 'center', padding:5}}>
                                    <PageSpeedGuage
                                        value={metric.value}
                                        size={80}
                                        thickness={6}
                                    />
                                    <Typography sx={{
                                        mt: 1,
                                        fontSize: '14px',
                                        color: '#666'
                                    }}>
                                        {metric.label}
                                    </Typography>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PerformanceMetrics;