import { Dialog, DialogContent, DialogTitle, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";

export const WorkspaceRevenueModal = ({ open, handleClose, workspaceRevenues }) => {
   
        const [searchTerm, setSearchTerm] = useState("");
            const [filteredWorkspaces, setFilteredWorkspaces] = useState([]);
        
      useEffect(() => {
            filterWorkspaces();
        }, [searchTerm, workspaceRevenues ]);
        const filterWorkspaces = () => {
            let filtered = workspaceRevenues;
            // Filter by search term
            filtered = filtered.filter(workspace =>
                workspace.name.toLowerCase().includes(searchTerm.toLowerCase()) 
               
            );
    
            // Filter by subscription status
           
    
            setFilteredWorkspaces(filtered);
        };

    return (
      <Dialog 
        open={open} 
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle sx={{fontWeight: 'bold'}}>
          All-Time Workspace Revenue Details
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
        <TextField
                        placeholder="Search workspaces..."
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Workspace Name</TableCell>
                
                  <TableCell  sx={{ fontWeight: 'bold' }} align="right">Total Revenue</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align="right">Total Subscriptions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredWorkspaces.map((workspace) => (
                  <TableRow key={workspace.id}>
                    <TableCell>{workspace.name}</TableCell>
                    
                    <TableCell align="right">
                      ${workspace.revenue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </TableCell>
                    <TableCell align="right">
                      {workspace.subscriptionCount}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    );
  };