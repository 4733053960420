import React, { useState } from "react";
import {
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import { setProductCount, showToast } from "../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { deleteCartItems } from "../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import {
  listCartItems,
  listCoupons,
  listProductCards,
} from "../graphql/queries";
import { useMediaQuery } from "@mui/material";
import CartMobileResponsive from "../components/Common/CartMobileResponsive";
import { toast } from "react-toastify";
const container = {
  margin: "2rem 3rem",
};
const title = {
  fontSize: "2rem",
  margin: "0px",
  marginBottom: "1rem",
};
const organizationCard = {
  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
  borderRadius: "10px",
  padding: "1.5rem 2rem",
  minHeight: "70vh",
  position: "relative",
};
const headingAlign = {
  display: "flex",
  justifyContent: "space-between",
  margin: "0rem 3rem",
};
const rowAlign = {
  display: "flex",
  justifyContent: "space-between",
  margin: "0.5rem 3rem",
};
const textALign = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const counterContainer = {
  display: "flex",
  alignItems: "center",
};

const Cart = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const client = generateClient();
  const navigate = useNavigate();
  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const dispatch = useDispatch();
  const cartItemsWithDescription = useSelector((state) => state.user.productCount);
  console.log("the credis purpose description is::", cartItemsWithDescription)
  console.log("description is::", cartItemsWithDescription?.description)
  const [productDetail, setProductDetail] = useState(null);
  const [product, setProduct] = useState(null);
  const [couponData, setCouponData] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [expanded, setExpanded] = React.useState(true);
  const [promoCode, setPromoCode] = React.useState("");
  const [netPrice, setNetPrice] = React.useState(0);
  const [promoCodeError, setPromoCodeError] = React.useState(false);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [appliedCoupon, setAppliedCoupon] = useState(null);

  React.useEffect(() => {
    const fetchCartItems = async () => {
      const variables = {
        filter: {
          user_id: {
            eq: userData.sub,
          },
        },
      };

      const getCartItems = await client.graphql({
        query: listCartItems,
        variables: variables,
      });

      const res = getCartItems.data.listCartItems.items;
      const dbProductCart = res?.filter(
        (id) => id.workspace_id === selectedWorkspaceId
      );
      setProductDetail(dbProductCart);
      calculateTotalPrice(dbProductCart, 0);
    };
    fetchCartItems();
  }, []);

  React.useEffect(() => {
    const fetchCouponCode = async () => {
      const getCoupons = await client.graphql({
        query: listCoupons,
      });

      const res = getCoupons.data.listCoupons.items;
      setCouponData(res);
    };
    fetchCouponCode();
  }, []);

  React.useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      // dispatch(setLoading(true));
      const getProductsData = await client.graphql({
        query: listProductCards,
      });
      const response = getProductsData.data.listProductCards.items;
      const cleanedResponse = response.map((item) => {
        const cleanedItem = { ...item };
        if (cleanedItem.categories) {
          cleanedItem.categories = JSON.parse(cleanedItem.categories);
        }
        if (cleanedItem.tags) {
          cleanedItem.tags = JSON.parse(cleanedItem.tags);
        }
        return cleanedItem;
      });
      setProduct(cleanedResponse);
      // dispatch(setLoading(false));
    } catch (error) {
      // dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error fetching orders:", error);
    }
  };

  const calculateTotalPrice = (cartItems, discount) => {
    const total = cartItems?.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    const netTotal =
      cartItems?.reduce((acc, item) => acc + item.price * item.quantity, 0) -
      discount;
    setTotalPrice(total);
    setNetPrice(netTotal);
  };

  const handleDelete = async (index) => {
    try {
      const idToDelete = productDetail[index].cartItemId
        ? productDetail[index].cartItemId
        : productDetail[index].id;
      if (idToDelete) {
        const variables = {
          input: {
            id: idToDelete,
          },
        };

        const deletePlans = await client.graphql({
          query: deleteCartItems,
          variables: variables,
        });
      }

      const updatedProductDetail = productDetail.filter((_, i) => i !== index);
      setProductDetail(updatedProductDetail);
      dispatch(setProductCount(updatedProductDetail));
      calculateTotalPrice(updatedProductDetail, 0);
      setTotalDiscount(0);
    } catch (error) {
      console.error("Error in handleDelete:", error);
    }
  };

  const handleBillingData = () => {
    if (!productDetail || productDetail.length === 0) {
      toast.warn(
        "No products in the cart. Please add some products to proceed."
      );
      return;
    }

    const dataToSend = {
      productsDetail: productDetail,
      couponId: appliedCoupon ? appliedCoupon.id : null,
      creditsPurpose: cartItemsWithDescription?.description

    };

    if (netPrice < 0) {
      toast.warn("Total price is not valid to proceed.");
      return;
    }
    if (!props.isCartPressed) {
      navigate("/billing", {
        state: dataToSend,
        creditsPurpose: cartItemsWithDescription?.description

      });
    } else if (props.isCartPressed) {
      props.handleCheckOutPressed(dataToSend, totalPrice);
    }
  };

  const handleChangeAccordianButton = (panel) => (event, isExpanded) => {
    setExpanded(!expanded);
  };

  const handleCartDiscountDollarAmount = (findPromoCode) => {
    const totalCartPrice = productDetail?.reduce(
      (acc, curr) => acc + parseFloat(curr.price),
      0
    );
    const updatedProductDetail = productDetail?.map((product) => {
      const originalPrice = parseFloat(product.price);
      const productDiscount =
        (originalPrice / totalCartPrice) * findPromoCode?.amount;

      return {
        ...product,
        discountType: "cart base",
        discount: +productDiscount.toFixed(2),
      };
    });

    setProductDetail(updatedProductDetail);
    calculateTotalPrice(updatedProductDetail, findPromoCode.amount);
    setTotalDiscount(findPromoCode.amount);
  };

  const handleCartDiscountPercentage = (findPromoCode) => {
    const totalDiscountPercent = findPromoCode?.percent;
    let totalDiscountAmount = 0;

    const updatedProductDetail = productDetail.map((product) => {
      const price = parseFloat(product.price);
      const discountAmount = (totalDiscountPercent / 100) * price;
      totalDiscountAmount += discountAmount;
      return {
        ...product,
        discountType: "cart base",
        discount: +discountAmount.toFixed(2),
      };
    });

    setProductDetail(updatedProductDetail);
    calculateTotalPrice(updatedProductDetail, totalDiscountAmount);
    setTotalDiscount(totalDiscountAmount);
  };

  const handleProductDiscountDollar = (findPromoCode, matchedProducts) => {
    const totalMatchedPrice = matchedProducts.reduce(
      (acc, product) => acc + parseFloat(product.price),
      0
    );

    const updatedProductDetail = productDetail?.map((product) => {
      const originalPrice = parseFloat(product.price);
      let productDiscount = 0;
      if (matchedProducts.some((p) => p.id === product.id)) {
        productDiscount =
          (originalPrice / totalMatchedPrice) * findPromoCode?.amount;
      }
      return {
        ...product,
        discountType: "product base",
        discount: +productDiscount.toFixed(2),
      };
    });

    const totalDiscountOnPrices = updatedProductDetail.reduce(
      (acc, product) => acc + parseFloat(product.discount || 0),
      0
    );

    setProductDetail(updatedProductDetail);
    calculateTotalPrice(updatedProductDetail, totalDiscountOnPrices);
    setTotalDiscount(totalDiscountOnPrices);
  };

  const handleProductDiscountPercentage = (findPromoCode, matchedProducts) => {
    const totalDiscountPercent = findPromoCode?.percent;
    let totalDiscountAmount = 0;

    const updatedProductDetail = productDetail?.map((product) => {
      const price = parseFloat(product.price);
      const discountAmount = (totalDiscountPercent / 100) * price;
      totalDiscountAmount += discountAmount;
      return {
        ...product,
        discountType: "product base",
        discount: matchedProducts.some((p) => p.id === product.id)
          ? +discountAmount.toFixed(2)
          : 0,
      };
    });
    const totalDiscountOnPrices = updatedProductDetail.reduce(
      (acc, product) => {
        return acc + parseFloat(product.discount || 0);
      },
      0
    );
    setProductDetail(updatedProductDetail);
    calculateTotalPrice(updatedProductDetail, totalDiscountOnPrices);
    setTotalDiscount(totalDiscountOnPrices);
  };

  const handleFixedCartDiscount = (findPromoCode) => {
    if (findPromoCode?.allowed_email?.length > 0) {
      const isMatchedEmail = findPromoCode?.allowed_email?.some(
        (email) => email.toLowerCase() === userData.email.toLowerCase()
      );
      if (isMatchedEmail) {
        if (!findPromoCode?.never_expire) {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const startDate = new Date(findPromoCode?.expire_start_date);
          startDate.setHours(0, 0, 0, 0);
          const endDate = new Date(findPromoCode?.expire_end_date);
          endDate.setHours(0, 0, 0, 0);
          if (startDate <= today && today <= endDate) {
            if (findPromoCode?.amount_type === "Dollar off") {
              handleCartDiscountDollarAmount(findPromoCode);
            } else {
              handleCartDiscountPercentage(findPromoCode);
            }
          } else {
            dispatch(
              showToast({ message: "Promo code expired", type: "error" })
            );
            return;
          }
        } else {
          if (findPromoCode?.amount_type === "Dollar off") {
            handleCartDiscountDollarAmount(findPromoCode);
          } else {
            handleCartDiscountPercentage(findPromoCode);
          }
        }
      } else {
        dispatch(showToast({ message: "Invalid promo code", type: "error" }));
        return;
      }
    } else {
      if (!findPromoCode?.never_expire) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const startDate = new Date(findPromoCode?.expire_start_date);
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date(findPromoCode?.expire_end_date);
        endDate.setHours(0, 0, 0, 0);
        if (startDate <= today && today <= endDate) {
          if (findPromoCode?.amount_type === "Dollar off") {
            handleCartDiscountDollarAmount(findPromoCode);
          } else {
            handleCartDiscountPercentage(findPromoCode);
          }
        } else {
          dispatch(showToast({ message: "Promo code expired", type: "error" }));
          return;
        }
      } else {
        if (findPromoCode?.amount_type === "Dollar off") {
          handleCartDiscountDollarAmount(findPromoCode);
        } else {
          handleCartDiscountPercentage(findPromoCode);
        }
      }
    }
  };

  const handleFixedProductDiscount = (findPromoCode) => {
    if (findPromoCode?.allowed_email?.length > 0) {
      if (findPromoCode?.product_id?.length > 0) {
        const isMatchedEmail = findPromoCode?.allowed_email?.some(
          (email) => email.toLowerCase() === userData.email.toLowerCase()
        );
        if (isMatchedEmail) {
          const matchedProducts = [];
          findPromoCode.product_id.forEach((productId) => {
            const matchedProduct = productDetail?.find(
              (p) => p.product_id === productId
            );
            if (matchedProduct) {
              matchedProducts.push(matchedProduct);
            }
          });
          if (matchedProducts.length > 0) {
            if (!findPromoCode?.never_expire) {
              const today = new Date();
              today.setHours(0, 0, 0, 0);

              const startDate = new Date(findPromoCode?.expire_start_date);
              startDate.setHours(0, 0, 0, 0);

              const endDate = new Date(findPromoCode?.expire_end_date);
              endDate.setHours(0, 0, 0, 0);
              if (startDate <= today && today <= endDate) {
                if (findPromoCode?.amount_type === "Dollar off") {
                  handleProductDiscountDollar(findPromoCode, matchedProducts);
                } else {
                  handleProductDiscountPercentage(
                    findPromoCode,
                    matchedProducts
                  );
                }
              } else {
                dispatch(
                  showToast({ message: "Promo code expired", type: "error" })
                );
                return;
              }
            } else {
              if (findPromoCode?.amount_type === "Dollar off") {
                handleProductDiscountDollar(findPromoCode, matchedProducts);
              } else {
                handleProductDiscountPercentage(findPromoCode, matchedProducts);
              }
            }
          } else {
            dispatch(
              showToast({ message: "Invalid promo code", type: "error" })
            );
            return;
          }
        } else {
          dispatch(showToast({ message: "Invalid promo code", type: "error" }));
          return;
        }
      } else {
        dispatch(showToast({ message: "Invalid promo code", type: "error" }));
        return;
      }
    } else {
      if (findPromoCode?.product_id?.length > 0) {
        const matchedProducts = [];
        findPromoCode.product_id.forEach((productId) => {
          const matchedProduct = productDetail?.find(
            (p) => p.product_id === productId
          );
          if (matchedProduct) {
            matchedProducts.push(matchedProduct);
          }
        });
        if (matchedProducts.length > 0) {
          if (!findPromoCode?.never_expire) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const startDate = new Date(findPromoCode?.expire_start_date);
            startDate.setHours(0, 0, 0, 0);
            const endDate = new Date(findPromoCode?.expire_end_date);
            endDate.setHours(0, 0, 0, 0);
            if (startDate <= today && today <= endDate) {
              if (findPromoCode?.amount_type === "Dollar off") {
                handleProductDiscountDollar(findPromoCode, matchedProducts);
              } else {
                handleProductDiscountPercentage(findPromoCode, matchedProducts);
              }
            } else {
              dispatch(
                showToast({ message: "Promo code expired", type: "error" })
              );
              return;
            }
          } else {
            if (findPromoCode?.amount_type === "Dollar off") {
              handleProductDiscountDollar(findPromoCode, matchedProducts);
            } else {
              handleProductDiscountPercentage(findPromoCode, matchedProducts);
            }
          }
        } else {
          dispatch(showToast({ message: "Invalid promo code", type: "error" }));
          return;
        }
      } else {
        dispatch(showToast({ message: "Invalid promo code", type: "error" }));
        return;
      }
    }
  };

  const applyToken = () => {
    if (promoCode === "") {
      setPromoCodeError(true);
      return;
    }
    try {
      const findPromoCode = couponData?.find((item) => item.code === promoCode);
      if (findPromoCode) {
        if (findPromoCode?.type === "Fixed cart discount") {
          handleFixedCartDiscount(findPromoCode);
        } else if (findPromoCode?.type === "Fixed product discount") {
          handleFixedProductDiscount(findPromoCode);
        }
        setAppliedCoupon(findPromoCode); // Store the applied coupon
      } else {
        dispatch(showToast({ message: "Invalid promo code", type: "error" }));
        return;
      }
    } catch (error) {
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error", error);
    }
  };

  const handlePromoCodeChange = (value) => {
    setPromoCode(value);
    setTotalDiscount(0);
    setPromoCodeError(false);
  };

  return (
    <>
      {isMobile ? (
        <div style={{ marginBottom: 50, backgroundColor: "white" }}>
          <CartMobileResponsive
            expanded={expanded}
            productDetail={productDetail}
            handleBillingData={handleBillingData}
            handleChangeAccordianButton={handleChangeAccordianButton}
            // totalPrice={totalPrice}
            promoCode={promoCode}
            promoCodeError={promoCodeError}
            setPromoCode={setPromoCode}
            setTotalDiscount={setTotalDiscount}
            applyToken={applyToken}
            setPromoCodeError={setPromoCodeError}
            handlePromoCodeChange={handlePromoCodeChange}
            totalPrice={netPrice ? netPrice : totalPrice}
            handleDelete={handleDelete}
            totalDiscount={totalDiscount}
            showPromoCode={true}
          />
        </div>
      ) : (
        <div style={container}>
          {!props.isCartPressed && (
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div>
                  <div>
                    <p style={title}>Shopping Cart</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Grid container spacing={1.5} style={{ fontSize: "0.9rem" }}>
                <Grid item xs={props.isCartPressed ? 8 : 8}>
                  <Card style={organizationCard}>
                    <div style={headingAlign} className="headAlign-cart">
                      <Grid container spacing={0}>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={3} style={textALign}>
                          <h4>Product</h4>
                        </Grid>
                        <Grid item xs={1.5} style={textALign}>
                          <h4>Plan</h4>
                        </Grid>
                        <Grid item xs={1.5} style={textALign}>
                          <h4>Quantity</h4>
                        </Grid>
                        <Grid item xs={1.5} style={textALign}>
                          <h4>Price</h4>
                        </Grid>
                        <Grid item xs={1.5} style={textALign}>
                          <h4>Total</h4>
                        </Grid>
                        <Grid item xs={1}></Grid>
                      </Grid>
                    </div>
                    <Divider light />
                    {productDetail?.length
                      ? productDetail?.map((item, index, array) => (
                        <>
                          <div style={rowAlign} className="rowAlign-cart">
                            <Grid container spacing={0}>
                              <Grid item xs={2} style={textALign}>
                                <div
                                  style={{ display: "flex", gap: "0.5rem" }}
                                >
                                  <img
                                    src={item?.image}
                                    height="100px"
                                    width="80px"
                                    style={{
                                      objectFit: "cover",
                                      borderRadius: "3px",
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={3} style={textALign}>
                                <p>{item?.name}</p>
                              </Grid>
                              <Grid item xs={1.5} style={textALign}>
                                {item?.plan ? (
                                  <p>{item?.plan}</p>
                                ) : (
                                  <p>Basic</p>
                                )}
                              </Grid>
                              <Grid item xs={1.5} style={textALign}>
                                <div style={counterContainer}>
                                  <p>
                                    <b>{item.quantity}</b>
                                  </p>
                                </div>
                              </Grid>
                              <Grid item xs={1.5} style={textALign}>
                                <p>{`$${Number(item?.price ?? 0)
                                  .toFixed(0)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  .toLocaleString("en-US")}`}</p>
                              </Grid>
                              <Grid item xs={1.5} style={textALign}>
                                <p>
                                  <b>{`$${Number(
                                    item?.price * item.quantity ?? 0
                                  )
                                    .toFixed(0)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    .toLocaleString("en-US")}`}</b>
                                </p>
                              </Grid>
                              <Grid item xs={1} style={textALign}>
                                <IconButton
                                  onClick={() => handleDelete(index)}
                                >
                                  <CancelIcon style={{ cursor: "pointer" }} />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </div>
                          {index !== array?.length - 1 && <Divider light />}
                        </>
                      ))
                      : null}
                  </Card>
                </Grid>
                <Grid item xs={props.isCartPressed ? 4 : 4}>
                  <Card style={organizationCard}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          ...title,
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        Order Summary
                      </p>
                    </div>
                    <Divider light />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>
                        Items: <b>{productDetail?.length}</b>
                      </p>
                      <p>
                        <b>{`$${Number(totalPrice ?? 0)
                          .toFixed(0)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          .toLocaleString("en-US")}`}</b>
                      </p>
                    </div>
                    <div>
                      <p>Promo Code</p>
                      <TextField
                        error={promoCodeError}
                        id="outlined-basic"
                        label="Enter promo code"
                        variant="outlined"
                        size="small"
                        onChange={(e) => {
                          setPromoCode(e.target.value);
                          setTotalDiscount(0);
                          setPromoCodeError(false);
                        }}
                        value={promoCode}
                        style={{ width: "100%", marginBottom: "1rem" }}
                      />
                      <Button
                        variant="contained"
                        style={{
                          padding: "0.2rem 1rem",
                          textTransform: "initial",
                        }}
                        onClick={applyToken}
                      >
                        Apply
                      </Button>
                      <Divider light style={{ margin: "3rem 0rem" }} />
                      {totalDiscount > 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p>Discount</p>
                          <p>
                            <b>{`$${Number(totalDiscount ?? 0)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              .toLocaleString("en-US")}`}</b>
                          </p>
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>Total</p>
                        <p>
                          <b>{`$${Number(netPrice ?? 0)
                            .toFixed(0)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            .toLocaleString("en-US")}`}</b>
                        </p>
                      </div>
                      <Button
                        variant="contained"
                        style={{
                          width: "90%",
                          padding: "0.5rem 1rem",
                          fontSize: "1rem",
                          textTransform: "initial",
                          position: "absolute",
                          bottom: 20,
                          right: 27,
                        }}
                        className="checkout-btn"
                        onClick={handleBillingData}
                      >
                        Checkout
                      </Button>
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!props.isCartPressed && (
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Footer />
              </Grid>
            </Grid>
          )}
        </div>
      )}
    </>
  );
};

export default Cart;