import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { generateClient } from "aws-amplify/api";
import Typography from "@mui/material/Typography";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import AddressForm from "../components/checkoutForm/AddressForm";
import { useState } from "react";
import PaymentMethod from "../components/checkoutForm/PaymentForm";
import ReviewOrder from "../components/checkoutForm/ReviewOrder";
import { useLocation, useNavigate } from "react-router-dom";
import { listSubscriberCards, listUserBillings, getSubscriberCard } from "../graphql/queries";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setProductCount, showToast } from "../redux/userSlice";
import {
  createSubscriptions,
  createUserBilling,
  deleteCartItems,
  updateSubscriberCard,
  updateUserBilling,
} from "../graphql/mutations";
import uuid from "react-uuid";
import LoadingButton from "@mui/lab/LoadingButton";
import { green } from "@mui/material/colors";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useMediaQuery } from "@mui/material";
import { attachPaymentMethod, createPaymentIntent, createPrice, stripeApi } from "../utills/stripeApis/stripeApi";
import { loadStripe } from "@stripe/stripe-js";
import tagManagerEvents from "./GoogleTagManager";
import googleTagTriggers from '../utills/GoogleTagsList/google-tags.json'

const steps = ["Billing address", "Payment details", "Review order"];

const merchant_name = process.env.REACT_APP_MERCHANT_LOGIN_NAME;
const transaction_key = process.env.REACT_APP_MERCHANT_TRANSACTION_KEY;
const authorize_api = process.env.REACT_APP_AUTHORIZE_API;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
export default function Checkout() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const organizationCard = {
    padding: isMobile ? "1rem 1rem" : "3rem 3rem",
    margin: isMobile ? "1.5rem auto" : "7rem auto",
    width: isMobile ? "95%" : "60%",
    minHeight: "68vh",
    boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
    borderRadius: "10px",
    position: "relative",
  };
  const client = generateClient();
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const buyNowProduct = state?.product;
  const productData = state?.productsDetail;
  const couponId = state?.couponId;
  const creditsPurpose = state?.creditsPurpose
  const [activeStep, setActiveStep] = React.useState(0);
  const [checkbox, setCheckbox] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [SaveInfoCheckbox, setSaveInfoCheckbox] = useState(true);
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const [productDetail, setProductDetail] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [subscriberData, setSubscriberData] = useState(null);
  const [userDetailDB, setUserDetailDB] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [cardData, setCardData] = React.useState({
    holderName: "",
    cardNumber: "",
    month: "",
    year: "",
    cardCode: "",
  });
  const [stripe, setStripe] = useState(stripePromise);

  const [customerDetail, setCustomerDetail] = useState({
    billing: {
      first_name: "",
      last_name: "",
      address_1: "",
      city: "",
      state: "",
      postcode: "",
      country: "",
      email: "",
    },
  });
  const [errors, setErrors] = useState({
    billing: {
      first_name: false,
      last_name: false,
      address_1: false,
      address_2: false,
      city: false,
      state: false,
      postcode: false,
      country: false,
      email: false,
      phone: false,
    },
  });
  const [cardDataErrors, setCardDataErros] = React.useState({
    holderName: "",
    cardNumber: "",
    month: "",
    year: "",
    cardCode: "",
  });

  useEffect(() => {
    if (userDetailDB?.length > 0) {
      const billingDetails = userDetailDB[0];
      setCustomerDetail((prevCustomerDetail) => ({
        ...prevCustomerDetail,
        billing: {
          ...prevCustomerDetail.billing,
          first_name: billingDetails.first_name || "",
          last_name: billingDetails.last_name || "",
          address_1: billingDetails.address || "",
          city: billingDetails.city || "",
          state: billingDetails.state || "",
          postcode: billingDetails.postal_code || "",
          country: billingDetails.country || "",
          email: billingDetails.email || "",
        },
      }));
      setCardData({
        ...cardData,
        holderName: billingDetails.holderName || "",
        cardNumber: billingDetails.card_number || "",
        month: billingDetails.expiry_month || "",
        year: billingDetails.expiry_year || "",
        cardCode: billingDetails.cvc || "",
      });
    }
  }, [userDetailDB]);

  React.useEffect(() => {
    if (buyNowProduct?.length > 0) {
      setProductDetail(buyNowProduct);
    } else {
      setProductDetail(productData);
    }
    calculateTotalPrice(productData);
  }, []);

  useEffect(() => {
    userBillingDetail();
  }, []);
  const fetchAllUserBillings = async (selectedWorkspaceId) => {
    let nextToken = null;
    let allItems = [];

    const variables = {
      filter: {
        workspace_id: {
          eq: selectedWorkspaceId,
        },
      },
      limit: 1000, // You can adjust this value
    };

    do {
      const getAddressData = await client.graphql({
        query: listUserBillings,
        variables: {
          ...variables,
          nextToken: nextToken
        },
      });

      const items = getAddressData.data.listUserBillings.items;
      allItems = [...allItems, ...items];
      nextToken = getAddressData.data.listUserBillings.nextToken;
    } while (nextToken);

    return allItems;
  };
  const userBillingDetail = async () => {
    try {
      const res = await fetchAllUserBillings(selectedWorkspaceId);
      setUserDetailDB(res);
    } catch (error) {
      console.error("Error creating todo:", error);
    }
  };

  const SaveUserBillingDetail = async () => {
    try {
      setLoading(true);
      if (checkbox) {
        const newId = uuid();
        const variable = {
          id: userDetailDB?.length > 0 ? userDetailDB?.[0]?.id : newId,
          workspace_id: selectedWorkspaceId,
          address: customerDetail?.billing?.address_1,
          card_number: cardData.cardNumber,
          expiry_month: cardData.month,
          expiry_year: cardData.year,
          cvc: cardData.cardCode,
          first_name: customerDetail?.billing?.first_name,
          last_name: customerDetail?.billing?.last_name,
          email: customerDetail?.billing?.email,
          city: customerDetail?.billing?.city,
          country: customerDetail?.billing?.country,
          postal_code: customerDetail?.billing?.postcode,
          state: customerDetail?.billing?.state,
          holderName: cardData?.holderName,
          paymentMethod_Gateway: "authorize",
        };

        const updateUserBillingData = await client.graphql({
          query:
            userDetailDB?.length > 0 ? updateUserBilling : createUserBilling,
          variables: { input: variable },
        });

        const result = updateUserBillingData.data.updateUserBilling;
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating user:", error);
      return;
    }
  };

  function generateInvoiceNumber() {
    const timestamp = Date.now().toString();
    const random = Math.floor(Math.random() * 1000)
      .toString()
      .padStart(3, "0");
    return `${timestamp}-${random}`;
  }

  const adjustDate = (period, every) => {
    const newDate = new Date();
    if (period === "day") {
      newDate.setDate(newDate.getDate() + +every);
    } else if (period === "month") {
      newDate.setMonth(newDate.getMonth() + +every);
    }
    return newDate;
  };

  const generateLineItemsId = () => {
    const newNumbers = Math.floor(100 + Math.random() * 900).toString();
    return newNumbers;
  };

  const handleNextCombined = async () => {
    const isStripe = userDetailDB?.[0]?.paymentMethod_Gateway === "stripe";

    if (activeStep === 0) {
      let hasEmptyField = false;
      Object.entries(customerDetail.billing).forEach(([key, value]) => {
        if (value.trim() === "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            billing: {
              ...prevErrors.billing,
              [key]: true,
            },
          }));
          hasEmptyField = true;
          return;
        }
      });
      if (!hasEmptyField) {
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 1) {
      let hasEmptyField = false;
      Object.entries(cardData).forEach(([key, value]) => {
        if (value.trim() === "") {
          setCardDataErros((prevErrors) => ({
            ...prevErrors,
            [key]: true,
          }));
          hasEmptyField = true;
          return;
        }
      });
      if (!hasEmptyField) {

        try {
          setLoading(true);
          if (isStripe) {
            let paymentMethodId = subscriberData?.[0]?.stripe_payment_profile_id;
            console.log("paymentMethodId", paymentMethodId);
            if (paymentMethodId) {
              await attachPaymentMethod(subscriberData?.[0]?.stripe_customer_id, paymentMethodId);
            }
          } else {
            if (!subscriberData?.[0]?.payment_profile_id) {
              let paymentProfileId = null;
              const createPaymentProfile = {
                createCustomerPaymentProfileRequest: {
                  merchantAuthentication: {
                    name: merchant_name,
                    transactionKey: transaction_key,
                  },
                  customerProfileId: subscriberData?.[0]?.subscription_id,
                  paymentProfile: {
                    billTo: {
                      firstName: customerDetail?.billing?.first_name,
                      lastName: customerDetail?.billing?.last_name,
                      address: customerDetail?.billing?.address_1,
                      city: customerDetail?.billing?.city,
                      state: customerDetail?.billing?.state,
                      zip: customerDetail?.billing?.postcode,
                      country: customerDetail?.billing?.country,
                    },
                    payment: {
                      creditCard: {
                        cardNumber: cardData?.cardNumber,
                        expirationDate: `${cardData?.year}-${cardData?.month}`,
                      },
                    },
                    defaultPaymentProfile: false,
                  },
                  validationMode: "liveMode",
                },
              };

              try {
                const response = await axios.post(authorize_api, createPaymentProfile);
                paymentProfileId = response.data.customerPaymentProfileId;

                if (response.data.messages.resultCode === "Error") {
                  throw new Error("Your payment method is invalid. Please try again with valid details.");
                }

                if (paymentProfileId) {
                  const variable = {
                    id: subscriberData?.[0]?.id,
                    payment_profile_id: paymentProfileId,
                  };

                  await client.graphql({
                    query: updateSubscriberCard,
                    variables: { input: variable },
                  });
                }
              } catch (error) {
                throw new Error(error.message || "Error creating payment profile");
              }
            }
          }
          await fetchSubscriberData();
          setActiveStep(activeStep + 1);
        } catch (error) {
          dispatch(showToast({ message: error.message, type: "error" }));
        } finally {
          setLoading(false);
        }
      }
    } else if (activeStep === steps.length - 1) {
      try {
        setLoading(true);
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0];

        let variableSubsArray = productDetail?.filter(
          (product) => product.type === "variable subscription"
        );
        let simpleProdArray = productDetail?.filter(
          (product) => product.type === "simple product"
        );

        if (isStripe) {
          if (variableSubsArray.length > 0) {
            for (const product of variableSubsArray) {
              try {
                const adjustedDate = adjustDate(product.period, product.every);
                const intervalCount = product.every ? parseInt(product.every) : 1;

                const customer = await stripeApi.get(`/customers/${subscriberData?.[0]?.stripe_customer_id}`);

                if (!customer.data.invoice_settings.default_payment_method) {
                  await stripeApi.post(`/payment_methods/${subscriberData?.[0]?.stripe_payment_profile_id}/attach`, {
                    customer: subscriberData?.[0]?.stripe_customer_id,
                  });

                  await stripeApi.post(`/customers/${subscriberData?.[0]?.stripe_customer_id}`, {
                    invoice_settings: {
                      default_payment_method: subscriberData?.[0]?.stripe_payment_profile_id,
                    },
                  });
                }

                let stripeProduct;
                try {
                  const existingProducts = await stripeApi.get("/products", {
                    params: { limit: 100 }, // Adjust the limit as needed
                  });

                  stripeProduct = existingProducts.data.data.find(
                    (p) => p.name === product.name
                  );

                  if (!stripeProduct) {
                    // If the product doesn't exist, create it
                    const newProduct = await stripeApi.post("/products", {
                      name: product.name,
                      description: `${product.name} - ${product.plan} plan`,
                    });
                    stripeProduct = newProduct.data;
                  }
                } catch (error) {
                  console.error("Error checking/creating product:", error);
                  throw error;
                }

                // Check if a price for this product with the specified recurring details already exists
                let stripePrice;
                try {
                  const existingPrices = await stripeApi.get("/prices", {
                    params: { product: stripeProduct.id, active: true },
                  });

                  stripePrice = existingPrices.data.data.find(
                    (p) =>
                      p.recurring &&
                      p.recurring.interval === product.period &&
                      p.recurring.interval_count === intervalCount &&
                      p.unit_amount ===
                      Math.round(parseFloat(product.price) * 100)
                  );

                  if (!stripePrice) {
                    const newPrice = await createPrice(
                      stripeProduct,
                      product,
                      intervalCount
                    );
                    stripePrice = newPrice.data;
                  }
                } catch (error) {
                  console.error("Error checking/creating price:", error);
                  throw error;
                }
                let subscriptionData = {
                  customer: subscriberData?.[0]?.stripe_customer_id,
                  items: [
                    {
                      price: stripePrice.id,
                      quantity: product.quantity,
                    },
                  ],
                  default_payment_method: subscriberData?.[0]?.stripe_payment_profile_id,
                  payment_behavior: 'default_incomplete',
                  expand: ['latest_invoice.payment_intent'],
                };

                if (couponId) {
                  const couponResponse = await stripeApi.get(`/coupons/${couponId}`);
                  if (couponResponse.data.valid) {
                    subscriptionData.coupon = couponId;
                  }
                }

                const subscriptionResponse = await stripeApi.post('/subscriptions', subscriptionData);

                if (subscriptionResponse.data.status === 'incomplete') {
                  const paymentIntentId = subscriptionResponse.data.latest_invoice.payment_intent.id;
                  const paymentIntent = await stripeApi.post(`/payment_intents/${paymentIntentId}/confirm`, {
                    payment_method: subscriberData?.[0]?.stripe_payment_profile_id,
                  });

                  if (paymentIntent.data.status !== 'succeeded') {
                    throw new Error('Initial payment failed');
                  }
                }

                const sub_id = uuid();
                const variable = {
                  id: sub_id,
                  product_id: product.id,
                  status: true,
                  created_at: formattedDate,
                  workspace_id: selectedWorkspaceId,
                  subscription_id: subscriptionResponse.data.id,
                  product_image: product.image,
                  product_name: product.name,
                  product_type: product.type,
                  product_period: product.period,
                  product_sku: product.sku,
                  product_plan: product.plan,
                  product_quantity: product.quantity,
                  product_price: product.discountType && product.discountType === "product base"
                    ? +product.price * +product.quantity - +product.discount
                    : +product.price * +product.quantity,
                  invoice_number: subscriptionResponse.data.latest_invoice.id,
                  subscription_by: "stripe",
                };

                await client.graphql({
                  query: createSubscriptions,
                  variables: { input: variable },
                });

                await client.graphql({
                  query: deleteCartItems,
                  variables: { input: { id: product.id } },
                });

                dispatch(setProductCount((prevCount) => prevCount - 1));
              } catch (error) {
                console.error(`Error processing product ${product.name}:`, error);
                dispatch(showToast({
                  message: `Failed to process ${product.name}. ${error.message}`,
                  type: "error",
                }));
              }
            }
          }

          if (simpleProdArray.length > 0) {
            try {

              const totalAmount = simpleProdArray.reduce(
                (sum, product) => sum + parseFloat(product.price) * product.quantity,
                0
              );
              let finalAmount = totalAmount;
              if (couponId) {
                try {
                  const couponResponse = await stripeApi.get(`/coupons/${couponId}`);
                  if (couponResponse.data.valid) {
                    if (couponResponse.data.percent_off) {
                      finalAmount = totalAmount * (1 - couponResponse.data.percent_off / 100);
                    } else if (couponResponse.data.amount_off) {
                      finalAmount = totalAmount - (couponResponse.data.amount_off / 100);
                    }
                  }
                } catch (error) {
                  console.warn('Error applying coupon:', error);
                  // Continue with original amount if coupon validation fails
                }
              }
              console.log("finalAmount", finalAmount);
              const paymentIntent = await createPaymentIntent(
                Math.round(finalAmount),
                subscriberData?.[0]?.stripe_customer_id,
                subscriberData?.[0]?.stripe_payment_profile_id,
                creditsPurpose
              );
              console.log("paymentIntent", paymentIntent);
              if (paymentIntent.status === 200) {

                console.log("simpleProdArray", simpleProdArray);
                for (const product of simpleProdArray) {
                  console.log("product", product);
                  await client.graphql({
                    query: deleteCartItems,
                    variables: { input: { id: product.id } },
                  });

                  dispatch(setProductCount((prevCount) => prevCount - 1));
                }
              } else {
                throw new Error('Payment failed');
              }
            } catch (error) {
              console.error("Error processing simple products:", error);
              dispatch(showToast({
                message: `Failed to process one-time purchase. ${error.message}`,
                type: "error",
              }));
              return;
            }
          }
        } else {
          if (variableSubsArray.length > 0) {
            for (const product of variableSubsArray) {
              try {
                const invoiceNumber = generateInvoiceNumber();
                const adjustedDate = adjustDate(product.period, product.every);
                const formattedDateAdd = adjustedDate.toISOString().split('T')[0];

                const createSubscription = {
                  ARBCreateSubscriptionRequest: {
                    merchantAuthentication: {
                      name: merchant_name,
                      transactionKey: transaction_key,
                    },
                    subscription: {
                      name: product.name,
                      paymentSchedule: {
                        interval: {
                          length: product.every,
                          unit: `${product.period}s`,
                        },
                        startDate: formattedDateAdd,
                        totalOccurrences: "9999",
                      },
                      amount: product.discountType && product.discountType === "product base"
                        ? +product.price * +product.quantity - +product.discount
                        : +product.price * +product.quantity,
                      order: {
                        invoiceNumber: invoiceNumber,
                      },
                      profile: {
                        customerProfileId: subscriberData?.[0]?.subscription_id,
                        customerPaymentProfileId: subscriberData?.[0]?.payment_profile_id,
                      },
                    },
                  },
                };

                const response = await axios.post(authorize_api, createSubscription);

                if (response.data.messages.resultCode === "Error") {
                  throw new Error(response.data.messages.message[0].text);
                }

                const sub_id = uuid();
                const variable = {
                  id: sub_id,
                  product_id: product.id,
                  status: true,
                  created_at: formattedDate,
                  workspace_id: selectedWorkspaceId,
                  subscription_id: response.data.subscriptionId,
                  product_image: product.image,
                  product_name: product.name,
                  product_type: product.type,
                  product_period: product.period,
                  product_sku: product.sku,
                  product_plan: product.plan,
                  product_quantity: product.quantity,
                  product_price: product.discountType && product.discountType === "product base"
                    ? +product.price * +product.quantity - +product.discount
                    : +product.price * +product.quantity,
                  invoice_number: invoiceNumber,
                };

                await client.graphql({
                  query: createSubscriptions,
                  variables: { input: variable },
                });

                await client.graphql({
                  query: deleteCartItems,
                  variables: { input: { id: product.id } },
                });

                dispatch(setProductCount((prevCount) => prevCount - 1));
              } catch (error) {
                console.error(`Error processing product ${product.name}:`, error);
                dispatch(showToast({
                  message: `Failed to process ${product.name}. ${error.message}`,
                  type: "error",
                }));
              }
            }
          }

          if (simpleProdArray.length > 0) {
            try {
              const invoiceNumber = generateInvoiceNumber();
              const lineItemId = generateLineItemsId();
              const buyProduct = {
                createTransactionRequest: {
                  merchantAuthentication: {
                    name: merchant_name,
                    transactionKey: transaction_key,
                  },
                  transactionRequest: {
                    transactionType: "authCaptureTransaction",
                    amount: simpleProdArray.reduce((sum, product) =>
                      sum + (product.discount
                        ? +product.price * +product.quantity - +product.discount
                        : +product.price * +product.quantity),
                      0
                    ),
                    profile: {
                      customerProfileId: subscriberData?.[0]?.subscription_id,
                      paymentProfile: {
                        paymentProfileId: subscriberData?.[0]?.payment_profile_id,
                      },
                    },
                    order: {
                      invoiceNumber: invoiceNumber,
                    },
                    lineItems: {
                      lineItem: simpleProdArray.map((product, index) => ({
                        itemId: lineItemId + index,
                        name: product.name,
                        description: `${product.name} is a ${product.type}`,
                        quantity: product.quantity,
                        unitPrice: product.price,
                      })),
                    },
                    processingOptions: {
                      isSubsequentAuth: "true",
                    },
                    authorizationIndicatorType: {
                      authorizationIndicator: "final",
                    },
                  },
                },
              };

              const response = await axios.post(authorize_api, buyProduct);

              if (response.data.messages.resultCode === "Ok") {
                if (response.data.transactionResponse.messages[0].code === "1") {
                  for (const product of simpleProdArray) {

                    await client.graphql({
                      query: deleteCartItems,
                      variables: { input: { id: product.id } },
                    });

                    dispatch(setProductCount((prevCount) => prevCount - 1));
                  }
                } else {
                  throw new Error(response.data.transactionResponse.errors[0].errorText);
                }
              } else {
                throw new Error(response.data.messages.message[0].text);
              }
            } catch (error) {
              console.error("Error processing simple products:", error);
              dispatch(showToast({
                message: `Failed to process one-time purchase. ${error.message}`,
                type: "error",
              }));
            }
          }
        }
        tagManagerEvents(googleTagTriggers.checkoutButtonPressed, {
          product: productDetail[0]?.name,
          price: productDetail[0]?.price,
          purchasedBy: cardData?.holderName
        })
        setActiveStep(activeStep + 1);
      } catch (error) {
        dispatch(showToast({ message: error.message, type: "error" }));
      } finally {
        setLoading(false);
      }
    }
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleFieldChange = (field, value) => {
    setCustomerDetail((prevCustomerDetail) => ({
      ...prevCustomerDetail,
      billing: {
        ...prevCustomerDetail.billing,
        [field]: value,
      },
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      billing: {
        ...prevErrors.billing,
        [field]: false,
      },
    }));
  };

  const handleCardDataFieldChange = (field, value) => {
    setCardData((prevCardData) => ({
      ...prevCardData,
      ...prevCardData.cardData,
      [field]: value,
    }));

    setCardDataErros((prevErrors) => ({
      ...prevErrors,
      ...prevErrors.cardData,
      [field]: false,
    }));
  };

  const handleCheckboxChange = (event) => {
    setCheckbox(event.target.checked);
    setShowAlert(false);
  };

  const handleSaveInfoCheckboxChange = (event) => {
    setSaveInfoCheckbox(event.target.checked);
  };

  const calculateTotalPrice = (cartItems) => {
    const total = cartItems?.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    setTotalPrice(total);
  };

  useEffect(() => {
    fetchSubscriberData();
  }, []);

  const fetchSubscriberData = async () => {
    try {
      const variables = {
        filter: {
          workspace_id: {
            eq: selectedWorkspaceId,
          },
        },
      };
      const getSubData = await client.graphql({
        query: listSubscriberCards,
        variables: variables,
      });
      const res = getSubData.data.listSubscriberCards.items;
      setSubscriberData(res);
      setLoading(false);
    } catch (error) {
      console.error("Error creating todo:", error);
    }
  };

  const createPaymentMethodAPI = async () => {
    try {
      if (!stripe) {
        console.error("Stripe has not been initialized");
        throw new Error("Stripe has not been initialized");
      }

      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: {
          number: cardData.cardNumber,
          exp_month: cardData.month,
          exp_year: cardData.year,
          cvc: cardData.cardCode,
        },
        billing_details: {
          name: `${customerDetail.billing.first_name} ${customerDetail.billing.last_name}`,
          address: {
            line1: customerDetail.billing.address_1,
            city: customerDetail.billing.city,
            state: customerDetail.billing.state,
            postal_code: customerDetail.billing.postcode,
            country: customerDetail.billing.country,
          },
        },
      });

      if (error) {
        console.error("Error creating payment method:", error);
        throw new Error(`Failed to create payment method: ${error.message}`);
      }

      if (!paymentMethod || !paymentMethod.id) {
        console.error("No payment method ID returned");
        throw new Error("Failed to create payment method: No ID returned");
      }

      console.log("Payment method created successfully:", paymentMethod.id);
      return paymentMethod.id;
    } catch (error) {
      console.error("Error in createPaymentMethodAPI:", error);
      throw error;
    }
  };


  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <AddressForm
            customerDetail={customerDetail}
            handleFieldChange={handleFieldChange}
            setCustomerDetail={setCustomerDetail}
            errors={errors}
          />
        );
      case 1:
        return (
          <PaymentMethod
            cardData={cardData}
            setCardData={setCardData}
            checkbox={checkbox}
            setCheckbox={setCheckbox}
            handleCheckboxChange={handleCheckboxChange}
            showAlert={showAlert}
            SaveInfoCheckbox={SaveInfoCheckbox}
            handleSaveInfoCheckboxChange={handleSaveInfoCheckboxChange}
            handleCardDataFieldChange={handleCardDataFieldChange}
            cardDataErrors={cardDataErrors}
          />
        );
      case 2:
        return (
          <ReviewOrder
            cardData={cardData}
            customerDetail={customerDetail}
            buyNowProduct={buyNowProduct}
            totalPrice={totalPrice}
            productDetail={productDetail}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }

  return (
    <Card style={organizationCard}>
      <Box>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            flexGrow: 1,
            marginBottom: "2rem",
          }}
        >
          <Stepper
            id="desktop-stepper"
            activeStep={activeStep}
            sx={{
              width: "100%",
            }}
          >
            {steps.map((label) => (
              <Step
                sx={{
                  ":first-child": { pl: 0 },
                  ":last-child": { pr: 0 },
                }}
                key={label}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>
      <Card
        sx={{
          display: { xs: "flex", md: "none" },
          width: "100%",
        }}
      ></Card>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: isMobile ? "105%" : "100%",
          gap: { xs: 5, md: "none" },
          marginTop: isMobile && "1rem",
        }}
      >
        <Stepper
          id="mobile-stepper"
          activeStep={activeStep}
          alternativeLabel
          sx={{ display: { sm: "flex", md: "none" } }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>
                <Typography
                  sx={{
                    fontSize: isMobile ? "12px" : "16px",
                    fontWeight: isMobile && "100",
                    margin: isMobile && 0,
                    padding: isMobile && 0,
                  }}
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {activeStep === steps.length ? (
        <Stack spacing={2} useFlexGap>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CheckCircleOutlineIcon sx={{ fontSize: 200, color: green[500] }} />
          </Box>
          <Typography
            variant="h5"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            Thank you for your order!
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            Your order is placed. We will mail you once your order will be
            ready.
          </Typography>
          <Button
            variant="contained"
            style={{
              position: "absolute",
              bottom: isMobile ? 10 : 30,
              right: isMobile ? 100 : 48,
              textTransform: "inherit",
            }}
            onClick={() => navigate("/subscription")}
          >
            Go to my plans
          </Button>
        </Stack>
      ) : (
        <React.Fragment>
          {getStepContent(activeStep)}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", sm: "row" },
              justifyContent: activeStep !== 0 ? "space-between" : "flex-end",
              alignItems: isMobile ? "center" : "end",
              flexGrow: 1,
              gap: 1,
              pb: { xs: isMobile ? 4 : 12, sm: 0 },
              mt: { xs: isMobile ? 2 : 2, sm: 0 },
            }}
          >
            {activeStep !== 0 && (
              <Button
                startIcon={<ChevronLeftRoundedIcon />}
                onClick={handleBack}
                variant="outlined"
                style={{
                  position: "absolute",
                  bottom: isMobile ? 20 : 20,
                  left: isMobile ? 5 : 48,
                  border: "none",
                  textTransform: "inherit",
                }}
              >
                Previous
              </Button>
            )}
            <LoadingButton
              onClick={handleNextCombined}
              endIcon={<ChevronRightRoundedIcon />}
              loading={loading}
              loadingPosition="end"
              variant="contained"
              style={{
                position: "absolute",
                bottom: isMobile ? 20 : 20,
                right: isMobile ? 15 : 48,
                textTransform: "inherit",
              }}
            >
              {activeStep === steps.length - 1 ? "Place order" : "Next"}
            </LoadingButton>
          </Box>
        </React.Fragment>
      )}
    </Card>
  );
}