import Login from "../screen/login";
import Dashboard from "../screen/dashboard";
import SignUp from "../screen/signup";
import Organization from "../screen/organiztion";
import SignUpWorkspace from "../screen/signup_workspace";
import SignUpUserInfo from "../screen/signup_userInfo";
import SignUpVerification from "../screen/signup_verification";
import Shop from "../screen/shop";
import File from "../screen/files";
import Team from "../screen/team";
import Subscription from "../screen/subscription";
import Helpdesk from "../screen/helpdesk";
import Chat from "../screen/chat";
import SingleProduct from "../screen/single_product";
import Cart from "../screen/cart";
import Profile from "../screen/profile";
import ProductScreen from "../screen/ProductScreen";
import MyLinks from "../screen/my_links";
import ChooseOrganization from "../screen/choose_organization";
import TeamUserLogin from "../screen/teamUser_login";
import PlanScreen from "../screen/PlanScreen";
import CartScreen from "../screen/CartScreen";
import BillingsScreen from "../screen/BillingsScreen";
import AllProducts from "../screen/all_products";
import AddProduct from "../screen/add_new_product";
import Order from "../screen/order_history";
import ResetPassword from "../screen/reset_password";
import ConfirmPasswordReset from "../screen/confirm_reset_password";
import PlanPaymentScreen from "../screen/paymentScreenPlan";
import CheckoutWizard from "../screen/checkout_wizard";
import AddWorkspaceName from "../screen/addWorkspaceName";
import AddWorkspaceUserInfo from "../screen/add_workspace_userinfo";
import Template from "../screen/template";
import WorkspaceStatistics from "../screen/workspace_statistics";
import TicketRequest from "../screen/ticketRequest";
import Performance from "../screen/performance";
import Wiki from "../screen/wiki";
import ConfirmDomainName from "../screen/confirmDomainName";
// import HealthCheckDashboard from "../screen/NewPerformancePage";

export const RoutingList = [
  {
    route: "/",
    element: <ProductScreen />,
    is_protected: false,
  },
  {
    route: "/login",
    element: <Login />,
    is_protected: false,
  },
  {
    route: "/choose-organization",
    element: <ChooseOrganization />,
    is_protected: true,
    allowedRoles: [
      "super_admin",
      "workspace_admin",
      "advisor",
      "workspace_member",
    ],
  },
  {
    route: "/team-login",
    element: <TeamUserLogin />,
    is_protected: true,
    allowedRoles: [
      "super_admin",
      "workspace_admin",
      "advisor",
      "workspace_member",
    ],
  },
  {
    route: "/signup",
    element: <SignUp />,
    is_protected: false,
  },
  {
    route: "/reset_password_request",
    element: <ResetPassword />,
    is_protected: false,
  },
  {
    route: "/reset_password",
    element: <ConfirmPasswordReset />,
    is_protected: false,
  },
  {
    route: "/add-organization",
    element: <Organization />,
    is_protected: false,
  },
  {
    route: "/signup-workspace",
    element: <SignUpWorkspace />,
    is_protected: false,
  },
  {
    route: "/signup-userinfo",
    element: <SignUpUserInfo />,
    is_protected: false,
  },
  {
    route: "/confirm-domain",
    element: <ConfirmDomainName />,
    is_protected: false,
  },
  {
    route: "/signup-verification",
    element: <SignUpVerification />,
    is_protected: false,
  },
  {
    route: "/home",
    element: <Dashboard />,
    is_protected: true,
    allowedRoles: [
      "super_admin",
      "workspace_admin",
      "advisor",
      "workspace_member",
    ],
  },
  {
    route: "/performance",
    // element: <HealthCheckDashboard />,
    element: <Performance />,
    is_protected: true,
    allowedRoles: [
      "super_admin",
      "workspace_admin",
      "advisor",
      "workspace_member",
    ],
  },
  {
    route: "/ticket-request",
    element: <TicketRequest />,
    is_protected: true,
    allowedRoles: [
      "super_admin",
      "workspace_admin",
      "advisor",
      "workspace_member",
    ],
  },
  {
    route: "/messages",
    element: <Chat />,
    is_protected: true,
    allowedRoles: [
      "super_admin",
      "workspace_admin",
      "advisor",
      "workspace_member",
    ],
  },
  {
    route: "/shop",
    element: <Shop />,
    is_protected: true,
    allowedRoles: [
      "super_admin",
      "workspace_admin",
      "advisor",
      "workspace_member",
    ],
  },
  {
    route: "/shop/:id?",
    element: <SingleProduct />,
    is_protected: true,
    allowedRoles: [
      "super_admin",
      "workspace_admin",
      "advisor",
      "workspace_member",
    ],
  },
  {
    route: "/template",
    element: <Template />,
    is_protected: true,
    allowedRoles: ["super_admin", "workspace_admin", "advisor"],
  },
  {
    route: "/files",
    element: <File />,
    is_protected: true,
    allowedRoles: ["super_admin", "workspace_admin"],
  },
  {
    route: "/team",
    element: <Team />,
    is_protected: true,
    allowedRoles: [
      "super_admin",
      "workspace_admin",
      "advisor",
      "workspace_member",
    ],
  },
  {
    route: "/subscription",
    element: <Subscription />,
    is_protected: true,
    allowedRoles: [
      "super_admin",
      "workspace_admin",
      "advisor",
      "workspace_member",
    ],
  },
  {
    route: "/orders",
    element: <Order />,
    is_protected: true,
    allowedRoles: [
      "super_admin",
      "workspace_admin",
      "advisor",
      // "workspace_member",
    ],
  },
  {
    route: "/helpdesk",
    element: <Helpdesk />,
    is_protected: true,
    allowedRoles: [
      "super_admin",
      "workspace_admin",
      "advisor",
      "workspace_member",
    ],
  },
  {
    route: "/billing",
    element: <CheckoutWizard />,
    is_protected: true,
    allowedRoles: ["super_admin", "workspace_admin", "advisor"],
  },
  {
    route: "/cart",
    element: <Cart />,
    is_protected: true,
    allowedRoles: ["super_admin", "workspace_admin", "advisor"],
  },
  {
    route: "/profile-setting",
    element: <Profile />,
    is_protected: true,
    allowedRoles: [
      "super_admin",
      "workspace_admin",
      "advisor",
      "workspace_member",
    ],
  },
  {
    route: "/links",
    element: <MyLinks />,
    is_protected: true,
    allowedRoles: [
      "super_admin",
      "workspace_admin",
      "advisor",
      "workspace_member",
    ],
  },
  {
    route: "/product/:productDetails",
    element: <PlanScreen />,
    is_protected: false,
  },
  {
    route: "/cart-item",
    element: <CartScreen />,
    is_protected: false,
  },
  {
    route: "/billings",
    element: <BillingsScreen />,
    is_protected: false,
  },
  {
    route: "/store_management",
    element: <AllProducts />,
    is_protected: true,
    allowedRoles: ["super_admin"],
  },
  {
    route: "/add_product",
    element: <AddProduct />,
    is_protected: true,
    allowedRoles: ["super_admin"],
  },
  {
    route: "/add_product/:id?",
    element: <AddProduct />,
    is_protected: true,
    allowedRoles: ["super_admin"],
  },
  {
    route: "/payment_address_settings",
    element: <PlanPaymentScreen />,
    is_protected: true,
    allowedRoles: ["super_admin", "workspace_admin", "advisor"],
  },
  {
    route: "/new_workspace_name",
    element: <AddWorkspaceName />,
    is_protected: true,
    allowedRoles: [
      "super_admin",
      "workspace_admin",
      "advisor",
      "workspace_member",
    ],
  },
  {
    route: "/new_workspace_userinfo",
    element: <AddWorkspaceUserInfo />,
    is_protected: true,
    allowedRoles: [
      "super_admin",
      "workspace_admin",
      "advisor",
      "workspace_member",
    ],
  },
  {
    route: "/workspace-statistics",
    element: <WorkspaceStatistics />,
    is_protected: true,
    allowedRoles: ["super_admin"],
  },
  {
    route: "/wiki",
    element: <Wiki />,
    is_protected: true,
    allowedRoles: ["super_admin"],
  },
];
