import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  headerText: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  link: {
    fontSize: "12px",
    textDecoration: "none",
    color: "#fff",
  },
  transactionId: {
    fontWeight: "bold",
  },
  status: {
    marginBottom: "20px",
  },
  section: {
    marginBottom: "20px",
  },
  sectionHeader: {
    backgroundColor: "#e0e0e0",
    padding: "10px",
    fontWeight: "bold",
  },
  sectionContent: {
    padding: "10px",
    borderBottom: "1px solid #e0e0e0",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
    marginTop: "20px",
  },
  button: {
    padding: "5px 10px",
    fontSize: "12px",
    cursor: "pointer",
  },
};

const TransactionDetailModal = ({
  handleCloseTransDetailModal,
  openTransDetailModal,
  transDetail,
  formatString,
  formatUtcDate,
  userBillingData
}) => {
  const isStripe = transDetail?.paymentProvider === 'stripe';
  const renderSettlementInfo = () => (
    <div style={styles.section}>
      <div style={styles.sectionHeader}>Settlement Information</div>
      <div style={styles.sectionContent}>
        <p>
          Settlement Amount:{" "}
          <b>{`$ ${Number(transDetail?.settleAmount ?? 0)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            .toLocaleString("en-US")}`}</b>
        </p>
        {transDetail?.refundedAmount > 0 && (
          <p>
            Refunded Amount:{" "}
            <b>{`$ ${Number(transDetail?.refundedAmount ?? 0)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              .toLocaleString("en-US")}`}</b>
          </p>
        )}
        <p>
          Settlement Date and Time:{" "}
          <b>
            {isStripe
              ? formatUtcDate(transDetail?.settleDate)
              : formatUtcDate(transDetail?.submitTimeUTC) || formatUtcDate(transDetail?.settleDate)}
          </b>
        </p>
        {!isStripe && (
          <p>
            Batch ID: <b>{transDetail?.batch?.batchId}</b>
          </p>
        )}
      </div>
    </div>
  );

  const renderAuthInfo = () => (
    <div style={styles.section}>
      <div style={styles.sectionHeader}>
        {isStripe ? "Payment Information" : "Authorization Information"}
      </div>
      <div style={styles.sectionContent}>
        {!isStripe ? (
          <>
            <p>
              Authorization Amount:{" "}
              <b>{`$ ${Number(transDetail?.authAmount ?? 0)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                .toLocaleString("en-US")}`}</b>
            </p>
            <p>
              Authorization Code: <b>{transDetail?.authCode}</b>
            </p>
          </>
        ) : null}
        <p>
          Submit Date/Time:{" "}
          <b>
            {isStripe
              ? formatUtcDate(transDetail?.submitDate)
              : formatUtcDate(transDetail?.submitTimeLocal) || formatUtcDate(transDetail?.settleDate)}
          </b>
        </p>
        <p>
          Payment Method:{" "}
          <b>
            {isStripe
              ? `${formatString(transDetail?.accountType)} ${transDetail?.accountNumber}`
              : transDetail?.accountType}
          </b>
        </p>
        {isStripe && transDetail?.cardExpiration && (
          <p>
            Card Expiration: <b>{transDetail?.cardExpiration}</b>
          </p>
        )}
        {isStripe && transDetail?.cardCountry && (
          <p>
            Card Country: <b>{transDetail?.cardCountry}</b>
          </p>
        )}
        {!isStripe && (
          <>
            <p>
              Transaction Type:{" "}
              <b>{formatString(transDetail?.transactionType)}</b>
            </p>
            <p>
              Market Type: <b>{transDetail?.marketType}</b>
            </p>
            <p>
              Customer IP: <b>{transDetail?.customerIP}</b>
            </p>
          </>
        )}
      </div>
    </div>
  );

  const renderOrderInfo = () => (
    <div style={styles.section}>
      <div style={styles.sectionHeader}>Order Information</div>
      <div style={styles.sectionContent}>
        <p>
          Invoice #: <b>{transDetail?.invoiceNumber}</b>
        </p>
        {transDetail?.order?.description && (
          <p>
            Description: <b>{transDetail?.order?.description}</b>
          </p>
        )}

      </div>
    </div>
  );

  const renderRefundHistory = () => {
    if (!isStripe || !transDetail?.refundHistory?.length) return null;

    return (
      <div style={styles.section}>
        <div style={styles.sectionHeader}>Refund History</div>
        {transDetail.refundHistory.map((refund, index) => (
          <div key={refund.id} style={styles.sectionContent}>
            <p>
              Refund Amount:{" "}
              <b>{`$ ${Number(refund.amount)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</b>
            </p>
            <p>
              Date: <b>{formatUtcDate(refund.created)}</b>
            </p>
            {refund.description && (
              <p>
                Reason: <b>{refund.description}</b>
              </p>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderCustomerInfo = () => (
    <div style={styles.section}>
      <div style={styles.sectionHeader}>Customer Billing Information</div>
      <div style={styles.sectionContent}>
        <p>
          Name:{" "}
          <b>
            {isStripe
              ? transDetail?.firstName
              : `${transDetail?.billTo?.firstName} ${transDetail?.billTo?.lastName}`}
          </b>
        </p>
        {isStripe &&
          <>
            <p>
              Address: <b>{userBillingData[0]?.address}</b>
            </p>
            <p>
              City: <b>{userBillingData[0]?.city}</b>
            </p>
            <p>
              Country: <b>{userBillingData[0]?.country}</b>
            </p>
            <p>
              State: <b>{userBillingData[0]?.state}</b>
            </p>
            <p>
              Zip: <b>{userBillingData[0]?.postal_code}</b>
            </p>
          </>
        }

      </div>
    </div>
  );

  return (
    <Dialog
      open={openTransDetailModal}
      onClose={handleCloseTransDetailModal}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle style={{ color: "#0066cc" }}>
        Transaction Detail
        <IconButton
          aria-label="close"
          onClick={handleCloseTransDetailModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div style={styles.container}>

            <div style={styles.header}>
              <div style={styles.headerText}>
                Transaction ID:{" "}
                <span style={styles.transactionId}>{transDetail?.transId}</span>
              </div>
            </div>
            <div style={styles.status}>
              <strong>Transaction Status:</strong>{" "}
              {formatString(transDetail?.transactionStatus)}
            </div>
            <div style={{ justifyContent: 'flex-end', display: 'flex', padding: 5 }}>

              {isStripe && transDetail?.receiptUrl && (
                <Button variant="contained">
                  <a
                    href={transDetail.receiptUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={styles.link}
                  >
                    View Receipt
                  </a>
                </Button>
              )}
            </div>
            {renderSettlementInfo()}
            {renderAuthInfo()}
            {renderOrderInfo()}
            {renderRefundHistory()}
            {renderCustomerInfo()}
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default TransactionDetailModal;