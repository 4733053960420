import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Footer from "../components/Footer";
import { showToast } from "../redux/userSlice";
import { useDispatch } from "react-redux";
import {
  listCoupons,
  listProductCards,
  listProductVariations,
  listSubscriberCards,
  listSubscriptions,
  listUserBillings,
} from "../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { PulseLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import axios from "axios";
import {
  createSubscriptions,
  deleteSubscriptions,
  updateSubscriptions,
} from "../graphql/mutations";
import uuid from "react-uuid";
import { useMediaQuery } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { DataGrid } from "@mui/x-data-grid";
import Link from "@mui/material/Link";
import DeleteIcon from "@mui/icons-material/Delete";
import { loadStripe } from "@stripe/stripe-js";
import { stripeApi } from "../utills/stripeApis/stripeApi";
import './ticketRequest.css'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; // Add this import at the top

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Subscription = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const container = {
    margin: isMobile ? "1rem 1rem auto" : "2rem 4rem",
  };
  const title = {
    fontSize: isMobile ? "1rem" : "2rem",
    margin: "0px",
    marginBottom: isMobile ? "0rem" : "1rem",
  };
  const organizationCard = {
    padding: isMobile ? "0rem" : "1.5rem 2rem",
    boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
    borderRadius: "10px",
    minHeight: "68vh",
  };
  const statusStyleActive = {
    backgroundColor: "#C6E1C6",
    color: "#2E7D32",
    padding: "6px 10px 6px 10px",
    borderRadius: "4px",
    fontSize: "0.875rem",
    margin: 0
  };
  const statusStyleInActive = {
    backgroundColor: "#FFEBEE",
    color: "#C62828",
    padding: "6px 10px 6px 10px",
    borderRadius: "4px",
    fontSize: "0.875rem",
    margin: 0
  };
  const buttonStyle = {
    textTransform: "initial",
    fontSize: "1rem",
    border: "none",
    paddingLeft: 0,
  };
  const counterContainer = {
    display: "flex",
    alignItems: "center",
  };
  const counterButton = {
    color: "#1565C0",
  };
  const nameContainerStyle = {
    display: "inline-block",
    maxWidth: "150px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    verticalAlign: "middle",
  };
  const navigate = useNavigate();
  const client = generateClient();
  const dispatch = useDispatch();
  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const [subscriptionsList, setSubscriptionsList] = useState(null);
  const [loader, setLoader] = useState(false);
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const [open, setOpen] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [idToCancel, setIdToCancel] = useState(null);
  const [openItemModal, setOpenItemModal] = useState(false);
  const [productData, setProductData] = React.useState([]);
  const [purchaseOption, setPurchaseOption] = useState("");
  const [scheduleLaterDate, setScheduleLaterDate] = useState(null);
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeError, setPromoCodeError] = React.useState(false);
  const [couponData, setCouponData] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [netPrice, setNetPrice] = React.useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [appliedCoupon, setAppliedCoupon] = useState("");
  const [count, setCount] = useState(1);
  const [itemSelect, setItemSelect] = useState(null);
  const [plansById, setPlansById] = useState(null);
  const [plan, setPlan] = useState("");
  const [loading, setLoading] = useState(false);
  const [subscriberData, setSubscriberData] = useState(null);
  const [orderLoading, setOrderLoading] = useState(false);
  const [period, setPeriod] = React.useState("");
  const [every, setEvery] = React.useState("");
  const [idToDelete, setIdToDelete] = React.useState(null);
  const [userDetailDB, setUserDetailDB] = useState(null);
  const [holdModal, setHoldModal] = useState(false);
  const [subscriptionToHold, setSubscriptionToHold] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [bulkScheduleDate, setBulkScheduleDate] = useState(null);
  const [bulkSubscriptionModal, setBulkSubscriptionModal] = useState(false);
  const [bulkActions, setBulkActions] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [searchSubscriber, setSearchSubscriber] = useState("");
  const [bulkAction, setBulkAction] = useState("");
  const [allStatus, setAllStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [dateFilter, setDateFilter] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredRowId, setHoveredRowId] = React.useState(null);

  const statusContainer = {
    position: "relative",
    cursor: "pointer",
    width: "100%",
    zIndex: 1, // Ensure it appears above other elements

  };
  const hoverButtons = {
    position: "absolute",
    top: "70%",
    width: "100%",
    padding: "4px",
    display: "flex",
    zIndex: 999, // Ensure it's above other elements
  };


  const actionButton = {
    border: "none",
    background: "none",
    cursor: "pointer",
    color: "#1565C0",
    fontSize: "12px",
    textAlign: "center",
    margin: "0px"
  };



  const merchant_name = process.env.REACT_APP_MERCHANT_LOGIN_NAME;
  const transaction_key = process.env.REACT_APP_MERCHANT_TRANSACTION_KEY;
  const authorize_api = process.env.REACT_APP_AUTHORIZE_API;

  useEffect(() => {
    fetchSubscriptions();
    fetchProductsDetail();
    fetchSubscriberData();
  }, []);

  useEffect(() => {
    if (selectedProduct !== null) {
      fetchPlansById();
    }
  }, [selectedProduct]);


  const userBillingDetail = async () => {
    try {
      const variables = {
        filter: {
          workspace_id: {
            eq: selectedWorkspaceId,
          },
        },
      };

      const getUserBilling = await client.graphql({
        query: listUserBillings,
        variables: variables,
      });
      const res = getUserBilling.data.listUserBillings.items;
      setUserDetailDB(res);
    } catch (error) {
      console.error("Error creating todo:", error);
    }
  };

  const fetchSubscriberData = async () => {
    try {
      setLoading(true);
      const variables = {
        filter: {
          workspace_id: {
            eq: selectedWorkspaceId,
          },
        },
      };
      const getSubData = await client.graphql({
        query: listSubscriberCards,
        variables: variables,
      });
      const res = getSubData.data.listSubscriberCards.items;
      setSubscriberData(res);
      setLoading(false);
    } catch (error) {
      console.error("Error creating todo:", error);
    } finally {
      setLoading(false);
    }
  };

  async function fetchAllProductVariationsIds() {
    let allItems = [];
    let nextToken = null;

    do {
      const variables = {
        filter: {
          product_id: {
            eq: itemSelect?.[0]?.id,
          },
        },
        nextToken: nextToken,
      };
      const getCategories = await client.graphql({
        query: listProductVariations,
        variables: variables,
      });
      const response = getCategories?.data?.listProductVariations?.items || [];
      allItems = [...allItems, ...response];
      nextToken = getCategories?.data?.listProductVariations?.nextToken;
    } while (nextToken);
    return allItems;
  }
  const fetchPlansById = async () => {
    try {
      setLoading(true);
      const response = await fetchAllProductVariationsIds();
      const cleanedResponse = response.map((item) => {
        const cleanedItem = { ...item };
        if (cleanedItem.categories) {
          cleanedItem.categories = JSON.parse(cleanedItem.categories);
        }
        if (cleanedItem.tags) {
          cleanedItem.tags = JSON.parse(cleanedItem.tags);
        }
        return cleanedItem;
      });
      setPlansById(cleanedResponse);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error fetching orders:", error);
    }
  };

  const handlePlanChange = (event) => {
    const selectedPlan = event.target.value;
    const selectedPlanObject = plansById.find(
      (option) => option.name === selectedPlan
    );
    if (selectedPlanObject) {
      setPrice(selectedPlanObject.price);
      setTotalPrice(selectedPlanObject.price);
      setPlan(selectedPlan);
      setCount(1);
      setNetPrice(selectedPlanObject.price - totalDiscount);
      setPeriod(selectedPlanObject.period);
      setEvery(selectedPlanObject.every);
    } else {
      setPrice("");
      setPlan("");
      setCount(1);
      setPeriod("");
      setEvery("");
    }
  };

  const fetchProductsDetail = async () => {
    try {
      setLoader(true);
      const getProductData = await client.graphql({
        query: listProductCards,
      });

      const result = getProductData?.data?.listProductCards?.items;
      const sortedResult = result?.sort((a, b) => a.name.localeCompare(b.name));
      setProductData(sortedResult);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };
  const fetchSubscriptionsData = async (selectedWorkspaceId) => {
    let nextToken = null;
    let allItems = [];

    const variables = {
      filter: {
        workspace_id: {
          eq: selectedWorkspaceId,
        },
      },
      limit: 1000, // You can adjust this value
    };

    do {
      const getAddressData = await client.graphql({
        query: listSubscriptions,
        variables: {
          ...variables,
          nextToken: nextToken
        },
      });

      const items = getAddressData.data.listSubscriptions.items;
      allItems = [...allItems, ...items];
      nextToken = getAddressData.data.listSubscriptions.nextToken;
    } while (nextToken);

    return allItems;
  };

  const fetchSubscriptions = async () => {
    try {
      setLoader(true);
      const res = await fetchSubscriptionsData(selectedWorkspaceId);

      const updatedSubscriptions = await Promise.all(res.map(async (subscription) => {
        if (subscription.subscription_by === "stripe" && subscription.subscription_id) {
          try {
            const isSchedule = subscription.subscription_id.startsWith('sub_sched_');

            if (isSchedule) {
              // Fetch subscription schedule details
              const scheduleResponse = await stripeApi.get(
                `/subscription_schedules/${subscription.subscription_id}`
              );

              const scheduleData = scheduleResponse.data;
              const currentTimestamp = Math.floor(Date.now() / 1000);

              // Check if schedule has started
              const hasStarted = scheduleData.phases[0]?.start_date <= currentTimestamp;
              const hasEnded = scheduleData.phases[0]?.end_date &&
                scheduleData.phases[0].end_date <= currentTimestamp;

              // If schedule has started and has an active subscription
              if (hasStarted && scheduleData.subscription) {
                try {
                  const subscriptionResponse = await stripeApi.get(
                    `/subscriptions/${scheduleData.subscription}`
                  );
                  const activeSubscription = subscriptionResponse.data;

                  // Update database to use the new active subscription ID
                  await client.graphql({
                    query: updateSubscriptions,
                    variables: {
                      input: {
                        id: subscription.id,
                        is_schedule: false,
                        status: activeSubscription.status === 'active',
                        subscription_id: scheduleData.subscription // Update to new active subscription ID
                      }
                    }
                  });

                  const safeTimestamp = (timestamp) => {
                    if (!timestamp) return null;
                    try {
                      const date = new Date(timestamp * 1000);
                      return date.toISOString();
                    } catch (error) {
                      console.error('Invalid timestamp:', timestamp);
                      return null;
                    }
                  };

                  // Return active subscription data
                  return {
                    ...subscription,
                    is_schedule: false,
                    status: activeSubscription.status === 'active',
                    created_at: safeTimestamp(activeSubscription.created),
                    next_payment_date: safeTimestamp(activeSubscription.current_period_end),
                    current_period_start: safeTimestamp(activeSubscription.current_period_start),
                    current_period_end: safeTimestamp(activeSubscription.current_period_end),
                    subscription_id: activeSubscription.id, // Use new subscription ID
                    stripe_status: activeSubscription.status,
                    payment_status: activeSubscription.latest_invoice?.status || null
                  };
                } catch (error) {
                  console.error("Error fetching active subscription:", error);
                }
              }

              // If schedule hasn't started or no active subscription yet
              const safeTimestamp = (timestamp) => {
                if (!timestamp) return null;
                try {
                  const date = new Date(timestamp * 1000);
                  return date.toISOString();
                } catch (error) {
                  console.error('Invalid timestamp:', timestamp);
                  return null;
                }
              };

              // Return schedule data
              return {
                ...subscription,
                is_schedule: true,
                status: false,
                created_at: safeTimestamp(scheduleData.created),
                next_payment_date: safeTimestamp(scheduleData.phases[0]?.start_date),
                current_period_start: safeTimestamp(scheduleData.phases[0]?.start_date),
                current_period_end: safeTimestamp(scheduleData.phases[0]?.end_date),
                schedule_status: scheduleData.status,
                subscription_id: subscription.subscription_id // Keep schedule ID until active
              };

            } else {
              // Regular subscription handling
              const subscriptionResponse = await stripeApi.get(
                `/subscriptions/${subscription.subscription_id}`
              );

              const stripeSubscription = subscriptionResponse.data;

              const safeTimestamp = (timestamp) => {
                if (!timestamp) return null;
                try {
                  const date = new Date(timestamp * 1000);
                  return date.toISOString();
                } catch (error) {
                  console.error('Invalid timestamp:', timestamp);
                  return null;
                }
              };

              return {
                ...subscription,
                is_schedule: false,
                status: stripeSubscription.status === 'active',
                created_at: safeTimestamp(stripeSubscription.created),
                next_payment_date: safeTimestamp(stripeSubscription.current_period_end),
                current_period_start: safeTimestamp(stripeSubscription.current_period_start),
                current_period_end: safeTimestamp(stripeSubscription.current_period_end),
                stripe_status: stripeSubscription.status,
                payment_status: stripeSubscription.latest_invoice?.status || null
              };
            }
          } catch (error) {
            console.error(`Error fetching Stripe details for ${subscription.subscription_id}:`, error);
            return {
              ...subscription,
              next_payment_date: null,
              last_order_date: null,
              end_date: null,
              trial_end: null,
              current_period_start: null,
              current_period_end: null,
              stripe_error: error.message
            };
          }
        }
        return subscription;
      }));

      setSubscriptionsList(updatedSubscriptions);
    } catch (error) {
      console.error("Fetch subscriptions error:", error);
      dispatch(showToast({ message: error.message, type: "error" }));
    } finally {
      setLoader(false);
    }
  };

  React.useEffect(() => {
    const fetchCouponCode = async () => {
      const getCoupons = await client.graphql({
        query: listCoupons,
      });

      const res = getCoupons.data.listCoupons.items;
      setCouponData(res);
    };
    fetchCouponCode();
  }, []);

  // Helper function to format dates
  const formatDate = (dateString, showRelative = false) => {
    if (!dateString) return '-';

    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return '-';

      if (showRelative) {
        const now = new Date();
        const diffTime = Math.abs(now - date);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (date > now) {
          return `in ${diffDays} day${diffDays !== 1 ? 's' : ''}`;
        } else {
          return `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`;
        }
      }

      // Format as MM/DD/YYYY
      return date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      });
    } catch (error) {
      console.error('Date formatting error:', error);
      return '-';
    }
  };

  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
    setPrice(+price + +totalPrice);
    setNetPrice(+price + +totalPrice - +totalDiscount);
  };

  const handleDecrement = () => {
    if (count > 1) {
      setCount(count - 1);
      setPrice(+price - +totalPrice);
      setNetPrice(+price - +totalDiscount);
      setNetPrice(+price - +totalPrice - +totalDiscount);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseItemModal = () => {
    setOpenItemModal(false);
    setPurchaseOption("");
    setScheduleLaterDate(null);
    setPromoCode("");
    setPromoCodeError(false);
    setSelectedProduct(null);
    setTotalDiscount(0);
    setNetPrice(0);
    setTotalPrice(0);
    setPrice(0);
    setAppliedCoupon("");
    setCount(1);
    setItemSelect(null);
    setPlan("");
    setPeriod("");
    setEvery("");
  };

  const handleDateChange = (newValue) => {
    if (newValue) {
      setScheduleLaterDate(newValue);
    } else {
      setScheduleLaterDate(null);
    }
  };

  const scheduleLaterFormattedDate = scheduleLaterDate
    ? scheduleLaterDate.format("YYYY-MM-DD")
    : "";

  const handleCancelSubscription = (item) => {
    setIdToCancel(item);
    setOpen(true);
  };

  const handleOpenItemModal = () => {
    if (subscriberData?.[0]?.payment_profile_id !== null) {
      setOpenItemModal(true);
    } else {
      dispatch(
        showToast({
          message: "Please add your billing and payment method first",
          type: "error",
        })
      );
    }
  };

  const handleProductChange = (event, newValue) => {
    setSelectedProduct(newValue);
    if (productData?.length > 0) {
      const selectedItem = productData.filter((pro) => pro.name === newValue);
      if (selectedItem?.[0]?.type !== "variable subscription") {
        setPrice(selectedItem?.[0]?.price);
        setTotalPrice(selectedItem?.[0]?.price);
        setNetPrice(selectedItem?.[0]?.price);
        setPlan("");
        setAppliedCoupon("");
        setTotalDiscount(0);
        setPromoCode("");
        setCount(1);
        setPurchaseOption("");
        setItemSelect(selectedItem);
        setPeriod("");
        setEvery("");
        return;
      }
      setItemSelect(selectedItem);
    }
    setPrice(0);
    setTotalPrice(0);
    setNetPrice(0);
    setPlan("");
    setAppliedCoupon("");
    setTotalDiscount(0);
    setPromoCode("");
    setCount(1);
    setPurchaseOption("");
    setPeriod("");
    setEvery("");
  };

  const handleConfrimCancel = async () => {
    try {
      setOpen(false);
      setLoader(true);
      if (idToCancel.subscription_by === "stripe") {
        const stripe = await stripePromise;
        const secretKey = process.env.REACT_APP_STRIPE_SECRET_KEY;

        const response = await fetch(
          `https://api.stripe.com/v1/subscriptions/${idToCancel.subscription_id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${secretKey}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        const result = await response.json();

        if (result.error) {
          throw new Error(result.error.message);
        }

        if (result.status !== "canceled") {
          throw new Error("Failed to cancel the subscription");
        }
      } else {
        // Assume it's Authorize.net if not Stripe
        const cancelData = {
          ARBCancelSubscriptionRequest: {
            merchantAuthentication: {
              name: merchant_name,
              transactionKey: transaction_key,
            },
            subscriptionId: idToCancel.subscription_id,
          },
        };

        const response = await axios.post(authorize_api, cancelData);

        if (response.data.messages.resultCode === "Error") {
          throw new Error(response.data.messages.message[0].text);
        }

        if (response.data.messages.resultCode !== "Ok") {
          throw new Error("Failed to cancel the subscription");
        }
      }

      // If we've reached this point, the cancellation was successful
      const variables = {
        input: {
          id: idToCancel.id,
          status: false,
        },
      };

      await client.graphql({
        query: updateSubscriptions,
        variables: variables,
      });

      await fetchSubscriptions();
      setIdToCancel(null);
      dispatch(
        showToast({
          message: "You have cancelled your subscription successfully",
          type: "success",
        })
      );
    } catch (error) {
      dispatch(
        showToast({
          message: error.message,
          type: "error",
        })
      );
    } finally {
      setLoader(false);
    }
  };

  function generateInvoiceNumber() {
    const timestamp = Date.now().toString();
    const random = Math.floor(Math.random() * 1000)
      .toString()
      .padStart(3, "0");
    return `${timestamp}-${random}`;
  }

  const generateLineItemsId = () => {
    const newNumbers = Math.floor(100 + Math.random() * 900).toString();
    return newNumbers;
  };

  const adjustDate = () => {
    const newDate = new Date();
    if (period === "day") {
      newDate.setDate(newDate.getDate() + +every);
    } else if (period === "month") {
      newDate.setMonth(newDate.getMonth() + +every);
    }
    return newDate;
  };

  const handlePlaceOrder = async () => {
    const invoiceNumber = generateInvoiceNumber();
    const lineItemId = generateLineItemsId();
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    // Add one month

    const adjustedDate = adjustDate();
    // Format the adjusted date
    const yearAdd = adjustedDate.getFullYear();
    const monthAdd = String(adjustedDate.getMonth() + 1).padStart(2, "0");
    const dayAdd = String(adjustedDate.getDate()).padStart(2, "0");
    const formattedDateAdd = `${yearAdd}-${monthAdd}-${dayAdd}`;

    if (itemSelect?.[0]?.type === "variable subscription") {
      setOrderLoading(true);

      // instant payment

      if (purchaseOption !== "Schedule later") {
        const instantPayment = {
          createTransactionRequest: {
            merchantAuthentication: {
              name: merchant_name,
              transactionKey: transaction_key,
            },
            transactionRequest: {
              transactionType: "authCaptureTransaction",
              amount: +netPrice,
              profile: {
                customerProfileId: subscriberData?.[0]?.subscription_id,
                paymentProfile: {
                  paymentProfileId: subscriberData?.[0]?.payment_profile_id,
                },
              },
              order: {
                invoiceNumber: invoiceNumber,
              },
              lineItems: {
                lineItem: {
                  itemId: lineItemId,
                  name: itemSelect?.[0]?.name,
                  description: `${itemSelect?.[0]?.name} is a ${itemSelect?.[0]?.type}`,
                  quantity: count,
                  unitPrice: totalPrice,
                },
              },
              processingOptions: {
                isSubsequentAuth: "true",
              },
              authorizationIndicatorType: {
                authorizationIndicator: "final",
              },
            },
          },
        };
        const instantPaymentResponse = await axios.post(
          authorize_api,
          instantPayment
        );

        if (instantPaymentResponse.data.messages.resultCode === "Ok") {
          if (
            instantPaymentResponse.data.transactionResponse.messages[0].code ===
            "1"
          ) {
            const createSubscription = {
              ARBCreateSubscriptionRequest: {
                merchantAuthentication: {
                  name: merchant_name,
                  transactionKey: transaction_key,
                },
                subscription: {
                  name: itemSelect?.[0]?.name,
                  paymentSchedule: {
                    interval: {
                      length: every,
                      unit: `${period}s`,
                    },
                    startDate: formattedDateAdd,
                    totalOccurrences: "9999",
                  },
                  amount: +netPrice,
                  order: {
                    invoiceNumber: invoiceNumber,
                  },
                  profile: {
                    customerProfileId: subscriberData?.[0]?.subscription_id,
                    customerPaymentProfileId:
                      subscriberData?.[0]?.payment_profile_id,
                  },
                },
              },
            };
            const response = await axios.post(
              authorize_api,
              createSubscription
            );
            if (response.data.messages.resultCode === "Error") {
              setLoading(false);
              setOrderLoading(false);
              dispatch(
                showToast({
                  message: response.data.messages.message[0].text,
                  type: "error",
                })
              );
              return;
            }

            if (response.data.messages.resultCode === "Ok") {
              const sub_id = uuid();
              const variable = {
                id: sub_id,
                product_id: itemSelect?.[0]?.id,
                status: true,
                created_at:
                  purchaseOption === "Schedule later"
                    ? scheduleLaterFormattedDate
                    : formattedDate,
                workspace_id: selectedWorkspaceId,
                subscription_id: response.data.subscriptionId,
                product_image: itemSelect?.[0]?.image,
                product_name: itemSelect?.[0]?.name,
                product_type: itemSelect?.[0]?.type,
                product_period: period,
                product_sku: itemSelect?.[0]?.sku,
                product_plan: plan,
                product_quantity: count,
                product_price: +netPrice,
                invoice_number: invoiceNumber,
              };

              const createSubsCard = await client.graphql({
                query: createSubscriptions,
                variables: { input: variable },
              });

              setOrderLoading(false);
              setOpenItemModal(false);
              setPurchaseOption("");
              setScheduleLaterDate(null);
              setPromoCode("");
              setPromoCodeError(false);
              setSelectedProduct(null);
              setTotalDiscount(0);
              setNetPrice(0);
              setTotalPrice(0);
              setPrice(0);
              setAppliedCoupon("");
              setCount(1);
              setItemSelect(null);
              setPlan("");
              dispatch(
                showToast({
                  message: "Your order is placed successfully",
                  type: "success",
                })
              );
            }
          } else {
            setOrderLoading(false);
            dispatch(
              showToast({
                message:
                  instantPaymentResponse.data.transactionResponse.errors[0]
                    .errorText,
                type: "error",
              })
            );
            return;
          }
        } else {
          setOrderLoading(false);
          dispatch(
            showToast({
              message: instantPaymentResponse.data.messages.message[0].text,
              type: "error",
            })
          );
          return;
        }
      } else {
        const createSubscription = {
          ARBCreateSubscriptionRequest: {
            merchantAuthentication: {
              name: merchant_name,
              transactionKey: transaction_key,
            },
            subscription: {
              name: itemSelect?.[0]?.name,
              paymentSchedule: {
                interval: {
                  length: every,
                  unit: `${period}s`,
                },
                startDate:
                  purchaseOption === "Schedule later"
                    ? scheduleLaterFormattedDate
                    : formattedDateAdd,
                totalOccurrences: "9999",
              },
              amount: +netPrice,
              order: {
                invoiceNumber: invoiceNumber,
              },
              profile: {
                customerProfileId: subscriberData?.[0]?.subscription_id,
                customerPaymentProfileId:
                  subscriberData?.[0]?.payment_profile_id,
              },
            },
          },
        };
        const response = await axios.post(authorize_api, createSubscription);
        if (response.data.messages.resultCode === "Error") {
          setLoading(false);
          setOrderLoading(false);
          dispatch(
            showToast({
              message: response.data.messages.message[0].text,
              type: "error",
            })
          );
          return;
        }

        if (response.data.messages.resultCode === "Ok") {
          const sub_id = uuid();
          const variable = {
            id: sub_id,
            product_id: itemSelect?.[0]?.id,
            status: true,
            created_at: scheduleLaterFormattedDate,
            workspace_id: selectedWorkspaceId,
            subscription_id: response.data.subscriptionId,
            product_image: itemSelect?.[0]?.image,
            product_name: itemSelect?.[0]?.name,
            product_type: itemSelect?.[0]?.type,
            product_period: period,
            product_sku: itemSelect?.[0]?.sku,
            product_plan: plan,
            product_quantity: count,
            product_price: +netPrice,
            invoice_number: invoiceNumber,
          };

          const createSubsCard = await client.graphql({
            query: createSubscriptions,
            variables: { input: variable },
          });

          setOrderLoading(false);
          setOpenItemModal(false);
          setPurchaseOption("");
          setScheduleLaterDate(null);
          setPromoCode("");
          setPromoCodeError(false);
          setSelectedProduct(null);
          setTotalDiscount(0);
          setNetPrice(0);
          setTotalPrice(0);
          setPrice(0);
          setAppliedCoupon("");
          setCount(1);
          setItemSelect(null);
          setPlan("");
          dispatch(
            showToast({
              message: "Your order is placed successfully",
              type: "success",
            })
          );
        }
      }
    } else {
      setOrderLoading(true);
      const buyProduct = {
        createTransactionRequest: {
          merchantAuthentication: {
            name: merchant_name,
            transactionKey: transaction_key,
          },
          transactionRequest: {
            transactionType: "authCaptureTransaction",
            amount: +netPrice,
            profile: {
              customerProfileId: subscriberData?.[0]?.subscription_id,
              paymentProfile: {
                paymentProfileId: subscriberData?.[0]?.payment_profile_id,
              },
            },
            order: {
              invoiceNumber: invoiceNumber,
            },
            lineItems: {
              lineItem: {
                itemId: lineItemId,
                name: itemSelect?.[0]?.name,
                description: `${itemSelect?.[0]?.name} is a ${itemSelect?.[0]?.type}`,
                quantity: count,
                unitPrice: totalPrice,
              },
            },
            processingOptions: {
              isSubsequentAuth: "true",
            },
            authorizationIndicatorType: {
              authorizationIndicator: "final",
            },
          },
        },
      };

      const response = await axios.post(authorize_api, buyProduct);

      if (response.data.messages.resultCode === "Ok") {
        if (response.data.transactionResponse.errors[0].errorCode === "1") {
          setOrderLoading(false);
          setOpenItemModal(false);
          setPurchaseOption("");
          setScheduleLaterDate(null);
          setPromoCode("");
          setPromoCodeError(false);
          setSelectedProduct(null);
          setTotalDiscount(0);
          setNetPrice(0);
          setTotalPrice(0);
          setPrice(0);
          setAppliedCoupon("");
          setCount(1);
          setItemSelect(null);
          setPlan("");
          dispatch(
            showToast({
              message: "Your order is placed successfully",
              type: "success",
            })
          );
        } else {
          setLoading(false);
          setOrderLoading(false);
          dispatch(
            showToast({
              message: response.data.transactionResponse.errors[0].errorText,
              type: "error",
            })
          );
          return;
        }
      } else {
        setLoading(false);
        setOrderLoading(false);
        dispatch(
          showToast({
            message: response.data.messages.message[0].text,
            type: "error",
          })
        );
        return;
      }
    }
    fetchSubscriptions();
    setOrderLoading(false);
  };

  const handleProductDiscountDollar = (findPromoCode, selectedItem) => {
    const updatedProductDetail = selectedItem?.map((product) => {
      let productDiscount = 0;
      if (selectedItem.some((p) => p.id === product.id)) {
        productDiscount = findPromoCode?.amount;
      }
      return {
        ...product,
        discount: +productDiscount,
      };
    });
    const totalDiscountOnPrices = updatedProductDetail.reduce(
      (acc, product) => {
        return acc + parseFloat(product.discount || 0);
      },
      0
    );
    setNetPrice(+price - +totalDiscountOnPrices);
    setTotalDiscount(totalDiscountOnPrices);
    setAppliedCoupon(findPromoCode.code);
  };

  const handleProductDiscountPercentage = (findPromoCode, selectedItem) => {
    const totalDiscountPercent = findPromoCode?.percent;

    const updatedProductDetail = selectedItem.map((product) => {
      const discountAmount = (totalDiscountPercent / 100) * price;
      return {
        ...product,
        discount: selectedItem.some((p) => p.id === product.id)
          ? +discountAmount.toFixed(2)
          : 0,
      };
    });
    const totalDiscountOnPrices = updatedProductDetail.reduce(
      (acc, product) => {
        return acc + parseFloat(product.discount || 0);
      },
      0
    );
    setNetPrice(+price - +totalDiscountOnPrices);
    setTotalDiscount(totalDiscountOnPrices);
    setAppliedCoupon(findPromoCode.code);
  };

  const handleFixedProductDiscount = (findPromoCode, selectedItem) => {
    if (findPromoCode?.allowed_email?.length > 0) {
      if (findPromoCode?.product_id?.length > 0) {
        const isMatchedEmail = findPromoCode?.allowed_email?.some(
          (email) => email.toLowerCase() === userData.email.toLowerCase()
        );
        if (isMatchedEmail) {
          if (!findPromoCode?.never_expire) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            const startDate = new Date(findPromoCode?.expire_start_date);
            startDate.setHours(0, 0, 0, 0);

            const endDate = new Date(findPromoCode?.expire_end_date);
            endDate.setHours(0, 0, 0, 0);
            if (startDate <= today && today <= endDate) {
              if (findPromoCode?.amount_type === "Dollar off") {
                handleProductDiscountDollar(findPromoCode, selectedItem);
              } else {
                handleProductDiscountPercentage(findPromoCode, selectedItem);
              }
            } else {
              dispatch(
                showToast({ message: "Promo code expired", type: "error" })
              );
              return;
            }
          } else {
            if (findPromoCode?.amount_type === "Dollar off") {
              handleProductDiscountDollar(findPromoCode, selectedItem);
            } else {
              handleProductDiscountPercentage(findPromoCode, selectedItem);
            }
          }
        } else {
          dispatch(showToast({ message: "Invalid promo code", type: "error" }));
          return;
        }
      } else {
        dispatch(showToast({ message: "Invalid promo code", type: "error" }));
        return;
      }
    } else {
      if (findPromoCode?.product_id?.length > 0) {
        if (!findPromoCode?.never_expire) {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const startDate = new Date(findPromoCode?.expire_start_date);
          startDate.setHours(0, 0, 0, 0);
          const endDate = new Date(findPromoCode?.expire_end_date);
          endDate.setHours(0, 0, 0, 0);
          if (startDate <= today && today <= endDate) {
            if (findPromoCode?.amount_type === "Dollar off") {
              handleProductDiscountDollar(findPromoCode, selectedItem);
            } else {
              handleProductDiscountPercentage(findPromoCode, selectedItem);
            }
          } else {
            dispatch(
              showToast({ message: "Promo code expired", type: "error" })
            );
            return;
          }
        } else {
          if (findPromoCode?.amount_type === "Dollar off") {
            handleProductDiscountDollar(findPromoCode, selectedItem);
          } else {
            handleProductDiscountPercentage(findPromoCode, selectedItem);
          }
        }
      } else {
        dispatch(showToast({ message: "Invalid promo code", type: "error" }));
        return;
      }
    }
  };

  const applyToken = () => {
    if (promoCode === "") {
      setPromoCodeError(true);
      return;
    }
    try {
      const findPromoCode = couponData?.find((item) => item.code === promoCode);
      const selectedItem = productData?.filter(
        (pro) => pro.name === selectedProduct
      );
      const matchingProduct = findPromoCode?.product_id?.find(
        (productId) => productId === selectedItem[0].id
      );

      if (findPromoCode && matchingProduct && selectedItem?.length > 0) {
        if (findPromoCode?.type === "Fixed product discount") {
          handleFixedProductDiscount(findPromoCode, selectedItem);
        }
      } else {
        dispatch(showToast({ message: "Invalid promo code", type: "error" }));
        return;
      }
    } catch (error) {
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error", error);
    }
  };
  const handleHoldSubscription = (subscription) => {
    setSubscriptionToHold(subscription);
    setHoldModal(true);
  };
  const handleConfirmHold = async () => {
    try {
      setLoader(true);

      if (subscriptionToHold.subscription_by === "stripe") {
        const secretKey = process.env.REACT_APP_STRIPE_SECRET_KEY;

        // Fetch subscription status
        const fetchResponse = await fetch(
          `https://api.stripe.com/v1/subscriptions/${subscriptionToHold.subscription_id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${secretKey}`,
            },
          }
        );

        const subscription = await fetchResponse.json();

        if (subscription.status === "canceled") {
          throw new Error("This subscription is already canceled or not started yet and cannot be paused.");
        }

        // Pause the subscription
        const pauseResponse = await fetch(
          `https://api.stripe.com/v1/subscriptions/${subscriptionToHold.subscription_id}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${secretKey}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: "pause_collection[behavior]=void",
          }
        );

        const pauseResult = await pauseResponse.json();
        if (pauseResult.error) {
          throw new Error(pauseResult.error.message);
        }
      } else {
        // Handle Authorize.net subscription logic
        const holdData = {
          ARBUpdateSubscriptionRequest: {
            merchantAuthentication: {
              name: merchant_name,
              transactionKey: transaction_key,
            },
            subscriptionId: subscriptionToHold.subscription_id,
            subscription: {
              status: "held",
            },
          },
        };

        const response = await axios.post(authorize_api, holdData);

        if (response.data.messages.resultCode === "Error") {
          throw new Error(response.data.messages.message[0].text);
        }
      }

      // Update subscription status in the database
      const variables = {
        input: {
          id: subscriptionToHold.id,
          status: true,
          subscription_on_hold: true,
        },
      };

      await client.graphql({
        query: updateSubscriptions,
        variables: variables,
      });

      await fetchSubscriptions();
      setHoldModal(false);
      setSubscriptionToHold(null);
      dispatch(
        showToast({
          message: "Subscription has been paused successfully",
          type: "success",
        })
      );
    } catch (error) {
      dispatch(
        showToast({
          message: error.message,
          type: "error",
        })
      );
    } finally {
      setLoader(false);
    }
  };

  const handleResumeSubscription = async (subscription) => {
    try {
      setLoader(true);

      if (subscription.subscription_by === "stripe") {
        // Resume Stripe subscription by removing pause_collection
        const response = await stripeApi.post(
          `/subscriptions/${subscription.subscription_id}`,
          {
            pause_collection: '' // This tells Stripe to remove the pause_collection object
          }
        );

        if (!response.data || response.data.error) {
          throw new Error(response.data?.error?.message || 'Failed to resume subscription');
        }

        // Verify that pause is removed
        if (response.data.pause_collection) {
          throw new Error('Failed to resume subscription - still paused');
        }

        // Update subscription status in database
        const variables = {
          input: {
            id: subscription.id,
            status: true,
            subscription_on_hold: false
          }
        };

        await client.graphql({
          query: updateSubscriptions,
          variables: variables,
        });

        await fetchSubscriptions();
        dispatch(
          showToast({
            message: "Subscription has been resumed successfully",
            type: "success",
          })
        );
      } else {
        // Handle Authorize.net resume logic
        const resumeData = {
          ARBUpdateSubscriptionRequest: {
            merchantAuthentication: {
              name: merchant_name,
              transactionKey: transaction_key,
            },
            subscriptionId: subscription.subscription_id,
            subscription: {
              status: "active"
            }
          }
        };

        const response = await axios.post(authorize_api, resumeData);

        if (response.data.messages.resultCode === "Error") {
          throw new Error(response.data.messages.message[0].text);
        }
      }
    } catch (error) {
      console.error("Resume subscription error:", error);
      dispatch(
        showToast({
          message: error.message || "Failed to resume subscription",
          type: "error",
        })
      );
    } finally {
      setLoader(false);
    }
  };

  const today = dayjs();
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // Select all items
      setSelectedItems(rows);
    } else {
      // Deselect all items
      setSelectedItems([]);
    }
  };
  const columns = [
    {
      field: 'checkbox',
      headerName: '',
      width: 70,
      renderCell: (params) => (
        <Checkbox
          checked={selectedItems.some(item => item.id === params.row.id)}
          onChange={() => handleItemSelect(params.row)}
        />
      ),
      renderHeader: () => (
        <Checkbox
          checked={rows.length > 0 && selectedItems.length === rows.length}
          indeterminate={selectedItems.length > 0 && selectedItems.length < rows.length}
          onChange={handleSelectAllClick}
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      renderCell: (params) => {
        const isHovered = hoveredRowId === params.row.id;

        const enhancedStatusContainer = {
          ...statusContainer,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '100%',
          height: '100%',
          padding: '4px',
          boxSizing: 'border-box',
          // marginTop: "0.7rem"
        };

        const enhancedHoverButtons = {
          ...hoverButtons,
          display: isHovered ? 'flex' : 'none',
          gap: '8px',
          marginTop: '1px'
        };

        // For "Added in Plan" case
        if (!params.row.subscription_id) {
          return (
            <div style={{ cursor: 'pointer' }}
              onMouseEnter={() => setHoveredRowId(params.row.id)}
              onMouseLeave={() => setHoveredRowId(null)}
            >
              <p style={{
                ...statusStyleInActive,

                color: 'black'
              }}>
                Added in plan
              </p>
              {
                <div style={enhancedHoverButtons}>
                  <span onClick={() => handleOpenDeleteModal(params?.row?.id)} style={{ ...actionButton, }}>Trash</span>

                </div>
              }
            </div>
          );
        }
        if (params.row.is_schedule) {
          return <div
            style={enhancedStatusContainer}
            onMouseEnter={() => setHoveredRowId(params.row.id)}
            onMouseLeave={() => setHoveredRowId(null)}
          >
            <p style={{
              ...statusStyleInActive,
              textAlign: 'center',
            }}>
              Scheduled
            </p>
            {/* <div style={enhancedHoverButtons}>
              <span onClick={() => cancelSubscriptionSchedule(params?.row)} style={{ ...actionButton, }}>Cancel Schedule</span>

            </div> */}

          </div>;
        }

        // For "Inactive" case
        if (!params.row.status) {
          return (
            <div
              style={enhancedStatusContainer}
              onMouseEnter={() => setHoveredRowId(params.row.id)}
              onMouseLeave={() => setHoveredRowId(null)}
            >
              <p style={{
                ...statusStyleInActive,
                textAlign: 'center',
              }}>
                In Active
              </p>
              {
                <div style={enhancedHoverButtons}>
                  <span onClick={() => handleOpenDeleteModal(params?.row?.id)} style={{ ...actionButton, }}>Trash</span>

                </div>
              }
            </div>
          );
        }

        // For "On Hold" case
        if (params.row.subscription_on_hold === true) {
          return (
            <div
              style={enhancedStatusContainer}
              onMouseEnter={() => setHoveredRowId(params.row.id)}
              onMouseLeave={() => setHoveredRowId(null)}
            >
              <p style={{
                ...statusStyleActive,
                backgroundColor: "#F8DDA7",
                color: "#574516",

              }}>
                On Hold
              </p>
              {isHovered && (
                <div style={enhancedHoverButtons}>
                  <span onClick={() => handleResumeSubscription(params?.row)} style={{ ...actionButton, }}>Activate</span>|
                  <span onClick={() => handleCancelSubscription(params?.row)} style={{ ...actionButton, }}>Cancel</span>
                </div>
              )}
            </div>
          );
        }

        // For "Active" case
        return (
          <div
            style={enhancedStatusContainer}
            onMouseEnter={() => setHoveredRowId(params.row.id)}
            onMouseLeave={() => setHoveredRowId(null)}
          >
            <p style={{
              ...statusStyleActive,
              textAlign: 'center',
            }}>
              Active
            </p>
            {isHovered && (
              <div style={enhancedHoverButtons}>
                <span onClick={() => handleHoldSubscription(params?.row)} style={{ ...actionButton, }}>On Hold</span> |
                <span onClick={() => handleCancelSubscription(params?.row)} style={{ ...actionButton, }}>Cancel</span>
              </div>
            )}
          </div>
        );
      }
    },

    {
      field: "items",
      headerName: "Items",
      width: 250,
      renderCell: (params) => (
        <div
          style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}
        >
          <img
            src={params.row.image}
            alt={params.row.name}
            style={{
              width: 50,
              height: 50,
              borderRadius: "10%",
              marginRight: 8,
            }}
          />
          {params.row.name}
        </div>
      )
    },
    {
      field: "total",
      headerName: "Total",
      width: 140,
      renderCell: (params) => (
        <Box>
          <Typography variant="body2">
            ${(params.row.product_price).toLocaleString("en-US")}/{params.row.product_period}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Via {getPaymentMethodDisplay(params.row.subscription_by)}
          </Typography>
        </Box>
      )
    },
    {
      field: "start_date",
      headerName: "Start Date",
      width: 120,
      renderCell: (params) => (

        <Typography variant="body2">
          {formatDate(params.row.created_at)}
        </Typography>
      )
    },
    {
      field: "trial_end",
      headerName: "Trial End",
      width: 120,
      renderCell: (params) => (
        <Typography variant="body2">
          {params.row.trial_end ? formatDate(params.row.trial_end) : '-'}
        </Typography>
      )
    },
    {
      field: "next_payment",
      headerName: "Next Payment",
      width: 120,
      renderCell: (params) => {
        if (params.row.status && params.row.current_period_end) {
          return (
            <Typography variant="body2">
              {formatDate(params.row.current_period_end, true)} {/* Show relative date */}
            </Typography>
          );
        }
        return <Typography variant="body2">-</Typography>;
      }
    },
    {
      field: "last_order_date",
      headerName: "Last Order Date",
      width: 120,
      renderCell: (params) => {
        const dateToShow = params.row.last_order_date || params.row.current_period_start;
        return (
          <Typography variant="body2">
            {formatDate(dateToShow)} {/* Show exact date */}
          </Typography>
        );
      }
    },
    {
      field: "end_date",
      headerName: "End Date",
      width: 120,
      renderCell: (params) => {
        if (!params.row.status) {
          return (
            <Typography variant="body2">
              {formatDate(params.row.updatedAt)}
            </Typography>
          );
        }
        if (params.row.end_date) {
          return (
            <Typography variant="body2">
              {formatDate(params.row.end_date)}
            </Typography>
          );
        }

        return <Typography variant="body2">-</Typography>;
      }
    },
    // {
    //   field: "orders",
    //   headerName: "Orders",
    //   width: 100,
    //   renderCell: (params) => (
    //     <Link
    //       component="button"
    //       variant="body2"
    //       onClick={() => navigate(`/orders?subscription=${params.row.subscription_id}`)}
    //     >
    //       {params.row.order_count || '0'}
    //     </Link>
    //   )
    // }
  ];


  const rows = subscriptionsList?.map((item) => ({
    id: item.id,
    status: item.status,
    name: item.product_name,
    image: item.product_image,
    subscription_id: item.subscription_id,
    product_name: item.product_name,
    product_price: parseFloat(item.product_price),
    product_period: item.product_period,
    created_at: item.createdAt,
    subscription_by: item.subscription_by,
    subscription_on_hold: item.subscription_on_hold,
    is_schedule: item?.is_schedule,
    // Add all date fields explicitly
    current_period_end: item.current_period_end || null,
    current_period_start: item.current_period_start || null,
    next_payment_date: item.next_payment_date || null,
    last_order_date: item.last_order_date || null,
    end_date: item.end_date || null,
    trial_end: item.trial_end || null,
    updatedAt: item.updatedAt || null
  }));

  const handleOpenDeleteModal = (id) => {
    setOpenDeleteModal(true);
    setIdToDelete(id);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setIdToDelete(null);
  };

  const handleConfirmDelete = async () => {
    setOpenDeleteModal(false);
    setLoader(true);
    try {
      const variables = {
        input: {
          id: idToDelete,
        },
      };

      const deleteSubsciptionRow = await client.graphql({
        query: deleteSubscriptions,
        variables: variables,
      });
      await fetchSubscriptions();
      setIdToDelete(null);
      setLoader(false);
      dispatch(
        showToast({
          message: "Plan is deleted successfully",
          type: "success",
        })
      );
    } catch (error) {
      setLoader(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error", error);
    }
  };

  const handleItemSelect = (item) => {
    setSelectedItems((prevSelected) => {
      // Check if item is already selected
      const isItemSelected = prevSelected.some(selectedItem => selectedItem.id === item.id);

      if (isItemSelected) {
        // Remove item if already selected
        return prevSelected.filter(selectedItem => selectedItem.id !== item.id);
      } else {
        // Add item if not selected
        return [...prevSelected, item];
      }
    });
  };

  const fetchPriceId = async (productId) => {
    try {
      // Fetch the product details including stripe_price_id
      const productData = await client.graphql({
        query: listProductCards,
        variables: {
          filter: {
            id: { eq: productId }
          }
        }
      });

      return productData.data.listProductCards.items[0]?.stripe_price_id;
    } catch (error) {
      console.error("Error fetching price ID:", error);
      return null;
    }
  };

  const handleBulkSubscription = async () => {
    try {
      if (selectedItems.length === 0) {
        dispatch(showToast({ message: "No items selected", type: "error" }));
        return;
      }
      const currentDate = new Date(); // Get current date and time


      // Convert bulkScheduleDate to a JavaScript Date object (ignoring time)
      const bulkDate = bulkScheduleDate.toDate(); // Converts Day.js object to Date object

      // Compare the dates, ignoring time (using toDateString())
      if (bulkDate.toDateString() === currentDate.toDateString()) {
        dispatch(showToast({
          message: "The schedule date must be a future date. Please select a valid date.",
          type: "error"
        }));
        return;
      }
      setLoader(true);

      for (const product of selectedItems) {
        try {
          // Case 1: Existing Stripe subscription or schedule
          if (product.subscription_id && product.subscription_by === "stripe") {
            const isSchedule = product.subscription_id.startsWith('sub_sched_');

            if (isSchedule) {
              // Handle existing schedule
              try {
                // Get the existing schedule details
                const scheduleResponse = await stripeApi.get(
                  `/subscription_schedules/${product.subscription_id}`
                );

                // Update the existing schedule
                await updateExistingSchedule(product, scheduleResponse.data);
              } catch (error) {
                // If schedule not found or other error, create new schedule
                await createNewScheduledSubscription(product);
              }
            } else {
              // Handle regular subscription
              // Check subscription status from Stripe
              const subscriptionCheck = await stripeApi.get(
                `/subscriptions/${product.subscription_id}`
              );

              // Get all subscription schedules
              const schedules = await stripeApi.get('/subscription_schedules', {
                limit: 100,
                status: 'not_started'  // Only get schedules that haven't started yet
              });

              // Find if there's a schedule for this subscription
              const existingSchedule = schedules.data.data.find(
                schedule => schedule.phases.some(phase =>
                  phase.items.some(item =>
                    item.price === subscriptionCheck.data.items.data[0].price.id
                  )
                )
              );

              if (existingSchedule) {
                // Subscription already has a future schedule - update it
                await updateExistingSchedule(product, existingSchedule);
              } else {
                // No future schedule exists - handle based on subscription status
                if (subscriptionCheck.data.status === 'active' ||
                  subscriptionCheck.data.status === 'trialing') {
                  await handleActiveSubscription(product);
                } else {
                  await createNewScheduledSubscription(product);
                }
              }
            }
          }
          // Case 2: No existing Stripe subscription
          else {
            let response = await createNewScheduledSubscription(product);
          }

        } catch (error) {
          console.error(`Error processing product ${product.name}:`, error);
          dispatch(showToast({
            message: `Failed to process ${product.name}. ${error.message}`,
            type: "error",
          }));
        }
      }

      // Reset state and show success message
      setSelectedItems([]);
      setBulkScheduleDate(null);
      setBulkSubscriptionModal(false);
      await fetchSubscriptions();
      dispatch(showToast({
        message: "Plans scheduled successfully",
        type: "success"
      }));

    } catch (error) {
      console.error("Bulk subscription error:", error);
      dispatch(showToast({ message: error.message, type: "error" }));
    } finally {
      setLoader(false);
    }
  };

  // Update the updateExistingSchedule function to handle both types
  const updateExistingSchedule = async (product, existingSchedule) => {
    try {
      // Check if schedule is still valid
      if (existingSchedule.status === 'canceled' ||
        existingSchedule.status === 'completed') {
        return await createNewScheduledSubscription(product);
      }

      // Update the existing schedule
      const updatedSchedule = await stripeApi.post(
        `/subscription_schedules/${existingSchedule.id}`,
        {
          phases: [{
            start_date: Math.floor(new Date(bulkScheduleDate).getTime() / 1000),
            items: [
              {
                price: existingSchedule.phases[0].items[0].price,
                quantity: product.quantity || existingSchedule.phases[0].items[0].quantity || 1
              }
            ],
            collection_method: 'charge_automatically',
            proration_behavior: 'none'
          }]
        }
      );

      if (updatedSchedule.error) {
        throw new Error(`Failed to update subscription schedule: ${updatedSchedule.error.message}`);
      }

      // Update database record
      await updateDatabaseRecord(product.id, {
        status: true,
        subscription_on_hold: false,
        created_at: bulkScheduleDate.format("YYYY-MM-DD"),
        subscription_id: updatedSchedule?.data?.id,
        is_schedule: true,

      });

      return updatedSchedule.data;

    } catch (error) {
      console.error("Error updating existing schedule:", error);
      // If we can't update the existing schedule, create a new one
      if (error.response?.status === 404) {
        return await createNewScheduledSubscription(product);
      }
      throw error;
    }
  };

  const handleActiveSubscription = async (product) => {
    try {
      // Get the existing subscription details
      const existingSubscription = await stripeApi.get(
        `/subscriptions/${product.subscription_id}`
      );

      // Cancel the existing subscription at period end
      await stripeApi.post(
        `/subscriptions/${product.subscription_id}`,
        { cancel_at_period_end: true }
      );

      // Create a new subscription schedule
      const scheduleResponse = await stripeApi.post('/subscription_schedules', {
        customer: existingSubscription.data.customer,
        start_date: Math.floor(new Date(bulkScheduleDate).getTime() / 1000),
        phases: [{
          items: [
            {
              price: existingSubscription.data.items.data[0].price.id,
              quantity: product.quantity || existingSubscription.data.items.data[0].quantity || 1
            }
          ],
          collection_method: 'charge_automatically',
          proration_behavior: 'none'
        }]
      });

      if (scheduleResponse.error) {
        throw new Error(`Failed to schedule subscription: ${scheduleResponse.error.message}`);
      }

      // Update database record
      await updateDatabaseRecord(product.id, {
        subscription_id: scheduleResponse?.data?.id,
        status: true,
        subscription_on_hold: false,
        created_at: bulkScheduleDate.format("YYYY-MM-DD"),
        is_schedule: true
        // current_period_end: existingSubscription.data.current_period_end
      });

      return scheduleResponse.data;
    } catch (error) {
      console.error("Error handling active subscription:", error);
      throw error;
    }
  };

  // Create new scheduled subscription
  const createNewScheduledSubscription = async (product) => {
    try {
      // Get or create Stripe product and price
      const stripeProduct = await getOrCreateStripeProduct(product);
      const stripePrice = await getOrCreateStripePrice(product, stripeProduct);
      console.log("The product details is::", product)
      console.log("The customer details is::", subscriberData?.[0])
      // Create a subscription schedule with correct parameter structure
      const scheduleData = {
        customer: subscriberData?.[0]?.stripe_customer_id,
        start_date: Math.floor(new Date(bulkScheduleDate).getTime() / 1000),
        end_behavior: 'release',
        phases: [
          {
            items: [  // Changed from 'plans' to 'items'
              {
                price: stripePrice.id,
                quantity: product.quantity || 1
              }
            ],
            collection_method: 'charge_automatically',
            proration_behavior: 'none'
          }
        ]
      };

      const schedule = await stripeApi.post('/subscription_schedules', scheduleData);
      console.log("The schedule iss::", schedule?.data)
      if (schedule.error) {
        throw new Error(`Failed to create subscription schedule: ${schedule.error.message}`);
      }
      if (product?.customAddition == true) {

        const variable = {
          id: product?.id,
          product_id: product?.id,
          status: true,
          created_at: bulkScheduleDate.format("YYYY-MM-DD"),
          workspace_id: selectedWorkspaceId,
          subscription_id: schedule?.data?.id,
          product_image: product?.image,
          product_name: product?.name,
          product_period: product.product_period,
          product_plan: "plan",
          product_quantity: 1,
          product_price: product.product_price,
          invoice_number: "",
          subscription_by: "stripe",

          is_schedule: true
        };

        const createSubsCard = await client.graphql({
          query: createSubscriptions,
          variables: { input: variable },
        });
        return
      }
      // Update database record
      await updateDatabaseRecord(product.id, {
        subscription_id: schedule.data.id,
        status: true,
        subscription_on_hold: false,
        created_at: bulkScheduleDate.format("YYYY-MM-DD"),
        // schedule_id: schedule.data.id,
        subscription_by: "stripe",
        is_schedule: true

      });

      return schedule.data;
    } catch (error) {
      console.error("Error creating new scheduled subscription:", error);
      throw error;
    }
  };
  const cancelSubscriptionSchedule = async (scheduleId) => {
    try {
      console.log("the cancle id is::", scheduleId)
      console.log("the cancle id is::", scheduleId?.subscription_id)
      // Replace this with your configured Axios instance for Stripe API


      // Sending a DELETE request to cancel the subscription schedule
      const response = await axios.post(
        `https://api.stripe.com/v1/subscription_schedules/${scheduleId?.subscription_id}/cancel`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`, // Replace with your secret Stripe key
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      console.log('Subscription cancelled:', response.data);
      if (response?.data) {
        const variables = {
          input: {
            id: scheduleId?.id,
            status: false,
            is_schedule: false
          },
        };

        await client.graphql({
          query: updateSubscriptions,
          variables: variables,
        });

        await fetchSubscriptions();
      }
      // return response.data;
    } catch (error) {
      console.error('Error cancelling subscription schedule:', error.response?.data || error.message);
      throw error;
    }
  };
  const handleEditSubscription = (subscription) => {
    // Logic to edit subscription details
    // This could involve opening a modal with the current subscription details
    // and allowing the user to make changes
  };

  const BulkSubscriptionModal = () => {
    // Using existing states: productData and plansById
    const [selectedVariation, setSelectedVariation] = useState(null);

    // Add this console log to debug


    // Function to get variations for selected product
    const getVariationsForProduct = useCallback((productId) => {
      if (!plansById || !productId) return [];
      return plansById.filter(plan => plan.product_id === productId);
    }, [plansById]);

    // Function to add product to plan
    const handleAddToPlan = () => {
      if (!selectedProduct || !selectedVariation) {
        dispatch(showToast({
          message: "Please select both product and variation",
          type: "error"
        }));
        return;
      }

      // Add to selected items
      const currentDate = new Date().toISOString();
      const newItem = {
        id: uuid(),
        created_at: currentDate,
        current_period_end: null,
        current_period_start: null,
        end_date: null,
        image: selectedProduct.image || "", // Assuming product has image field
        last_order_date: null,
        name: selectedProduct.name,
        next_payment_date: null,
        product_id: selectedProduct?.id,
        product_name: selectedProduct.name,
        product_period: selectedVariation.period || "month",
        product_price: parseFloat(selectedVariation.price) || 0,
        status: true,
        subscription_by: null,
        subscription_id: "",
        subscription_on_hold: null,
        trial_end: null,
        updatedAt: currentDate,
        customAddition: true
      };


      setSelectedItems(prev => [...prev, newItem]);

      // Reset selections
      setSelectedProduct(null);
      setSelectedVariation(null);
    };

    return (
      <Dialog
        open={bulkSubscriptionModal}
        onClose={() => setBulkSubscriptionModal(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Schedule Plan</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Add Products to Plan
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
              <Autocomplete
                value={selectedProduct}
                onChange={(event, newValue) => {
                  setSelectedProduct(newValue);
                  setSelectedVariation(null);
                }}
                options={productData || []}
                getOptionLabel={(option) => option.name || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Product"
                    error={!selectedProduct && selectedVariation}
                    helperText={!selectedProduct && selectedVariation ? "Please select a product first" : ""}
                  />
                )}
                loading={loader}
                loadingText="Loading products..."
              />
              <Autocomplete
                value={selectedVariation}
                onChange={(event, newValue) => {
                  setSelectedVariation(newValue);
                }}
                options={selectedProduct ? getVariationsForProduct(selectedProduct.id) : []}
                getOptionLabel={(option) => {
                  return `${option.name || ''} - $${option.price || 0}${option.period ? ` / ${option.period}` : ''}`;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Variation"
                  />
                )}
                disabled={!selectedProduct}
                loading={loading}
                loadingText="Loading variations..."
                noOptionsText={selectedProduct ? "No variations found" : "Select a product first"}
              />
            </Box>
            <Button
              variant="outlined"
              onClick={handleAddToPlan}
              fullWidth
              disabled={!selectedProduct || !selectedVariation}
              startIcon={<AddCircleOutlinedIcon />}
            >
              Add to Plan
            </Button>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Date picker section */}
          <DialogContentText>
            Select the date when these subscriptions should start:
          </DialogContentText>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={bulkScheduleDate}
              onChange={(newValue) => setBulkScheduleDate(newValue)}
              minDate={dayjs()}
              sx={{ width: '100%', mt: 2 }}
            />
          </LocalizationProvider>

          {/* Selected Items List */}
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Selected Items:</Typography>
            {selectedItems.map((item) => (
              <Box
                key={item.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 1,
                  my: 1,
                  backgroundColor: '#f8f9fa',
                  padding: '8px 12px',
                  borderRadius: '4px'
                }}
              >
                <Box>
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    {item.name}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    ${(item.product_price)?.toLocaleString("en-US")} / {item.product_period}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {item.subscription_id ? (
                    <Chip
                      label="Existing Plan"
                      size="small"
                      color="warning"
                      sx={{ height: '24px' }}
                    />
                  ) : (
                    <Chip
                      label="New Plan"
                      size="small"
                      color="success"
                      sx={{ height: '24px' }}
                    />
                  )}
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSelectedItems(prev =>
                        prev.filter(i => i.id !== item.id)
                      );
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>

          {/* Summary section */}
          <Box sx={{ mt: 2, bgcolor: '#f5f5f5', p: 2, borderRadius: 1 }}>
            <Typography variant="subtitle2" color="text.secondary">
              Summary:
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
              <Chip
                label={`New: ${selectedItems.filter(item => !item.subscription_id).length}`}
                size="small"
                color="success"
              />
              <Chip
                label={`To Update: ${selectedItems.filter(item => item.subscription_id).length}`}
                size="small"
                color="warning"
              />
              <Chip
                label={`Total: ${selectedItems.length}`}
                size="small"
                color="primary"
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBulkSubscriptionModal(false)}>Cancel</Button>
          <LoadingButton
            onClick={handleBulkSubscription}
            variant="contained"
            loading={loader}
            disabled={selectedItems.length === 0 || !bulkScheduleDate}
          >
            Schedule Plans
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  };
  const handleCreateBulkSubscriptionClick = () => {
    if (subscriberData?.[0]?.stripe_payment_profile_id == null) {
      dispatch(showToast({
        message: "Stripe payment information is necessary to enable this feature.",
        type: "info"
      }));
      return

    }
    // First, filter the non-Stripe rows (rows that are not Stripe or have invalid subscription_id or is_schedule)
    const nonStripeRows = rows.filter(row =>
      row.subscription_by !== "stripe" || row.subscription_id == ""
    );

    console.log("the non-stripe rows:", nonStripeRows);

    // Filter out non-Stripe rows from the rows, leaving only Stripe rows
    // const stripeRows = rows.filter(row =>
    //   (row.subscription_by === "stripe" && row.is_schedule === true && row?.status == true) || row.subscription_id === ""
    // );

    const stripeRows = rows.filter(row => {
      // Case 1: Stripe subscription with status true
      const isStripeSubscription = row?.subscription_by === 'stripe' && row?.status === true;

      // Case 2: Empty subscription_id
      const hasEmptySubscriptionId = row?.subscription_id === "";

      // Case 3: Scheduled with status true
      const isScheduledWithStatus = row?.is_schedule === true && row?.status === false;

      // Case 4: Not scheduled with status true
      const isNotScheduledWithStatus = row?.is_schedule === false && row?.status === true;

      return (
        isStripeSubscription ||
        hasEmptySubscriptionId ||
        isScheduledWithStatus ||
        isNotScheduledWithStatus
      );
    });
    console.log("the stripe rows:", stripeRows);

    // If no Stripe rows are left after removing non-Stripe rows
    if (stripeRows.length === 0) {
      dispatch(showToast({
        message: "No Stripe subscriptions found",
        type: "warning"
      }));
      return;
    }

    // If there are any non-Stripe rows, show a warning with their count or product names
    if (nonStripeRows.length > 0) {
      let message;
      if (nonStripeRows.length <= 3) {
        // Show specific product names if 3 or fewer
        const productNames = nonStripeRows.map(row => row.name).join(', ');
        message = `The following products are not linked with Stripe: ${productNames}`;
      } else {
        // Show count if more than 3
        message = `${nonStripeRows.length} products are not linked with Stripe and will be skipped`;
      }

      dispatch(showToast({
        message: message,
        type: "warning"
      }));
    }

    // Set the remaining Stripe rows as the selected items
    setSelectedItems(stripeRows);

    // Set the bulk schedule date and open the modal
    setBulkScheduleDate(dayjs());
    setBulkSubscriptionModal(true);
  };


  const getPaymentMethodDisplay = (method) => {
    switch (method) {
      case 'stripe':
        return 'Credit Card';
      default:
        return 'Manual Renewal';
    }
  };

  const filterSubscriptions = (subscriptions) => {
    if (!subscriptions) return [];

    return subscriptions.filter(item => {
      // Existing filters
      if (statusFilter === "active" && (!item.status || item.subscription_on_hold)) return false;
      if (statusFilter === "scheduled" && item.is_schedule !== true) return false;
      if (statusFilter === "on-hold" && !item.subscription_on_hold) return false;
      if (statusFilter === "cancelled" && (item.status == true || item.is_schedule == true)) return false;

      if (paymentStatus) {
        if (paymentStatus === "scheduled" && item.is_schedule !== true) return false;
        if (paymentStatus === "on-hold" && !item.subscription_on_hold) return false;
        if (paymentStatus === "cancelled" && (item.status == true || item.is_schedule == true)) return false;
        if (paymentStatus === "pending" && item.subscription_id !== false) return false;
      }

      // Date filter
      if (dateFilter) {
        const itemDate = new Date(item.created_at);
        const filterDate = new Date(dateFilter);

        if (itemDate.getMonth() !== filterDate.getMonth() ||
          itemDate.getFullYear() !== filterDate.getFullYear()) {
          return false;
        }
      }

      // Product search filter
      if (searchProduct) {
        const searchTerm = searchProduct.toLowerCase();
        return (
          item.product_name?.toLowerCase().includes(searchTerm) ||
          item.subscription_id?.toLowerCase().includes(searchTerm)
        );
      }

      return true;
    });
  };

  const getLastMonths = () => {
    const months = [];
    const currentDate = new Date();

    for (let i = 0; i < 12; i++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
      months.push({
        value: date.toISOString(),
        label: date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })
      });
    }

    return months;
  };
  const getStatusCounts = () => {
    if (!subscriptionsList) {
      return {
        all: 0,
        active: 0,
        onHold: 0,
        cancelled: 0,
        scheduled: 0 // Add scheduled count
      };
    }

    return {
      all: subscriptionsList?.length || 0,
      active: subscriptionsList?.filter(item =>
        item.status === true && item.subscription_on_hold !== true
      ).length || 0,
      onHold: subscriptionsList?.filter(item =>
        item.subscription_on_hold === true
      ).length || 0,
      cancelled: subscriptionsList?.filter(item =>
        item.status === false && item.is_schedule !== true
      ).length || 0,
      scheduled: subscriptionsList?.filter(item =>
        item.is_schedule === true // Assuming you use this field for scheduled status
      ).length || 0
    };
  };


  const handleBulkAction = async () => {
    if (!bulkAction || selectedItems.length === 0) {
      dispatch(showToast({
        message: "Please select items and an action to perform",
        type: "error"
      }));
      return;
    }

    try {
      setLoader(true);

      for (const item of selectedItems) {
        let updateInput = {
          id: item.id
        };

        switch (bulkAction) {
          case "activate":
            updateInput.status = true;
            updateInput.subscription_on_hold = false;
            break;
          case "deactivate":
            updateInput.subscription_on_hold = true;
            break;
          case "delete":
            // Handle deletion separately
            await client.graphql({
              query: deleteSubscriptions,
              variables: { input: { id: item.id } }
            });
            continue;
          default:
            continue;
        }

        if (bulkAction !== "delete") {
          await client.graphql({
            query: updateSubscriptions,
            variables: { input: updateInput }
          });
        }
      }

      // Refresh the subscriptions list
      await fetchSubscriptions();

      // Clear selections
      setSelectedItems([]);
      setBulkAction("");

      dispatch(showToast({
        message: "Bulk action completed successfully",
        type: "success"
      }));
    } catch (error) {
      console.error("Bulk action error:", error);
      dispatch(showToast({
        message: error.message || "Failed to perform bulk action",
        type: "error"
      }));
    } finally {
      setLoader(false);
    }
  };

  // Add this custom No Rows Overlay component
  const CustomNoRowsOverlay = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          minHeight: '400px', // Set a minimum height
          '& .ant-empty-img-1': {
            fill: '#aeb8c2',
          },
        }}
      >
        <Typography variant="body1" color="text.secondary">
          No Records Found
        </Typography>
      </Box>
    );
  };

  // Helper function to update database record
  const updateDatabaseRecord = async (id, data) => {
    try {
      await client.graphql({
        query: updateSubscriptions,
        variables: {
          input: {
            id,
            ...data
          }
        }
      });
    } catch (error) {
      console.error("Error updating database record:", error);
      throw error;
    }
  };

  // Helper function to get or create Stripe product
  const getOrCreateStripeProduct = async (product) => {
    try {
      const existingProducts = await stripeApi.get("/products", {
        params: { limit: 100 },
      });

      let stripeProduct = existingProducts.data.data.find(
        (p) => p.name === product.name
      );

      if (!stripeProduct) {
        const newProduct = await stripeApi.post("/products", {
          name: product.name,
          description: `${product.name} - standard plan`,
        });
        stripeProduct = newProduct.data;
      }

      return stripeProduct;
    } catch (error) {
      console.error("Error in getOrCreateStripeProduct:", error);
      throw error;
    }
  };

  // Helper function to get or create Stripe price
  const getOrCreateStripePrice = async (product, stripeProduct) => {
    try {
      const existingPrices = await stripeApi.get("/prices", {
        params: { product: stripeProduct.id, active: true },
      });

      let stripePrice = existingPrices.data.data.find(
        (p) =>
          p.recurring &&
          p.recurring.interval === 'month' &&
          p.recurring.interval_count === 1 &&
          p.unit_amount === Math.round(parseFloat(product.product_price) * 100)
      );

      if (!stripePrice) {
        const newPrice = await stripeApi.post("/prices", {
          product: stripeProduct.id,
          unit_amount: Math.round(parseFloat(product.product_price) * 100),
          currency: 'usd',
          recurring: {
            interval: 'month',
            interval_count: 1,
          },
        });
        stripePrice = newPrice.data;
      }

      return stripePrice;
    } catch (error) {
      console.error("Error in getOrCreateStripePrice:", error);
      throw error;
    }
  };

  return (
    <>
      <div style={container}>
        {isMobile && (
          <div>
            <div>
              <p style={title}>My Plan</p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Link onClick={() => navigate("/orders")}>Orders</Link>
              {userData?.role !== "workspace_member" && (
                <>
                  <Link onClick={() => navigate("/payment_address_settings")}>
                    Payment Method
                  </Link>
                  <Link onClick={handleOpenItemModal}>Add Item</Link>
                  {/* {
                    userData?.role !== "super_admin" && (
                      <Button
                        variant="outlined"
                        onClick={handleCreateBulkSubscriptionClick}
                        style={{ textTransform: "inherit" }}
                      >
                        Create Plan
                      </Button>
                    )
                  } */}
                  {
                    userData?.role !== "super_admin" && (
                      <Button
                        variant="outlined"
                        onClick={handleCreateBulkSubscriptionClick}
                        sx={{
                          textTransform: "inherit",
                          display: "flex",
                          gap: "6px",
                          alignItems: "center",
                          '& .MuiSvgIcon-root': {
                            fontSize: '1.1rem' // Adjust icon size if needed
                          }
                        }}
                      >
                        <CalendarTodayIcon />
                        Schedule
                      </Button>
                    )
                  }

                </>
              )}
            </div>
          </div>
        )}
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                marginBottom: isMobile && 10,
              }}
            >
              {!isMobile && (
                <div>
                  <p style={title}>My Plan</p>
                </div>
              )}
              {isMobile ? (
                <></>
              ) : (
                <div
                  style={{
                    display: "flex",
                    gap: "0.5rem",
                    marginTop: "0.45rem",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => navigate("/orders")}
                    style={{ textTransform: "inherit" }}
                  >
                    Orders
                  </Button>
                  {userData?.role !== "workspace_member" && (
                    <>
                      <Button
                        variant="outlined"
                        onClick={() => navigate("/payment_address_settings")}
                        style={{ textTransform: "inherit" }}
                      >
                        Payment Method
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={handleOpenItemModal}
                        style={{ textTransform: "inherit" }}
                      >
                        Add Item
                      </Button>
                      {/* {
                        userData?.role == "super_admin" && (
                          <Button
                            variant="outlined"
                            onClick={handleCreateBulkSubscriptionClick}
                            style={{ textTransform: "inherit" }}
                          >
                            Create Plan
                          </Button>
                        )
                      } */}
                      {
                        userData?.role == "super_admin" && (
                          <Button
                            variant="outlined"
                            onClick={handleCreateBulkSubscriptionClick}
                            sx={{
                              textTransform: "inherit",
                              display: "flex",
                              gap: "6px",
                              alignItems: "center",
                              '& .MuiSvgIcon-root': {
                                fontSize: '1.1rem' // Adjust icon size if needed
                              }
                            }}
                          >
                            <CalendarTodayIcon />
                            Schedule
                          </Button>
                        )
                      }
                    </>
                  )}
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        {isMobile ? (
          <div className="tableInnerWrapper" style={{ background: "white" }}>
            <div className="table-info">
              <table className="d-table">
                <thead>
                  <tr>
                    <th style={{ width: "170px" }}>Title</th>
                    <th>Name</th>
                    <th>Created At</th>
                    <th>Status</th>
                    {userData?.role !== "workspace_member" && <th>Action</th>}
                  </tr>
                </thead>
                {subscriptionsList?.length === 0 ? (
                  <>
                    {" "}
                    <tbody>
                      <tr className="norecord">
                        <td colSpan={7} style={{ textAlign: "center" }}>
                          No Record Found
                        </td>
                      </tr>
                    </tbody>
                  </>
                ) : (
                  <>
                    <tbody>
                      {subscriptionsList?.length > 0 &&
                        subscriptionsList?.map((item, index, array) => (
                          <>
                            <div
                              style={{
                                display: "flex",
                                marginBottom: 8,
                                marginTop: 8,
                              }}
                            >
                              <div>
                                <img
                                  src={item?.product_image}
                                  style={{
                                    height: "150px",
                                    width: "150px",
                                    borderRadius: "8%",
                                    backgroundColor: "transparent",
                                  }}
                                />
                              </div>
                              <div style={{ marginLeft: 10 }}>
                                <div className="td-name">
                                  <div className="itemtitle">
                                    <div className="td-name">Name:</div>
                                    <span style={nameContainerStyle}>
                                      {" "}
                                      {`${item?.product_name}`}
                                    </span>
                                  </div>
                                  <div className="itemtitle">
                                    <div className="td-name">Status:</div>
                                    <span
                                      style={
                                        item.status === true
                                          ? statusStyleActive
                                          : statusStyleInActive
                                      }
                                    >
                                      {item.product_type ===
                                        "variable subscription" &&
                                        item.status === true
                                        ? "Active"
                                        : item.product_type ===
                                          "variable subscription" &&
                                          item.status === false
                                          ? "InActive"
                                          : "Purchased"}
                                    </span>
                                  </div>
                                  <div className="itemtitle">
                                    <div className="td-name">Role:</div>
                                    <span style={{ padding: 0 }}>
                                      {item?.role === "workspace_admin"
                                        ? "Workspace Admin"
                                        : item?.role === "workspace_member"
                                          ? "Workspace Member"
                                          : item?.role === "super_admin"
                                            ? "Super Admin"
                                            : "Advisor"}
                                    </span>
                                  </div>

                                  <div
                                    className="itemtitle"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>
                                      <div className="td-name">Created At:</div>
                                      <span>{formatDate(item?.createdAt)}</span>
                                    </div>
                                    <div style={{ marginLeft: 30 }}>
                                      {userData?.role !==
                                        "workspace_member" && (
                                          <>
                                            <div className="td-name">Action:</div>
                                            {item?.status ? (
                                              <span
                                                style={{
                                                  ...statusStyleInActive,
                                                  backgroundColor: "#F0F0F0",
                                                  color: "red",
                                                  padding: "0rem 0rem",
                                                }}
                                                onClick={() =>
                                                  handleCancelSubscription(
                                                    item?.row
                                                  )
                                                }
                                              >
                                                Cancel
                                              </span>
                                            ) : !item?.status ? (
                                              <span
                                                style={{
                                                  ...statusStyleInActive,
                                                  backgroundColor: "#F0F0F0",
                                                  color: "black",
                                                }}
                                              >
                                                Cancelled
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {index < subscriptionsList.length - 1 && (
                              <Divider sx={{ width: "100%" }} />
                            )}
                          </>
                        ))}
                    </tbody>
                  </>
                )}
              </table>
            </div>
          </div>
        ) : (
          <Grid container spacing={5}>

            <Grid item xs={12}>
              <Box sx={{ mb: 2, borderBottom: '1px solid #e0e0e0' }}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <Button
                    onClick={() => setStatusFilter("all")}
                    sx={{
                      textTransform: 'none',
                      color: statusFilter === "all" ? 'primary.main' : 'text.secondary',
                      borderBottom: statusFilter === "all" ? 2 : 0,
                      borderRadius: 0,
                      '&:hover': { backgroundColor: 'transparent' }
                    }}
                  >
                    All ({getStatusCounts().all})
                  </Button>
                  <Button
                    onClick={() => setStatusFilter("active")}
                    sx={{
                      textTransform: 'none',
                      color: statusFilter === "active" ? 'primary.main' : 'text.secondary',
                      borderBottom: statusFilter === "active" ? 2 : 0,
                      borderRadius: 0,
                      '&:hover': { backgroundColor: 'transparent' }
                    }}
                  >
                    Active ({getStatusCounts().active})
                  </Button>
                  {/* Schedule Tab */}
                  <Button
                    onClick={() => setStatusFilter("scheduled")}
                    sx={{
                      textTransform: 'none',
                      color: statusFilter === "scheduled" ? 'primary.main' : 'text.secondary',
                      borderBottom: statusFilter === "scheduled" ? 2 : 0,
                      borderRadius: 0,
                      '&:hover': { backgroundColor: 'transparent' }
                    }}
                  >
                    Scheduled ({getStatusCounts().scheduled})
                  </Button>
                  <Button
                    onClick={() => setStatusFilter("on-hold")}
                    sx={{
                      textTransform: 'none',
                      color: statusFilter === "on-hold" ? 'primary.main' : 'text.secondary',
                      borderBottom: statusFilter === "on-hold" ? 2 : 0,
                      borderRadius: 0,
                      '&:hover': { backgroundColor: 'transparent' }
                    }}
                  >
                    On hold ({getStatusCounts().onHold})
                  </Button>
                  <Button
                    onClick={() => setStatusFilter("cancelled")}
                    sx={{
                      textTransform: 'none',
                      color: statusFilter === "cancelled" ? 'primary.main' : 'text.secondary',
                      borderBottom: statusFilter === "cancelled" ? 2 : 0,
                      borderRadius: 0,
                      '&:hover': { backgroundColor: 'transparent' }
                    }}
                  >
                    Cancelled ({getStatusCounts().cancelled})
                  </Button>
                </Box>
              </Box>
              <Box sx={{
                mb: 2,
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                p: 1,
                borderBottom: '1px solid #e0e0e0'
              }}>
                {/* <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <Select
                    size="small"
                    value={bulkAction}
                    onChange={(e) => setBulkAction(e.target.value)}
                    displayEmpty
                    sx={{ minWidth: 120 }}
                  >
                    <MenuItem value="">Bulk actions</MenuItem>
                    <MenuItem value="activate">Activate</MenuItem>
                    <MenuItem value="deactivate">Deactivate</MenuItem>
                    <MenuItem value="delete">Delete</MenuItem>
                  </Select>

                  <Button
                    size="small"
                    variant="contained"
                    sx={{ textTransform: 'none' }}
                    onClick={handleBulkAction}
                    disabled={!bulkAction || selectedItems.length === 0}
                  >
                    Apply
                  </Button>
                </Box> */}
                <Select
                  size="small"
                  value={dateFilter}
                  onChange={(e) => setDateFilter(e.target.value)}
                  displayEmpty
                  sx={{ minWidth: 150 }}
                >
                  <MenuItem value="">All Dates</MenuItem>
                  {getLastMonths().map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  size="small"
                  value={paymentStatus}
                  displayEmpty
                  sx={{ minWidth: 120 }}
                  onChange={(e) => setPaymentStatus(e.target.value)}
                >
                  <MenuItem value="">All status</MenuItem>
                  <MenuItem value="scheduled">Scheduled</MenuItem>
                  <MenuItem value="on-hold">On hold</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </Select>

                <TextField
                  size="small"
                  placeholder="Search for a product..."
                  value={searchProduct}
                  onChange={(e) => setSearchProduct(e.target.value)}
                  sx={{ minWidth: 200 }}
                />

                <Button
                  size="small"
                  variant="outlined"
                  sx={{ textTransform: 'none' }}
                  onClick={() => {
                    // Reset filters
                    setPaymentStatus("");
                    setSearchProduct("");
                    setBulkAction("");
                    setDateFilter("")
                  }}
                >
                  Reset Filters
                </Button>
              </Box>

              <Card style={organizationCard}>
                {loader ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "50vh",
                    }}
                  >
                    <PulseLoader color="#ACD6EB" />
                  </div>
                ) : (
                  <>

                    {subscriptionsList && subscriptionsList?.length > 0 ? (
                      <DataGrid
                        rows={filterSubscriptions(rows) || []}
                        columns={columns}
                        pageSize={7}
                        rowsPerPageOptions={[7]}
                        disableSelectionOnClick
                        checkboxSelection={false}
                        components={{
                          NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        sx={{
                          minHeight: "500px",
                          "& .MuiDataGrid-cell": {
                            overflow: "visible !important",
                            position: "relative !important",
                            padding: "8px", // Reduced padding
                            display: "flex",
                            alignItems: "center" // Center align content vertically
                          },
                          "& .MuiDataGrid-row": {
                            minHeight: "80px !important", // Reduced minimum height
                            height: "80px !important", // Fixed height
                            "&:hover": {
                              backgroundColor: "#f5f5f5" // Optional: adds hover effect
                            }
                          },
                          "& .MuiDataGrid-cellContent": {
                            height: "auto", // Let content determine height
                            width: "100%",
                            // display: "flex",
                            // alignItems: "center"
                          },

                        }}
                        autoHeight // Add this to make grid adjust to content
                        getRowHeight={() => 'auto'} // Makes rows adjust to content height
                        disableExtendRowFullWidth={false} // Ensures full width rows
                      />

                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                          minHeight: "50vh",
                        }}
                        className="sub-btn-container"
                      >
                        <span className="shop-text">
                          You haven't started your plan.
                        </span>
                        <Button
                          variant="contained"
                          style={{
                            marginTop: "0.5rem",
                            textTransform: "inherit",
                          }}
                          onClick={() => navigate("/shop")}
                          className="sub-button"
                        >
                          Build Your Plan
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </Card>
            </Grid>
          </Grid>
        )}
        <Dialog open={openDeleteModal} disableBackdropClick>
          <DialogTitle>Delete Plan</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this plan? This plan will be
              removed permanantly.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ margin: "0.5rem 0.5rem" }}>
            <Button
              onClick={handleCloseDeleteModal}
              style={{ textTransform: "inherit" }}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleConfirmDelete}
              style={{ textTransform: "inherit" }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={open} disableBackdropClick>
          <DialogTitle>Cancel Subscription</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to cancel this subscription? This
              subscription will be cancelled permanantly.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ margin: "0.5rem 0.5rem" }}>
            <Button
              onClick={handleClose}
              style={{ textTransform: "inherit" }}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleConfrimCancel}
              style={{ textTransform: "inherit" }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openItemModal}
          disableBackdropClick
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>Add Product</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                <Grid item xs={9}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={productData?.map((product) => product.name)}
                    onChange={handleProductChange}
                    renderInput={(params) => (
                      <TextField {...params} label="Search for a product..." />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div style={counterContainer}>
                    <IconButton
                      aria-label="delete"
                      size={isMobile ? "small" : "large"}
                      onClick={handleDecrement}
                    >
                      <RemoveCircleOutlinedIcon
                        fontSize={isMobile ? "small" : "large"}
                        style={counterButton}
                      />
                    </IconButton>
                    <p>
                      <b>{count}</b>
                    </p>
                    <IconButton
                      aria-label="delete"
                      size={isMobile ? "small" : "large"}
                      onClick={handleIncrement}
                    >
                      <AddCircleOutlinedIcon
                        fontSize={isMobile ? "small" : "large"}
                        style={counterButton}
                      />
                    </IconButton>
                  </div>
                </Grid>
                {selectedProduct !== null &&
                  itemSelect?.[0]?.type === "variable subscription" && (
                    <Grid item xs={9} style={{ marginTop: "1rem" }}>
                      <TextField
                        select
                        fullWidth
                        name="plan"
                        label="Choose plan"
                        value={plan}
                        onChange={handlePlanChange}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                maxHeight: 300,
                              },
                            },
                          },
                        }}
                      >
                        {loading ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <CircularProgress size={20} />
                          </Box>
                        ) : (
                          plansById?.map((option) => (
                            <MenuItem key={option.name} value={option.name}>
                              {option.name}
                            </MenuItem>
                          ))
                        )}
                      </TextField>
                    </Grid>
                  )}
                {itemSelect?.[0]?.type === "variable subscription" && (
                  <Grid item xs={9} style={{ marginTop: "1rem" }}>
                    <TextField
                      // error={amountTypeError}
                      select
                      fullWidth
                      label="Purchase Option"
                      value={purchaseOption}
                      onChange={(e) => {
                        setPurchaseOption(e.target.value);
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: "300px",
                            },
                          },
                        },
                      }}
                    >
                      {["Instant buy", "Schedule later"].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
                {purchaseOption === "Schedule later" && (
                  <Grid item xs={9} style={{ marginTop: "1rem" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Select Date"
                        fullWidth
                        value={scheduleLaterDate}
                        onChange={handleDateChange}
                        minDate={today}
                        slotProps={{
                          field: {
                            clearable: true,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                )}
                <Grid item xs={9} style={{ marginTop: "1rem" }}>
                  <TextField
                    error={promoCodeError}
                    id="outlined-basic"
                    label="Promo code"
                    variant="outlined"
                    onChange={(e) => {
                      setPromoCode(e.target.value);
                      setTotalDiscount(0);
                      setPromoCodeError(false);
                      setAppliedCoupon("");
                    }}
                    value={promoCode}
                    style={{ width: "100%", marginBottom: "1rem" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    disabled={
                      itemSelect?.[0]?.type === "variable subscription" &&
                      plan === ""
                    }
                    size="small"
                    variant="contained"
                    style={{
                      fontSize: "0.9rem",
                      textTransform: "initial",
                    }}
                    onClick={applyToken}
                  >
                    Apply Coupon
                  </Button>
                </Grid>
                <Grid
                  container
                  spacing={0}
                  style={{
                    marginTop: "2rem",
                    backgroundColor: "#f4f4f4",
                    padding: "2rem",
                    borderRadius: "1rem",
                  }}
                >
                  {appliedCoupon !== "" && (
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "start",
                        flexDirection: "column",
                      }}
                    >
                      <h4 style={{ margin: "0px" }}>Coupon</h4>
                      <Chip
                        style={{
                          borderRadius: "5px",
                          marginTop: "0.5rem",
                          backgroundColor: "#D8EBDD",
                        }}
                        label={appliedCoupon}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={appliedCoupon !== "" ? 6 : 12}
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                          gap: "2rem",
                        }}
                      >
                        <h4
                          style={{
                            margin: "0px",
                            width: "150px",
                            textAlign: "right",
                          }}
                        >
                          Item Total:
                        </h4>
                        <p
                          style={{
                            margin: "0px",
                            width: "150px",
                            textAlign: "right",
                          }}
                        >
                          {`$${Number(price ?? 0)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            .toLocaleString("en-US")}`}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                          gap: "2rem",
                          marginTop: "1rem",
                        }}
                      >
                        <h4
                          style={{
                            margin: "0px",
                            width: "150px",
                            textAlign: "right",
                          }}
                        >
                          Discount:
                        </h4>
                        <p
                          style={{
                            margin: "0px",
                            width: "150px",
                            textAlign: "right",
                          }}
                        >
                          {`$${Number(totalDiscount ?? 0)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            .toLocaleString("en-US")}`}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                          gap: "2rem",
                          marginTop: "1rem",
                        }}
                      >
                        <h4
                          style={{
                            margin: "0px",
                            width: "150px",
                            textAlign: "right",
                          }}
                        >
                          Grand Total:
                        </h4>
                        <p
                          style={{
                            margin: "0px",
                            width: "150px",
                            textAlign: "right",
                          }}
                        >
                          {`$${Number(netPrice ?? 0)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            .toLocaleString("en-US")}`}
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ margin: "0.5rem 0.5rem" }}>
            <Button
              onClick={handleCloseItemModal}
              variant="contained"
              style={{ textTransform: "inherit" }}
            >
              Close
            </Button>
            <LoadingButton
              onClick={handlePlaceOrder}
              endIcon={<ChevronRightRoundedIcon />}
              loading={orderLoading}
              loadingPosition="end"
              variant="contained"
              style={{ textTransform: "inherit" }}
            >
              Place order
            </LoadingButton>
          </DialogActions>
        </Dialog>
        {!isMobile && (
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Footer />
            </Grid>
          </Grid>
        )}
      </div>
      <Dialog open={holdModal} disableBackdropClick>
        <DialogTitle>Hold Subscription</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to pause this subscription? You can resume it later.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ margin: "0.5rem 0.5rem" }}>
          <Button
            onClick={() => {
              setHoldModal(false);
              setSubscriptionToHold(null);
            }}
            style={{ textTransform: "inherit" }}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirmHold}
            style={{ textTransform: "inherit" }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <BulkSubscriptionModal />

    </>
  );
};

export default Subscription;