import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UserProfile from "../components/user_profile";
import WorkspaceProfile from "../components/workspace_profile";
import AddressSetting from "../components/address_setting";
import StripeSettings from "../components/stripe_settings";
import { Button, useMediaQuery } from "@mui/material";
import AssignRole from "../components/assign_role";
import UserGatewayPreference from "../components/prefer_gateway";
import {
  listCartItems,
  listMyLinks,
  listSubscriberCards,
  listSubscriptions,
  listTeamInvitations,
  listUserBillings,
  listUserInfos,
  listWorkspaceTeams,
} from "../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { useLocation, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { setLoading, showToast } from "../redux/userSlice";
import {
  deleteCartItems,
  deleteMyLinks,
  deleteSubscriberCard,
  deleteSubscriptions,
  deleteTeamInvitation,
  deleteUserBilling,
  deleteUserInfo,
  deleteWorkspaceInfo,
  deleteWorkspaceTeam,
} from "../graphql/mutations";
import { useDispatch } from "react-redux";
import SEO_Auddit from "../components/SEO_Auddit";
import ManageConnections from "../components/manageDesktend";

function CustomTabPanel(props) {
  const { children, value, index, isMobile, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: isMobile ? 0 : 3,
            mt: isMobile && 2,
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const fromScreen = state?.screen;
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(fromScreen === "team" ? 3 : 0);
  const [open, setOpen] = React.useState(false);
  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const client = generateClient();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const [userBillingDetail, setUserBillingDetail] = React.useState(null);
  const [userDetail, setUserDetail] = React.useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const fetchUserData = async (selectedWorkspaceId) => {
    let nextToken = null;
    let allItems = [];

    const variables = {
      filter: {
        user_id: {
          eq: userData?.sub,
        },
        workspace_id: {
          eq: selectedWorkspaceId,
        },
      },
      limit: 1000, // You can adjust this value
    };

    do {
      const getAddressData = await client.graphql({
        query: listUserInfos,
        variables: {
          ...variables,
          nextToken: nextToken
        },
      });

      const items = getAddressData.data.listUserInfos.items;
      allItems = [...allItems, ...items];
      nextToken = getAddressData.data.listUserInfos.nextToken;
    } while (nextToken);

    return allItems;
  };
  React.useEffect(() => {
    const fetchUsers = async () => {

      const response = await fetchUserData(selectedWorkspaceId);
      setUserDetail(response);
    };
    fetchUsers();
    fetchUserBillingDetail();
  }, []);
  const fetchAllUserBillings = async (selectedWorkspaceId) => {
    let nextToken = null;
    let allItems = [];

    const variables = {
      filter: {
        workspace_id: {
          eq: selectedWorkspaceId,
        },
      },
      limit: 1000, // You can adjust this value
    };

    do {
      const getAddressData = await client.graphql({
        query: listUserBillings,
        variables: {
          ...variables,
          nextToken: nextToken
        },
      });

      const items = getAddressData.data.listUserBillings.items;
      allItems = [...allItems, ...items];
      nextToken = getAddressData.data.listUserBillings.nextToken;
    } while (nextToken);

    return allItems;
  };
  const fetchUserBillingDetail = async () => {
    try {
      const result = await fetchAllUserBillings(selectedWorkspaceId);

      setUserBillingDetail(result);

    } catch (error) {
      //   dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleConfirmDeleteWorkspace = async () => {
    dispatch(setLoading(true));
    setOpen(false);
    try {
      // delete cart items
      const getCartvariables = {
        filter: {
          workspace_id: {
            eq: selectedWorkspaceId,
          },
        },
      };

      const getCartItems = await client.graphql({
        query: listCartItems,
        variables: getCartvariables,
      });

      const res = getCartItems.data.listCartItems.items;

      if (res?.length > 0) {
        const deletePromises = res.map((item) => {
          const cartVariable = {
            input: {
              id: item.id,
            },
          };
          return client.graphql({
            query: deleteCartItems,
            variables: cartVariable,
          });
        });
        const results = await Promise.all(deletePromises);
      }
      // delete subscriber card
      const getSubscribersVariables = {
        filter: {
          workspace_id: {
            eq: selectedWorkspaceId,
          },
        },
      };

      const getSubscribers = await client.graphql({
        query: listSubscriberCards,
        variables: getSubscribersVariables,
      });

      const subRes = getSubscribers.data.listSubscriberCards.items;

      if (subRes?.length > 0) {
        const deletePromises = subRes.map((item) => {
          const subVariable = {
            input: {
              id: item.id,
            },
          };
          return client.graphql({
            query: deleteSubscriberCard,
            variables: subVariable,
          });
        });
        const results = await Promise.all(deletePromises);
      }
      // delete user billing
      const userBillingVariable = {
        filter: {
          workspace_id: {
            eq: selectedWorkspaceId,
          },
        },
      };

      const getUserBilling = await client.graphql({
        query: listUserBillings,
        variables: userBillingVariable,
      });

      const billingRes = getUserBilling.data.listUserBillings.items;

      if (billingRes?.length > 0) {
        const deletePromises = billingRes.map((item) => {
          const userVar = {
            input: {
              id: item.id,
            },
          };
          return client.graphql({
            query: deleteUserBilling,
            variables: userVar,
          });
        });
        const results = await Promise.all(deletePromises);
      }
      // delete links
      const linksVariable = {
        filter: {
          workspace_id: {
            eq: selectedWorkspaceId,
          },
        },
      };

      const getLinks = await client.graphql({
        query: listMyLinks,
        variables: linksVariable,
      });

      const linksRes = getLinks.data.listMyLinks.items;

      if (linksRes?.length > 0) {
        const deletePromises = linksRes.map((item) => {
          const linkVar = {
            input: {
              id: item.id,
            },
          };
          return client.graphql({
            query: deleteMyLinks,
            variables: linkVar,
          });
        });
        const results = await Promise.all(deletePromises);
      }
      // delete team invitations
      const invVariable = {
        filter: {
          workspace_id: {
            eq: selectedWorkspaceId,
          },
        },
      };

      const getInv = await client.graphql({
        query: listTeamInvitations,
        variables: invVariable,
      });

      const invRes = getInv.data.listTeamInvitations.items;

      if (invRes?.length > 0) {
        const deletePromises = invRes.map((item) => {
          const invVar = {
            input: {
              id: item.id,
            },
          };
          return client.graphql({
            query: deleteTeamInvitation,
            variables: invVar,
          });
        });
        const results = await Promise.all(deletePromises);
      }
      // delete subscriptions
      const subcriptionVariable = {
        filter: {
          workspace_id: {
            eq: selectedWorkspaceId,
          },
        },
      };

      const getSubs = await client.graphql({
        query: listSubscriptions,
        variables: subcriptionVariable,
      });

      const subsRes = getSubs.data.listSubscriptions.items;

      if (subsRes?.length > 0) {
        const deletePromises = subsRes.map((item) => {
          const subsVar = {
            input: {
              id: item.id,
            },
          };
          return client.graphql({
            query: deleteSubscriptions,
            variables: subsVar,
          });
        });
        const results = await Promise.all(deletePromises);
      }
      // delete team
      const teamVariable = {
        filter: {
          workspace_id: {
            contains: selectedWorkspaceId,
          },
        },
      };

      const getTeam = await client.graphql({
        query: listWorkspaceTeams,
        variables: teamVariable,
      });

      const teamRes = getTeam.data.listWorkspaceTeams.items;
      if (teamRes?.length > 0) {
        const deletePromises = teamRes.map((item) => {
          const teamVar = {
            input: {
              id: item.id,
            },
          };
          return client.graphql({
            query: deleteWorkspaceTeam,
            variables: teamVar,
          });
        });
        const results = await Promise.all(deletePromises);
      }
      // delete workspace
      const workspaceVar = {
        input: {
          id: selectedWorkspaceId,
        },
      };
      const deleteWorkspace = await client.graphql({
        query: deleteWorkspaceInfo,
        variables: workspaceVar,
      });
      // delete user info
      const userVariable = {
        filter: {
          workspace_id: {
            eq: selectedWorkspaceId,
          },
        },
      };

      const getUser = await client.graphql({
        query: listUserInfos,
        variables: userVariable,
      });

      const userRes = getUser.data.listUserInfos.items;
      if (userRes?.length > 0) {
        const deletePromises = userRes.map((item) => {
          const userVar = {
            input: {
              id: item.id,
            },
          };
          return client.graphql({
            query: deleteUserInfo,
            variables: userVar,
          });
        });
        const results = await Promise.all(deletePromises);
      }
      localStorage.removeItem("selectedWorkspaceId");
      navigate("/home");
      dispatch(
        showToast({
          message: "Workspace deleted successfully",
          type: "success",
        })
      );
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error:", error);
    }
  };

  const tabs = [];
  const tabPanels = [];

  if (
    userData?.role !== "super_admin" ||
    (userData?.role === "super_admin" && userDetail?.length > 0)
  ) {
    tabs.push(
      <Tab
        label="User Profile"
        {...a11yProps(tabs.length)}
        style={{ textTransform: "inherit" }}
      />
    );
    tabPanels.push(
      <CustomTabPanel value={value} index={tabs.length - 1} isMobile={isMobile}>
        <UserProfile />
      </CustomTabPanel>
    );
  }

  tabs.push(
    <Tab
      label="Workspace Profile"
      {...a11yProps(tabs.length)}
      style={{ textTransform: "inherit" }}
    />
  );
  tabPanels.push(
    <CustomTabPanel value={value} index={tabs.length - 1} isMobile={isMobile}>
      <WorkspaceProfile userDetail={userDetail} />
    </CustomTabPanel>
  );
  if (userBillingDetail?.[0]?.paymentMethod_Gateway !== "stripe") {
    if (userData?.role !== "workspace_member") {
      tabs.push(
        <Tab
          label="Billing Address & Payment Method"
          {...a11yProps(tabs.length)}
          style={{ textTransform: "inherit" }}
        />
      );
      tabPanels.push(
        <CustomTabPanel value={value} index={tabs.length - 1} isMobile={isMobile}>
          <AddressSetting />
        </CustomTabPanel>
      );
    }
  }

  if (userData?.role !== "workspace_member") {
    tabs.push(
      <Tab
        label={userBillingDetail?.[0]?.paymentMethod_Gateway !== "stripe" ? "Stripe Settings" : "Billing Address & Payment Method"}
        {...a11yProps(tabs.length)}
        style={{ textTransform: "inherit" }}
      />
    );
    tabPanels.push(
      <CustomTabPanel value={value} index={tabs.length - 1} isMobile={isMobile}>
        <StripeSettings />
      </CustomTabPanel>
    );
  }

  // if (userData?.role !== "workspace_member") {
  //   tabs.push(
  //     <Tab
  //       label="SEO Audit"
  //       {...a11yProps(tabs.length)}
  //       style={{ textTransform: "inherit" }}
  //     />
  //   );
  //   tabPanels.push(
  //     <CustomTabPanel value={value} index={tabs.length - 1} isMobile={isMobile}>
  //       <SEO_Auddit />
  //     </CustomTabPanel>
  //   );
  // }


  if (userData?.role === "super_admin") {
    tabs.push(
      <Tab
        label="Preferred Gateway"
        {...a11yProps(tabs.length)}
        style={{ textTransform: "inherit" }}
      />
    );
    tabPanels.push(
      <CustomTabPanel value={value} index={tabs.length - 1} isMobile={isMobile}>
        <UserGatewayPreference />
      </CustomTabPanel>
    );
  }
  if (userData?.role === "super_admin") {
    tabs.push(
      <Tab
        label="Roles"
        {...a11yProps(tabs.length)}
        style={{ textTransform: "inherit" }}
      />
    );
    tabPanels.push(
      <CustomTabPanel value={value} index={tabs.length - 1} isMobile={isMobile}>
        <AssignRole />
      </CustomTabPanel>
    );
  }

  // if (userData?.role === "super_admin") {
  //   tabs.push(
  //     <Tab
  //       label="Manage Connections"
  //       {...a11yProps(tabs.length)}
  //       style={{ textTransform: "inherit" }}
  //     />
  //   );
  //   tabPanels.push(
  //     <CustomTabPanel value={value} index={tabs.length - 1} isMobile={isMobile}>
  //       <ManageConnections />
  //     </CustomTabPanel>
  //   );
  // }


  return (
    <Box sx={{ width: "100%", marginTop: "2rem" }}>
      <Box
        sx={{
          overflowX: "auto",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant={isMobile ? "scrollable" : "standard"}
          scrollButtons={isMobile ? "auto" : "off"}
          sx={{
            "& .MuiTab-root": isMobile
              ? {
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }
              : {},
          }}
        >
          {tabs}
        </Tabs>
        {userData?.role !== "workspace_member" &&
          userData?.role !== "workspace_admin" && (
            <Button
              style={{
                textTransform: "inherit",
                marginRight: "1rem",
                backgroundColor: "#E55A54",
              }}
              variant="contained"
              onClick={handleOpen}
            >
              Delete Workspace
            </Button>
          )}
      </Box>
      {tabPanels}
      <Dialog open={open} disableBackdropClick>
        <DialogTitle>Delete Workspace</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this workspace? This action cannot
            be undone and all of you data associated with this workspace will be
            lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ margin: "0.5rem 0.5rem" }}>
          <Button
            onClick={handleClose}
            style={{ textTransform: "inherit" }}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirmDeleteWorkspace}
            style={{ textTransform: "inherit", backgroundColor: "#E55A54" }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
