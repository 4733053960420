import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Footer from "../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { generateClient, post } from "aws-amplify/api";
import {
  getUserInfo,
  getWorkspaceInfo,
  listCartItems,
  listMyLinks,
  listSubscriptions,
  listUserInfos,
  listWorkspaceInfos,
} from "../graphql/queries";
import Skeleton from "@mui/material/Skeleton";
import { PulseLoader } from "react-spinners";
import Stack from "@mui/material/Stack";
import {
  selectLoading,
  selectUserDetails,
  setLoading,
  setUserDetails,
  showToast,
  selectPageSpeedData,
  selectPageSpeedLastChecked,
  setPageSpeedData,
  selectPageSpeedLoading,
  setPageSpeedLoading,
} from "../redux/userSlice";
import { Link, useNavigate } from "react-router-dom";
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import { Navigate } from "react-router-dom";
import { AnalyticsBrowser } from "@june-so/analytics-next";
import "../Css/Dashboard.css";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import Advisor from "../Assets/1721063651853.jpg";
import { runGTMetrixTest } from "../components/gt-matrix";
import { getSamRushProject } from "../utills/SamRushApis/samrushApi";
import { LoadingScreen } from "../components/LoadingSiteAudit";
import CircularGauge from "../components/CircularGauge";
import PerformanceMetrics from "../components/PerformanceMetrics";
import { updateWorkspaceInfo } from "../graphql/mutations";

const container = {
  margin: "2rem 4rem",
};
const title = {
  fontSize: "2rem",
  margin: "0px",
  marginBottom: "0.5rem",
};
const videoIcon = {
  color: "#0D0C0C",
  fontSize: "1.3rem",
};
const organizationCard = {
  margin: "0.7rem 0rem",
  padding: "1rem 1.5rem",
  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
  minHeight: "330px",
  borderRadius: "10px",
};
const cardTitle = {
  fontSize: "1.5rem",
  margin: "0px",
  marginTop: "0rem",
};
const cardDesc = {
  lineHeight: "1.65rem",
  color: "#605E61",
};
const buttonStyle = {
  border: "none",
  color: "#000000de",
  textTransform: "initial",
  fontSize: "1rem",
  backgroundColor: "#F0F0F0",
};
const statusStyleActive = {
  color: "#1C984D",
  fontWeight: "bold",
  backgroundColor: "#D8EBDD",
  padding: "0.1rem 0.5rem",
  display: "inline-block",
  borderRadius: "4px",
  margin: "0px",
};
const statusStyleInActive = {
  color: "#E35244",
  fontWeight: "bold",
  backgroundColor: "#f6cbc6",
  padding: "0.1rem 0.5rem",
  display: "inline-block",
  borderRadius: "4px",
  margin: "0px",
};
const rowAlign = {
  display: "flex",
  justifyContent: "space-between",
};
const avsiorContainer = {
  display: "flex",
  justifyContent: "space-between",
};

const getLevelChipColor = (level) => {
  switch (level?.toLowerCase()) {
    case "urgent":
      return {
        backgroundColor: "#FFE0E0",
        color: "#D32F2F",
        borderColor: "#FFC7C7",
      };
    case "high":
      return {
        backgroundColor: "#FFF0E0",
        color: "#ED6C02",
        borderColor: "#FFE2C7",
      };
    case "medium":
      return {
        backgroundColor: "#E3F2FD",
        color: "#1976D2",
        borderColor: "#BBDEFB",
      };
    case "low":
    default:
      return {
        backgroundColor: "#E8F5E9",
        color: "#2E7D32",
        borderColor: "#C8E6C9",
      };
  }
};
const getStateChipColor = (state) => {
  switch (state?.toLowerCase()) {
    case "develop":
      return {
        backgroundColor: "#FFF7E6",
        color: "#B76E00",
        borderColor: "#FFE2B7",
      };
    case "qa":
      return {
        backgroundColor: "#EDF7FF",
        color: "#0065BD",
        borderColor: "#B7DBFF",
      };
    case "done":
      return {
        backgroundColor: "#E6FFE6",
        color: "#006100",
        borderColor: "#B7FFB7",
      };
    case "planning":
    default:
      return {
        backgroundColor: "#F3E8FF",
        color: "#5B14B8",
        borderColor: "#E2C6FF",
      };
  }
};
const apiUsername = process.env.REACT_APP_GT_MATRIX_Username;
const apiKey = process.env.REACT_APP_GT_MATRIX_KEY;
const desktendLoginUrl = process.env.REACT_APP_DESKTEND_LOGIN_ENDPOINT;
const desktendActivitiesUrl =
  process.env.REACT_APP_DESKTEND_ACTIVITIES_ENDPOINT;
const desktendUploadUrl = process.env.REACT_APP_DESKTEND_UPLOAD_ENDPOINT;
const desktendEmail = process.env.REACT_APP_DESKTEND_USER_EMAIL;
const desktendPassword = process.env.REACT_APP_DESKTEND_USER_PASSWORD;
const desktendDesk = process.env.REACT_APP_DESKTEND_DESK;

const Dashboard = () => {
  const [userDetail, setUserDetail] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(selectLoading);
  const userInfo = useSelector(selectUserDetails);
  const pageSpeedMetrics = useSelector(selectPageSpeedData);
  const [isPageSpeedCallInProgress, setIsPageSpeedCallInProgress] =
    useState(false);
  const pageSpeedLoading = useSelector(selectPageSpeedLoading);
  const [activities, setActivities] = useState([]);

  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const client = generateClient();
  const [projectData, setProjectData] = useState(null);
  const [subscriptionsList, setSubscriptionsList] = useState(null);
  const [loader, setLoader] = useState(false);
  const [productLoader, setProductLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [workspaceAdvisor, setWorkspaceAdvisor] = useState(null);
  const [domain, setDomain] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const pageSpeedData = useSelector(selectPageSpeedData);
  const lastChecked = useSelector(selectPageSpeedLastChecked);
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const [listTeamUsers, setListTeamUsers] = useState(null);
  const [linkList, setLinkList] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoader(true);

        const res = await fetchUserData(selectedWorkspaceId);
        const result = res?.filter(
          (id) => id.workspace_id === selectedWorkspaceId
        );
        setUserDetail(result);
        dispatch(setUserDetails(result));
        // await runGTMetrixTest('https://www.rothbright.com', apiUsername, apiKey);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.error("Error creating todo:", error);
      }
    };

    fetchData();
  }, []);
  const fetchUserData = async (selectedWorkspaceId) => {
    let nextToken = null;
    let allItems = [];

    const variables = {
      filter: {
        user_id: {
          eq: userData.sub,
        },
      },
      limit: 1000, // You can adjust this value
    };

    do {
      const getAddressData = await client.graphql({
        query: listUserInfos,
        variables: {
          ...variables,
          nextToken: nextToken,
        },
      });

      const items = getAddressData.data.listUserInfos.items;
      allItems = [...allItems, ...items];
      nextToken = getAddressData.data.listUserInfos.nextToken;
    } while (nextToken);

    return allItems;
  };

  React.useEffect(() => {
    if (userDetail?.length) {
      fetchCartItems();
      WorkspaceInfoWithTeams();
    }
  }, [userDetail]);

  useEffect(() => {
    fetchWorkspaceInfo();
  }, [selectedWorkspaceId, projectId]);
  const fetchWorkspaceInfo = async () => {
    try {
      if (!selectedWorkspaceId) return;

      const response = await client.graphql({
        query: getWorkspaceInfo,
        variables: {
          id: selectedWorkspaceId,
        },
      });

      const workspaceData = response.data.getWorkspaceInfo;
      fetchActivities(workspaceData);

      setDomain(workspaceData?.samrushDomainName);
      if (workspaceData?.workspace_advisor) {
        const advisorResponse = await client.graphql({
          query: getUserInfo,
          variables: {
            id: workspaceData?.workspace_advisor,
          },
        });
        const userData = advisorResponse?.data?.getUserInfo;
        setWorkspaceAdvisor(userData);
      }
      // First check if we have a stored report
      if (workspaceData?.page_speed_report) {
        try {
          const storedReport = JSON.parse(workspaceData.page_speed_report);
          const lastCheckedDate = new Date(storedReport.lastChecked);
          const isDataRecent =
            lastCheckedDate &&
            new Date() - lastCheckedDate < 24 * 60 * 60 * 1000;
          if (isDataRecent) {
            // Use stored report if it's recent
            dispatch(setPageSpeedData(storedReport.metrics));
            return storedReport.metrics;
          }
        } catch (error) {
          console.error("Error parsing stored report:", error);
        }
      }

      // Check if call is already in progress
      if (isPageSpeedCallInProgress) {
        return;
      }

      setIsPageSpeedCallInProgress(true);
      dispatch(setPageSpeedLoading(true));

      try {
        const api_key = "AIzaSyBtL911PwgosKvUClz7OnUpf9V8PCEknDs";
        const response = await axios.get(
          `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${encodeURIComponent(
            workspaceData?.samrushDomainName
          )}&key=${api_key}&category=performance&category=accessibility&category=best-practices&category=seo`
        );

        const { data } = response;
        const metrics = {
          performance: Math.round(
            (data.lighthouseResult.categories.performance?.score || 0) * 100
          ),
          accessibility: Math.round(
            (data.lighthouseResult.categories.accessibility?.score || 0) * 100
          ),
          bestPractices: Math.round(
            (data.lighthouseResult.categories["best-practices"]?.score || 0) *
              100
          ),
          seo: Math.round(
            (data.lighthouseResult.categories.seo?.score || 0) * 100
          ),
        };

        // Store report in database
        const reportData = {
          metrics,
          lastChecked: new Date().toISOString(),
        };

        await client.graphql({
          query: updateWorkspaceInfo,
          variables: {
            input: {
              id: selectedWorkspaceId,
              page_speed_report: JSON.stringify(reportData),
            },
          },
        });

        dispatch(setPageSpeedData(metrics));
        return metrics;
      } catch (error) {
        console.error("Error fetching PageSpeed data", error);
        dispatch(
          showToast({
            message: "Domain name is missing!",
            type: "error",
          })
        );
        return null;
      } finally {
        dispatch(setPageSpeedLoading(false));
        setIsPageSpeedCallInProgress(false);
      }
    } catch (error) {
      console.error("Error fetching workspace info:", error);
    }
  };
  const fetchActivities = async (feed_data) => {
    console.log("The feed data is::", feed_data);

    try {
      const loginResponse = await axios.post(desktendLoginUrl, {
        username: desktendEmail,
        password: desktendPassword, // You'll need to determine how to securely handle the password
      });

      if (loginResponse.data.accessToken) {
        const response = await axios.get(desktendActivitiesUrl, {
          headers: {
            Authorization: `Bearer ${loginResponse.data.accessToken}`,
            Desk: desktendDesk,
          },
        });
        console.log("response", response);
        const filteredActivities = response.data.filter(
          (item) => item.feedId === feed_data?.feed_id
        );
        console.log("The filterd Activities", filteredActivities);
        const formattedActivities = filteredActivities.map((item) => ({
          id: item.id,
          title: item.title,
          status: item.status == null ? "Pending" : item.status,
          state: item.state == null ? "Pending" : item.state,
          progress: item.progress,
          level: item.level,
          dueOn: item.dueOn ? new Date(item.dueOn).toLocaleString() : "Not Set",
        }));
        setActivities(formattedActivities.slice(0, 4));
      }
    } catch (error) {
      console.error("Error fetching activities:", error);
      // Handle error (e.g., show error message to user)
    }
  };
  React.useEffect(() => {
    WorkspaceInfoWithTeams();
  }, []);

  const WorkspaceInfoWithTeams = async () => {
    setLoader(true);
    try {
      const variables = {
        filter: {
          id: {
            eq: selectedWorkspaceId,
          },
        },
      };
      const data = await client.graphql({
        query: listWorkspaceInfos,
        variables: variables,
      });
      const userIdis = data.data.listWorkspaceInfos.items?.[0]?.user_id;
      if (userIdis) {
        const userPromises = userIdis.map(async (id) => {
          const variables = {
            filter: {
              user_id: {
                eq: id,
              },
              workspace_id: {
                eq: selectedWorkspaceId,
              },
            },
            limit: 1000,
          };
          const getUserData = await client.graphql({
            query: listUserInfos,
            variables: variables,
          });
          return getUserData?.data?.listUserInfos?.items[0];
        });
        const usersData = await Promise.all(userPromises);
        // const filteredData = usersData?.filter(
        //   (user) => user?.role !== "super_admin"
        // );
        setListTeamUsers(usersData);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  useEffect(() => {
    getSubscripition();
    getLinksData();
  }, []);

  const getSubscripition = async () => {
    try {
      const variables = {
        filter: {
          workspace_id: {
            eq: selectedWorkspaceId,
          },
        },
      };

      const getSubscriptions = await client.graphql({
        query: listSubscriptions,
        variables: variables,
      });
      const result = getSubscriptions.data.listSubscriptions.items;
      const filteredResult = result?.filter((item) => item.status === true);
      setSubscriptionsList(filteredResult);
    } catch (error) {
      setLoader(false);
      console.error("Error creating todo:", error);
    }
  };

  const fetchCartItems = async () => {
    try {
      const variables = {
        filter: {
          user_id: {
            eq: userData.sub,
          },
        },
      };

      const getCartItems = await client.graphql({
        query: listCartItems,
        variables: variables,
      });
      const res = getCartItems.data.listCartItems.items;
      const result = res?.filter(
        (id) => id.workspace_id === selectedWorkspaceId
      );
    } catch (error) {
      setLoader(false);
      console.error("Error creating todo:", error);
    }
  };

  const getLinksData = async () => {
    dispatch(setLoading(true));
    try {
      const variables = {
        filter: {
          workspace_id: {
            eq: selectedWorkspaceId,
          },
        },
      };
      const result = await client.graphql({
        query: listMyLinks,
        variables: variables,
      });
      setLinkList(result.data.listMyLinks.items);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  if (!selectedWorkspaceId) {
    return <Navigate to="/choose-organization" replace />;
  }

  let analytics = AnalyticsBrowser.load({
    writeKey: "2fwEgI0bB0meFBAI",
  });
  console.log("The activities", activities);

  return (
    <div style={container} className="main-container">
      {/* <button onClick={getUsers}>Get</button> */}
      {loading ? (
        <Stack spacing={4}>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            height={50}
            animation="wave"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "2rem",
            }}
          >
            <Skeleton
              variant="rounded"
              width={"30%"}
              height={300}
              animation="wave"
            />
            <Skeleton
              variant="rounded"
              width={"70%"}
              height={300}
              animation="wave"
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "2rem",
            }}
          >
            <Skeleton
              variant="rounded"
              width={"33%"}
              height={300}
              animation="wave"
            />
            <Skeleton
              variant="rounded"
              width={"33%"}
              height={300}
              animation="wave"
            />
            <Skeleton
              variant="rounded"
              width={"33%"}
              height={300}
              animation="wave"
            />
          </div>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            height={50}
            animation="wave"
          />
        </Stack>
      ) : (
        <Grid container spacing={0}>
          <Grid container spacing={3}>
            {/* Site Health Gauge */}
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
              <Card style={organizationCard} className="dashboard-section-card">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: 10,
                  }}
                >
                  <h4 style={cardTitle} className="card-title">
                  BrightList™
                  </h4>
                  {activities.length > 2 && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        variant="outlined"
                        style={buttonStyle}
                        onClick={() => navigate("/ticket-request")}
                      >
                        View All Activity
                      </Button>
                    </Box>
                  )}
                </div>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>Title</TableCell>
                        <TableCell
                          sx={{ fontWeight: "bold", alignItems: "center" }}
                        >
                          Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {activities.length > 0 ? (
                        activities.map((activity, index) => (
                          <TableRow
                            key={activity.id}
                            sx={{
                              "& > td": {
                                paddingTop: 2,
                                paddingBottom: 2,
                                borderBottom:
                                  index === activities.length - 1
                                    ? "none"
                                    : "1px solid rgba(224, 224, 224, 1)",
                              },
                            }}
                          >
                            <TableCell>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Tooltip title={activity.title}>
                                  <Typography
                                    variant="body2"
                                    color="primary"
                                    sx={{
                                      maxWidth: "200px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {activity.title}
                                  </Typography>
                                </Tooltip>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  textAlign: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    px: 2,
                                    py: 0.5,
                                    borderRadius: "16px",
                                    fontSize: "0.875rem",
                                    fontWeight: "500",
                                    textAlign: "center",
                                    ...getStateChipColor(activity.state),
                                  }}
                                >
                                  {activity.state === "Develop"
                                    ? "In Progress"
                                    : activity.state === "Qa"
                                    ? "In Review"
                                    : activity.state}
                                </Box>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={5} align="center">
                            <Typography variant="body1" sx={{ py: 3 }}>
                              No records found
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <Button
                  variant="outlined"
                  style={buttonStyle}
                  onClick={handleOpenModal}
                >
                  Welcome Video&nbsp;
                  <PlayCircleIcon style={videoIcon} />
                </Button> */}
              </Card>
              {/* <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="welcome-video-modal"
                aria-describedby="welcome-video-description"
                className={classes.modal}
                disableBackdropClick // Optional: Prevent closing on backdrop click
              >
                <div className={classes.modalContent}>
                  <h3 id="welcome-video-modal">Welcome Video</h3>
                  <p id="welcome-video-description">
                    This video provides an overview of Rothbright Dashboard
                    features.
                  </p> */}
              {/* Replace with your video source */}
              {/* <video className={classes.video} controls autoPlay muted>
                    <source
                      src="/src/Assets/invideo-ai-1080 Exploring Rothbright_ Your Ultimate Work 2024-02-29.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video> */}
              {/* </div> */}
              {/* </Modal> */}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={8}
              className="team-container"
            >
              {pageSpeedLoading ? (
                // <Card
                //   style={organizationCard}
                //   className="dashboard-section-card"
                // >
                <>
                  <LoadingScreen
                    websiteUrl={domain}
                    IsPageSpeedLoadingRoute={true}
                    onClose={() => setIsLoading(false)}
                  />
                </>
              ) : (
                // </Card>
                <Card style={organizationCard}>
                  <PerformanceMetrics
                    data={pageSpeedMetrics}
                    domain={domain}
                    setDomain={setDomain}
                    fetchWorkspaceInfo={fetchWorkspaceInfo}
                    selectedWorkspaceId={selectedWorkspaceId}
                  />
                </Card>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              className="team-container"
            >
              <Card
                style={organizationCard}
                className="dashboard-section-card team-container"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "1.3rem",
                  }}
                >
                  <h4 style={cardTitle} className="card-title">
                    Reports
                  </h4>
                  <Button
                    variant="outlined"
                    style={buttonStyle}
                    onClick={() => navigate("/links")}
                  >
                   View All
                  </Button>
                </div>
                <div style={rowAlign}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      {linkList?.length > 0 &&
                        linkList?.slice(0, 3).map((_link, index, array) => (
                          <>
                            <div style={{ display: "flex", gap: "0.5rem" }}>
                              <img
                                src={_link?.image_url}
                                height="50px"
                                width="50px"
                                style={{
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "start",
                                }}
                              >
                                <div>
                                   <Link
                                                                      to={_link.url}
                                                                      style={{
                                                                        textDecoration: "none",
                                                                        color: "inherit",
                                                                      }}
                                                                      target="_blank"
                                                                    >

                                  <h3 
                                    style={{
                                      margin: "0px",
                                      textAlign: "start",
                                      whiteSpace: isMobile && "nowrap",
                                      overflow: isMobile && "hidden",
                                      textOverflow: isMobile && "ellipsis",
                                      maxWidth: isMobile && "250px",
                                      cursor:'pointer'
                                    }}
                                  >
                                    {_link?.name}&nbsp;
                                 
                                  </h3>
                                    </Link>
                                </div>
                               
                              </div>
                            </div>
                            {index !== array?.length - 1 && (
                              <Divider
                                light
                                style={{ margin: "0.5rem 0rem" }}
                              />
                            )}
                          </>
                        ))}
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Card
                style={organizationCard}
                className="subscription-container "
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "1.3rem",
                  }}
                  className="subscription-sub-container"
                >
                  <h4 style={cardTitle} className="card-title">
                    My Plan
                  </h4>
                  <Button
                    variant="outlined"
                    style={buttonStyle}
                    onClick={() => navigate("/subscription")}
                    className="manage-sub-btn"
                  >
                    Manage Plan
                  </Button>
                </div>
                {loader ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "150px",
                      alignSelf: "center",
                    }}
                  >
                    <PulseLoader color="#ACD6EB" />
                  </div>
                ) : (
                  <>
                    {subscriptionsList && subscriptionsList.length > 0
                      ? subscriptionsList
                          ?.slice(0, 3)
                          .map((item, index, array) => (
                            <>
                              <div style={rowAlign} key={index}>
                                <Grid container spacing={0}>
                                  <Grid item xs={9}>
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "0.5rem",
                                      }}
                                    >
                                      <img
                                        src={item.product_image}
                                        style={{
                                          height: "50px",
                                          width: "50px",
                                          borderRadius: "8%",
                                          backgroundColor: "#dddddd",
                                        }}
                                      />
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          alignItems: "start",
                                        }}
                                      >
                                        <div>
                                          <h3
                                            style={{
                                              margin: "0px",
                                              textAlign: "start",
                                            }}
                                          >
                                            {item.product_name}
                                          </h3>
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3}
                                    style={{
                                      textAlign: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p
                                      style={
                                        item.status === true
                                          ? statusStyleActive
                                          : statusStyleInActive
                                      }
                                    >
                                      {item.status ? "Active" : "InActive"}
                                    </p>
                                  </Grid>
                                </Grid>
                              </div>
                              {index !== array?.length - 1 && (
                                <Divider
                                  light
                                  style={{ margin: "0.5rem 0rem" }}
                                />
                              )}
                            </>
                          ))
                      : null}
                    {subscriptionsList &&
                      subscriptionsList.length === 0 &&
                      !loader && (
                        <>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              justifyContent: "center",
                              minHeight: "150px",
                            }}
                            className="sub-btn-container"
                          >
                            <span className="shop-text">
                              You haven't started your plan.
                            </span>
                            <Button
                              variant="contained"
                              style={{
                                marginTop: "0.5rem",
                                textTransform: "inherit",
                              }}
                              onClick={() => navigate("/shop")}
                              className="sub-button"
                            >
                              Build Your Plan
                            </Button>
                          </div>
                        </>
                      )}
                  </>
                )}
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Card style={organizationCard} className="dashboard-section-card">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "1rem",
                  }}
                >
                  <h4 style={cardTitle} className="card-title">
                    Team
                  </h4>
                  
                    <Button onClick={()=>navigate("/team")} variant="outlined" style={buttonStyle}>
                     View All
                    </Button>
               
                </div>
                <div
                  style={{
                    display: "flex",
                  
                  }}
                >
                  <div >
                    <Grid container spacing={0}>
                      <Grid item xs={6}>
                        <div style={{ display: "flex", gap: "1rem" }}>
                          <img
                            src={
                              workspaceAdvisor?.display_picture
                                ? workspaceAdvisor?.display_picture
                                : Advisor
                            }
                            height="60px"
                            width="60px"
                            style={{
                              objectFit: "cover",
                              borderRadius: "50%",
                            }}
                          />
                         <div
  style={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
  }}
>
  <div style={{ display: "flex", alignItems: "flex-start", gap: "8px" }}>
    <div>
      <h3
        style={{
          margin: "0px",
          textAlign: "start",
        }}
      >
        {workspaceAdvisor?.user_name_first
          ? `${workspaceAdvisor?.user_name_first}${workspaceAdvisor?.user_name_second}`
          : "Marcus Frye"}
      </h3>
      <div style={{ color: "#3b3b3b" }}>
        {workspaceAdvisor?.email
          ? workspaceAdvisor?.email
          : "marcus@rothbright.com"}
      </div>
      {!workspaceAdvisor?.id && (
        <>
          <div style={{ color: "#3b3b3b" }}>
            Los Angeles, CA
          </div>
          <div style={{ color: "#939393" }}>
            Pacific Time
          </div>
        </>
      )}
    </div>
    <h3 style={{ ...statusStyleActive, height: "fit-content", fontSize: "16px" }}>Advisor</h3>
  </div>
</div>
                        </div>
                        <Grid item xs={12}>
                      {listTeamUsers?.length > 0 &&
                        listTeamUsers?.slice(0, 3).map((team, index, array) => (
                          <>
                            <div style={{ display: "flex", gap: "1rem",marginTop:8 }}>
                              <img
                                src={team?.display_picture}
                                height="50px"
                                width="50px"
                                style={{
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "start",
                                }}
                              >
                                <div>
                                  <h3
                                    style={{
                                      margin: "0px",
                                      textAlign: "start",
                                      whiteSpace: isMobile && "nowrap",
                                      overflow: isMobile && "hidden",
                                      textOverflow: isMobile && "ellipsis",
                                      maxWidth: isMobile && "250px",
                                    }}
                                  >
                                    {team?.user_name_first}&nbsp;
                                    {team?.user_name_second}
                                  </h3>
                                </div>
                                <div style={{ color: "#3b3b3b" }}>
                                  {team?.email}
                                </div>
                              </div>
                            </div>
                            {index !== array?.length - 1 && (
                              <Divider
                                light
                                style={{ margin: "0.5rem 0rem" }}
                              />
                            )}
                          </>
                        ))}
                    </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={3}></Grid>
          <div className="space"></div>
          <Grid container spacing={5} className="footer-container">
            <Grid item xs={12}>
              <Footer />
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Dashboard;
