import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  TextField,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Avatar,
  Modal,
  Button,
  useMediaQuery,
  Grid,
  TableSortLabel
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import './ticketRequest.css';
import Footer from '../components/Footer';
import { selectLoading, setLoading, showToast } from '../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { listWorkspaceInfos } from '../graphql/queries';
import { generateClient } from 'aws-amplify/api';
import ActivityDetalisModal from '../components/ActivityDetalisModal';
import SortIcon from '@mui/icons-material/SwapVertOutlined';

const desktendLoginUrl = process.env.REACT_APP_DESKTEND_LOGIN_ENDPOINT
const desktendActivitiesUrl = process.env.REACT_APP_DESKTEND_ACTIVITIES_ENDPOINT;
const desktendUploadUrl = process.env.REACT_APP_DESKTEND_UPLOAD_ENDPOINT;
const desktendEmail = process.env.REACT_APP_DESKTEND_USER_EMAIL
const desktendPassword = process.env.REACT_APP_DESKTEND_USER_PASSWORD
const desktendDesk = process.env.REACT_APP_DESKTEND_DESK

// Add this function outside your component to get chip color based on level
const getLevelChipColor = (level) => {
  switch (level?.toLowerCase()) {
    case 'urgent':
      return {
        backgroundColor: '#FFE0E0',
        color: '#D32F2F',
        borderColor: '#FFC7C7'
      };
    case 'high':
      return {
        backgroundColor: '#FFF0E0',
        color: '#ED6C02',
        borderColor: '#FFE2C7'
      };
    case 'medium':
      return {
        backgroundColor: '#E3F2FD',
        color: '#1976D2',
        borderColor: '#BBDEFB'
      };
    case 'low':
    default:
      return {
        backgroundColor: '#E8F5E9',
        color: '#2E7D32',
        borderColor: '#C8E6C9'
      };
  }
};

// Add this function to get state chip colors
const getStateChipColor = (state) => {
  switch (state?.toLowerCase()) {
    case 'develop':
      return {
        backgroundColor: '#FFF7E6',
        color: '#B76E00',
        borderColor: '#FFE2B7'
      };
    case 'qa':
      return {
        backgroundColor: '#EDF7FF',
        color: '#0065BD',
        borderColor: '#B7DBFF'
      };
    case 'done':
      return {
        backgroundColor: '#E6FFE6',
        color: '#006100',
        borderColor: '#B7FFB7'
      };
    case 'planning':
    default:
      return {
        backgroundColor: '#F3E8FF',
        color: '#5B14B8',
        borderColor: '#E2C6FF'
      };
  }
};
const priorityOrder = {
  'Urgent': 1,
  'High': 2,
  'Medium': 3,
  'Low': 4
};
const TicketRequest = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const client = generateClient();
  const dispatch = useDispatch();
  
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const [workspaceData, setWorkspaceData] = useState([]);
  const [activities, setActivities] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [newRequest, setNewRequest] = useState({
    title: "",
    description: "",
    files: []
  });
  const [filters, setFilters] = useState({
    search: '',
    state: '',
    level: '',
  });
  const [sortConfig, setSortConfig] = useState({ key: 'level', direction: 'asc' }); // Changed default sort

  const organizationCard = {
    padding: isMobile ? "0rem" : "1.5rem 2rem",
    boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
    borderRadius: "10px",
    minHeight: "68vh",
    width: "100%",
    backgroundColor: "white"
  };
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    loadWorkspaceData();

  }, []);

  const fetchWorkspaceData = async (selectedWorkspaceId) => {
    let nextToken = null;
    let allItems = [];

    const variables = {
      filter: {
        id: {
          eq: selectedWorkspaceId,
        },
      },
      limit: 1000,
    };

    do {
      const getAddressData = await client.graphql({
        query: listWorkspaceInfos,
        variables: {
          ...variables,
          nextToken: nextToken
        },
      });

      const items = getAddressData.data.listWorkspaceInfos.items;
      allItems = [...allItems, ...items];
      nextToken = getAddressData.data.listWorkspaceInfos.nextToken;
    } while (nextToken);

    return allItems;
  };



  const loadWorkspaceData = async () => {
    try {
        dispatch(setLoading(true));
      const data = await fetchWorkspaceData(selectedWorkspaceId);
      fetchActivities(data);

      setWorkspaceData(data);
      
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error creating todo:", error);
    }
  };

  // const fetchActivities = async (feed_data) => {
  //   dispatch(setLoading(true));

  //   try {
  //     const loginResponse = await axios.post(desktendLoginUrl, {
  //       username: desktendEmail,
  //       password: desktendPassword // You'll need to determine how to securely handle the password
  //     });

  //     if (loginResponse.data.accessToken) {
  //       const response = await axios.get(desktendActivitiesUrl, {
  //         headers: {
  //           'Authorization': `Bearer ${loginResponse.data.accessToken}`,
  //           'Desk': desktendDesk
  //         }
  //       })
  //       const filteredActivities = response.data.filter(item => item.feedId === feed_data[0]?.feed_id);
  //       const formattedActivities = await Promise.all(
  //         filteredActivities.map(async (item) => {
  //           const ownerName = item.ownerId
  //             ? await fetchOwnerName(item.ownerId,loginResponse.data.accessToken)
  //             : "Unknown";
  //           return {
  //             ...item,
  //             ownerName, // Add owner's name to the activity
  //             status: item.status == null ? "Pending" : item.status,
  //             state: item.state == null ? "Pending" : item.state,
  //             dueOn: item.dueOn ? new Date(item.dueOn).toLocaleString() : "Not Set",
  //           };
  //         })
  //       );
  
  //       setActivities(formattedActivities);
  //     }
     

  //   } catch (error) {
  //     dispatch(setLoading(false));

  //     console.error('Error fetching activities:', error);
  //     // Handle error (e.g., show error message to user)
  //   }
  // };
  const fetchActivities = async (feed_data) => {
    dispatch(setLoading(true));

    try {
      const loginResponse = await axios.post(desktendLoginUrl, {
        username: desktendEmail,
        password: desktendPassword
      });

      if (loginResponse.data.accessToken) {
        const response = await axios.get(desktendActivitiesUrl, {
          headers: {
            'Authorization': `Bearer ${loginResponse.data.accessToken}`,
            'Desk': desktendDesk
          }
        });
        const filteredActivities = response.data.filter(item => item.feedId === feed_data[0]?.feed_id);
        const formattedActivities = await Promise.all(
          filteredActivities.map(async (item) => {
            const ownerName = item.ownerId
              ? await fetchOwnerName(item.ownerId, loginResponse.data.accessToken)
              : "Unknown";
            return {
              ...item,
              ownerName,
              status: item.status == null ? "Pending" : item.status,
              state: item.state == null ? "Pending" : item.state,
              dueOn: item.dueOn ? new Date(item.dueOn).toLocaleString() : "Not Set",
              level: item.level || "Low" // Ensure level is never null
            };
          })
        );

        // Sort activities by priority by default
        const sortedActivities = formattedActivities.sort((a, b) => {
          return priorityOrder[a.level] - priorityOrder[b.level];
        });

        setActivities(sortedActivities);
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      console.error('Error fetching activities:', error);
    }
  };

  const fetchOwnerName = async (ownerId,token) => {
    try {
      dispatch(setLoading(true));

      const response = await axios.get(`https://prod-api.desktend.com/api/users/${ownerId}`,{
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
    
      return response?.data?.firstName; // Adjust based on your API response structure

    } catch (error) {
      console.error(`Error fetching owner name for ID ${ownerId}:`, error);
      dispatch(setLoading(false));

      return "Unknown"; // Fallback value
    }
  };
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleSubmitRequest = async () => {
    try {
      dispatch(setLoading(true));

      // Login step
      const loginFormData = new URLSearchParams();
      loginFormData.append('username', desktendEmail);
      loginFormData.append('password', desktendPassword);

      const loginResponse = await axios.post(desktendLoginUrl, loginFormData.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      });


      if (loginResponse.data.accessToken) {
        // Create activity
        const activityFormData = new URLSearchParams();
        activityFormData.append('title', newRequest.title);
        activityFormData.append('feed[id]', workspaceData[0]?.feed_id);
        activityFormData.append('description', newRequest.description);
        activityFormData.append('state', 'Planning');

        const response = await axios.post(desktendActivitiesUrl, activityFormData.toString(), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${loginResponse.data.accessToken}`,
            'Desk': desktendDesk
          }
        });

        // Handle file uploads if files exist
        if (newRequest.files.length > 0) {
          const uploadFormData = new FormData();
          uploadFormData.append('activity[id]', response.data.id);
          // Append each file with a unique key
          newRequest.files.forEach((file, index) => {
            uploadFormData.append(`files`, file);
          });

          // Log the FormData contents for debugging
          for (let pair of uploadFormData.entries()) {
            console.log(pair[0], pair[1]);
          }

          const uploadFiles = await axios.post(desktendUploadUrl, uploadFormData, {
            headers: {
              'Authorization': `Bearer ${loginResponse.data.accessToken}`,
              'Desk': desktendDesk,
              'Content-Type': 'multipart/form-data',
            }
          });
        }
        dispatch(setLoading(false));

        handleCloseModal();
        window.location.reload();
        dispatch(showToast({ message: "Activity created successfully", type: "success" }));
      } else {
        dispatch(setLoading(false));
        throw new Error("Login failed");

      }
    } catch (error) {
      console.error("Error:", error);
      dispatch(setLoading(false));

      dispatch(showToast({ message: error.message || "Error creating activity", type: "error" }));
    }
  }

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const filteredActivities = activities.filter(activity => {
    dispatch(setLoading(true));

    const searchLower = filters.search.toLowerCase();
    const titleMatch = activity.title && activity.title.toLowerCase().includes(searchLower);
    const descriptionMatch = activity.description && activity.description.toLowerCase().includes(searchLower);

    // Exact match for level filter
    const levelMatch = filters.level === '' ||
      (filters.level.toLowerCase() === 'low' ?
        (!activity.level || activity.level.toLowerCase() === 'low') : // Match both null and 'low'
        activity.level?.toLowerCase() === filters.level.toLowerCase()); // Match other levels exactly

        dispatch(setLoading(false));
    return (
      (filters.search === '' || titleMatch || descriptionMatch) &&
      (filters.state === '' || activity.state === filters.state) &&
      levelMatch
    );

  });

  const handleAttachFiles = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true; // Allow multiple files
    fileInput.accept = '.pdf,.doc,.docx,.txt,.jpg,.jpeg,.png'; // Restrict file types

    fileInput.onchange = (e) => {
      const files = Array.from(e.target.files);

      // Check file sizes and types
      const validFiles = files.filter(file => {
        const maxSize = 5 * 1024 * 1024; // 5MB limit
        if (file.size > maxSize) {
          dispatch(showToast({
            message: `File ${file.name} is too large. Maximum size is 5MB`,
            type: "error"
          }));
          return false;
        }
        return true;
      });

      setUploadedFiles(prevFiles => [...prevFiles, ...validFiles]);
      setNewRequest(prev => ({
        ...prev,
        files: [...prev.files, ...validFiles]
      }));
    };

    fileInput.click();
  };

  const handleRemoveFile = (indexToRemove) => {
    setUploadedFiles(prevFiles =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
    setNewRequest(prev => ({
      ...prev,
      files: prev.files.filter((_, index) => index !== indexToRemove)
    }));
  };

  const handleActivityClick = (activity) => {
    setSelectedActivity(activity);
    setIsDrawerOpen(true);
  };
 
 const handleSort = (key) => {
    const isAsc = sortConfig.key === key && sortConfig.direction === 'asc';
    const newDirection = isAsc ? 'desc' : 'asc';
    setSortConfig({ key, direction: newDirection });

    const sortedData = [...activities].sort((a, b) => {
      let comparison = 0;

      switch (key) {
        case 'level':
          comparison = priorityOrder[a.level || 'Low'] - priorityOrder[b.level || 'Low'];
          break;
        case 'state':
          const stateOrder = {
            'Planning': 1,
            'Develop': 2,
            'Qa': 3,
            'Done': 4,
            'Pending': 5
          };
          comparison = (stateOrder[a.state] || 999) - (stateOrder[b.state] || 999);
          break;
        case 'dueOn':
          const dateA = a.dueOn === "Not Set" ? new Date(8640000000000000) : new Date(a.dueOn);
          const dateB = b.dueOn === "Not Set" ? new Date(8640000000000000) : new Date(b.dueOn);
          comparison = dateA - dateB;
          break;
        case 'progress':
          const progressA = parseInt(a.progress || '0');
          const progressB = parseInt(b.progress || '0');
          comparison = progressA - progressB;
          break;
        default:
          comparison = (a[key] || '').toString().localeCompare((b[key] || '').toString());
      }

      return newDirection === 'asc' ? comparison : -comparison;
    });

    setActivities(sortedData);
  };
  return (
    <Box sx={{
      padding: 5, backgroundColor: '#f5f5f5', minHeight: '100vh', margin: '0rem 4rem'
    }}>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="p" component="p" sx={{ fontWeight: 'bold', fontSize: "2rem" }}>
        BrightList™
        </Typography>

        <IconButton

          sx={{ bgcolor: '#1692E0', padding: "0.5rem", color: 'white', '&:hover': { bgcolor: '#1565c0' } }}
          onClick={handleOpenModal}
        >
          <AddIcon style={{
            color: "#fff",
            fontSize: "2rem",
          }} />
        </IconButton>
      </Box>

      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <TextField
          name="search"
          value={filters.search}
          onChange={handleFilterChange}
          placeholder="Search title or description"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: <SearchIcon sx={{ color: 'action.active', mr: 1 }} />,
          }}
          sx={{ flexGrow: 1, bgcolor: 'white' }}
        />
        <Select
          name="state"
          value={filters.state}
          onChange={handleFilterChange}
          size="small"
          sx={{ bgcolor: 'white', minWidth: 160 }}
          displayEmpty
        >
          <MenuItem value="">All States</MenuItem>
          <MenuItem value="Planning">Planning</MenuItem>
          <MenuItem value="Develop">In Progress</MenuItem>
          <MenuItem value="Qa">In Review</MenuItem>
          {/* Add other states as needed */}
        </Select>
        <Select
          name="level"
          value={filters.level}
          onChange={handleFilterChange}
          size="small"
          sx={{ bgcolor: 'white', minWidth: 160 }}
          displayEmpty
        >
          <MenuItem value="">All Levels</MenuItem>
          <MenuItem value="Urgent">Urgent</MenuItem>
          <MenuItem value="High">High</MenuItem>
          <MenuItem value="Medium">Medium</MenuItem>
          <MenuItem value="Low">Low</MenuItem>
        </Select>
      </Box>

      <Card style={organizationCard} >
        <CardContent sx={{ p: 0 }}>
          <TableContainer>
            <Table size="small">
              <TableHead >
              <TableRow>
        <TableCell>
          <TableSortLabel
            active={sortConfig.key === 'title'}
            direction={sortConfig.key === 'title' ? sortConfig.direction : 'asc'}
            onClick={() => handleSort('title')}
          >
            Title
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
            active={sortConfig.key === 'level'}
            direction={sortConfig.key === 'level' ? sortConfig.direction : 'asc'}
            onClick={() => handleSort('level')}
          >
            Priority
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
            active={sortConfig.key === 'state'}
            direction={sortConfig.key === 'state' ? sortConfig.direction : 'asc'}
            onClick={() => handleSort('state')}
          >
            Status
          </TableSortLabel>
        </TableCell>
        
        <TableCell>
          <TableSortLabel
            active={sortConfig.key === 'dueOn'}
            direction={sortConfig.key === 'dueOn' ? sortConfig.direction : 'asc'}
            onClick={() => handleSort('dueOn')}
          >
            Expected Completion
          </TableSortLabel>
        </TableCell>
      </TableRow>
              </TableHead>
              <TableBody>
                {filteredActivities.length > 0 ? (
                  filteredActivities.map((activity, index) => (
                    <TableRow
                      key={activity.id}
                      sx={{
                        "& > td": {
                          paddingTop: 2,
                          paddingBottom: 2,
                          borderBottom: index === activities.length - 1 ? "none" : "1px solid rgba(224, 224, 224, 1)",
                        }
                      }}
                    >

                      <TableCell>
                        <Box   sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar sx={{ width: 24, height: 24, ml: 2, mr: 2 }}>
                            {/* You can add an icon or image here */}
                          </Avatar>
                          <Typography  sx={{ 
        cursor: 'pointer',
        '&:hover': { textDecoration: 'underline' }
      }}
      onClick={() => handleActivityClick(activity)} variant="body2" color="primary">
                            {activity.title}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box
                            sx={{
                              px: 2,
                              py: 0.5,
                              borderRadius: '16px',
                              border: '1px solid',
                              fontSize: '0.875rem',
                              fontWeight: '500',
                              ...getLevelChipColor(activity.level)
                            }}
                          >
                            {activity.level || "Low"}
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box
                            sx={{
                              px: 2,
                              py: 0.5,
                              borderRadius: '16px',
                              border: '1px solid',
                              fontSize: '0.875rem',
                              fontWeight: '500',
                              ...getStateChipColor(activity.state)
                            }}
                          >
                            {activity.state === 'Develop' ? 'In Progress' :
                              activity.state === 'Qa' ? 'In Review' :
                                activity.state}
                          </Box>
                        </Box>
                      </TableCell>
                      {/* <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {activity.status ? `${activity.status}` : "Pending"}
                        </Box>
                      </TableCell> */}

                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {activity.dueOn ? activity.dueOn : "Not Set"}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <Typography variant="body1" sx={{ py: 3 }}>
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
<ActivityDetalisModal isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} selectedActivity={selectedActivity} />
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="new-request-modal"
        aria-describedby="modal-to-create-new-request"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: '90vh',
          overflow: 'auto'
        }}>
          <Typography id="new-request-modal" variant="h6" component="h2" sx={{ mb: 2 }}>
            New Activity
          </Typography>
          <Typography sx={{ mb: 2 }}>
            Create your request using the form below. Use the chat to let us know if you have any questions.
          </Typography>
          <TextField
            value={newRequest.title}
            onChange={(e) => setNewRequest({ ...newRequest, title: e.target.value })}
            fullWidth
            label="Title"
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <TextField
            value={newRequest.description}
            onChange={(e) => setNewRequest({ ...newRequest, description: e.target.value })}
            fullWidth
            label="Request"
            variant="outlined"
            multiline
            rows={4}
            sx={{ mb: 2 }}
          />
          <Button
            onClick={handleAttachFiles}
            variant="contained"
            startIcon={<AttachFileIcon />}
            sx={{ mb: 2 }}
          >
            Attach Files
          </Button>

          {/* Display uploaded files */}
          {uploadedFiles.length > 0 && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                Uploaded Files:
              </Typography>
              {uploadedFiles.map((file, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 1,
                    mb: 1,
                    bgcolor: '#f5f5f5',
                    borderRadius: 1
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                    <AttachFileIcon sx={{ mr: 1, color: '#1692E0' }} />
                    <Typography noWrap sx={{ maxWidth: '200px' }}>
                      {file.name}
                    </Typography>
                    <Typography variant="caption" sx={{ ml: 1, color: 'text.secondary' }}>
                      ({(file.size / 1024).toFixed(1)} KB)
                    </Typography>
                  </Box>
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveFile(index)}
                    sx={{ color: 'error.main' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button
              variant="contained"
              onClick={handleSubmitRequest}
              disabled={!newRequest.title || !newRequest.description}
            >
              Submit Request
            </Button>
          </Box>
        </Box>
      </Modal>
      {!isMobile && (
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default TicketRequest;
