import React, { useEffect, useState } from "react";
import { Box, Button, Card, Grid, TextField } from "@mui/material";
import Divider from "@mui/material/Divider";
import Footer from "../components/Footer";
import { showToast } from "../redux/userSlice";
import { useDispatch } from "react-redux";
import { listSubscriberCards, listUserBillings } from "../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { PulseLoader } from "react-spinners";
import { useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import TransactionDetailModal from "../components/transaction_detail_modal";
import { stripeApi } from "../utills/stripeApis/stripeApi";

const Order = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const container = {
    margin: isMobile ? "1rem 1rem" : "2rem 4rem",
  };
  const title = {
    fontSize: isMobile ? "1rem" : "2rem",
    margin: "0px",
    marginBottom: "1rem",
  };
  const organizationCard = {
    padding: isMobile ? "0rem" : "1.5rem 2rem",
    boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
    borderRadius: "10px",
    minHeight: "68vh",
  };
  const statusStyleActive = {
    color: "#1C984D",
    fontWeight: "bold",
    padding: "0.1rem 0.5rem",
    display: "inline-block",
    borderRadius: "4px",
    margin: "0px",
  };
  const statusStyleInActive = {
    color: "#E35244",
    fontWeight: "bold",
    padding: "0.1rem 0.5rem",
    display: "inline-block",
    borderRadius: "4px",
    margin: "0px",
  };
  const nameContainerStyle = {
    display: "inline-block",
    maxWidth: "180px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    verticalAlign: "middle",
  };
  const buttonStyle = {
    textTransform: "initial",
    fontSize: "1rem",
    border: "none",
    paddingLeft: 0,
  };
  const client = generateClient();
  const dispatch = useDispatch();
  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const [orderList, setOrderList] = useState(null);
  const [loader, setLoader] = useState(false);
  const [openRefundModal, setOpenRefundModal] = React.useState(false);
  const [openTransDetailModal, setOpenTransDetailModal] = React.useState(false);
  const [idToRefund, setIdToRefund] = useState(null);
  const [refundAmount, setRefundAmount] = React.useState("");
  const [refundDesc, setRefundDesc] = React.useState("");
  const [refundAmountError, setRefundAmountError] = React.useState(false);
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const [subscriberData, setSubscriberData] = useState(null);
  const [userBillingData, setUserBillingData] = useState(null);
  const [transDetail, setTransDetail] = useState(null);
  const [stripeOrders, setStripeOrders] = useState(null);
  const [nextRenewalDate, setNextRenewalDate] = useState(null);

  const merchant_name = process.env.REACT_APP_MERCHANT_LOGIN_NAME;
  const transaction_key = process.env.REACT_APP_MERCHANT_TRANSACTION_KEY;
  const authorize_api = process.env.REACT_APP_AUTHORIZE_API;
  useEffect(() => {
    fetchSubscriberData();
    fetchUserBilling()
    // fetchTransDetail();
    // fetchOrders();

  }, []);
  useEffect(() => {
    fetchStripeTransactions();
  }, [subscriberData]);
  const fetchSubscriberData = async () => {
    try {
      const variables = {
        filter: {
          workspace_id: {
            eq: selectedWorkspaceId,
          },
        },
      };
      const getSubData = await client.graphql({
        query: listSubscriberCards,
        variables: variables,
      });
      const res = getSubData.data.listSubscriberCards.items;
      setSubscriberData(res);
      if (!res?.[0]?.stripe_customer_id) {
        dispatch(showToast({
          message: "This page is dedicated exclusively to Stripe orders.",
          type: "Info",
        }))
      }
      await fetchStripeTransactions(res);

      return res;
    } catch (error) {
      console.error("Error creating todo:", error);
    }
  };
  const fetchAllUserBillings = async (selectedWorkspaceId) => {
    let nextToken = null;
    let allItems = [];

    const variables = {
      filter: {
        workspace_id: {
          eq: selectedWorkspaceId,
        },
      },
      limit: 1000, // You can adjust this value
    };

    do {
      const getAddressData = await client.graphql({
        query: listUserBillings,
        variables: {
          ...variables,
          nextToken: nextToken
        },
      });

      const items = getAddressData.data.listUserBillings.items;
      allItems = [...allItems, ...items];
      nextToken = getAddressData.data.listUserBillings.nextToken;
    } while (nextToken);

    return allItems;
  };
  const fetchUserBilling = async () => {
    try {
      const userBillingDetail = await fetchAllUserBillings(selectedWorkspaceId);

      setUserBillingData(userBillingDetail)

    } catch {
      console.log("Error fetch user Info")
    }
  }
  const fetchOrders = async () => {
    try {
      setLoader(true);

      if (!subscriberData?.[0]?.subscription_id) {
        console.log("No subscriber ID found");
        return;
      }

      // Function to get batches for a specific date range
      const getBatchesForDateRange = async (startDate, endDate) => {
        const batchListRequest = {
          getSettledBatchListRequest: {
            merchantAuthentication: {
              name: merchant_name,
              transactionKey: transaction_key,
            },
            firstSettlementDate: startDate.toISOString(),
            lastSettlementDate: endDate.toISOString()
          }
        };

        const response = await axios.post(authorize_api, batchListRequest);
        return response?.data?.batchList || [];
      };


      const allBatches = [];
      const endDate = new Date();
      const startDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000); // One year ago

      // Fetch batches in 30-day intervals
      for (let currentEndDate = endDate; currentEndDate > startDate;) {
        const currentStartDate = new Date(currentEndDate.getTime() - 30 * 24 * 60 * 60 * 1000);
        // Ensure we don't go beyond our target start date
        if (currentStartDate < startDate) {
          currentStartDate.setTime(startDate.getTime());
        }

        console.log(`Fetching batches from ${currentStartDate.toISOString()} to ${currentEndDate.toISOString()}`);

        const batches = await getBatchesForDateRange(currentStartDate, currentEndDate);
        allBatches.push(...batches);

        // Move the end date back by 30 days
        currentEndDate.setTime(currentStartDate.getTime());
      }

      // Get customer profile
      const profileRequest = {
        getCustomerProfileRequest: {
          merchantAuthentication: {
            name: merchant_name,
            transactionKey: transaction_key,
          },
          customerProfileId: subscriberData[0].subscription_id,
          includeIssuerInfo: true
        }
      };

      const profileResponse = await axios.post(authorize_api, profileRequest);
      const customerProfile = profileResponse?.data?.profile;
      const subscriptionIds = profileResponse?.data?.subscriptionIds || [];
      console.log(subscriptionIds, "subscriptionIds");
      // Get transactions for all batches
      const allTransactions = [];

      for (const batch of allBatches) {
        const transactionListRequest = {
          getTransactionListRequest: {
            merchantAuthentication: {
              name: merchant_name,
              transactionKey: transaction_key,
            },
            batchId: batch.batchId,
            sorting: {
              orderBy: "submitTimeUTC",
              orderDescending: true,
            },
            paging: {
              limit: "100",
              offset: "1",
            }
          }
        };

        try {
          const transResponse = await axios.post(authorize_api, transactionListRequest);
          if (transResponse?.data?.transactions) {
            // Filter transactions for current user's subscriptions
            const userTransactions = transResponse.data.transactions.filter(trans =>

              subscriptionIds.includes(trans.subscription?.id) ||
              trans?.profile?.customerProfileId === subscriberData[0].subscription_id
            );

            // Add batch info to each transaction
            const transactionsWithDetails = transResponse.data.transactions.map(trans => ({
              transId: trans.transId,
              invoiceNumber: trans.order?.invoiceNumber || "-",
              transactionStatus: trans.transactionStatus,
              submitTimeUTC: trans.submitTimeUTC,
              firstName: trans?.firstName || "",
              lastName: trans?.lastName || "",
              accountType: customerProfile?.paymentProfiles?.[0]?.payment?.creditCard?.cardType || "",
              accountNumber: `**** **** **** ${customerProfile?.paymentProfiles?.[0]?.payment?.creditCard?.cardNumber?.slice(-4)}`,
              settleAmount: trans.settleAmount,
              paymentProvider: 'authorize.net',
              batch: {
                batchId: batch.batchId
              },
              email: customerProfile.email
            }));

            allTransactions.push(...transactionsWithDetails);
          }
        } catch (transError) {
          console.error(`Error fetching transactions for batch ${batch.batchId}:`, transError);
        }
      }
      // Sort transactions by date
      const sortedTransactions = allTransactions.sort((a, b) =>
        new Date(b.submitTimeUTC) - new Date(a.submitTimeUTC)
      );
      setOrderList(sortedTransactions);

    } catch (error) {
      console.error("Error fetching orders:", error);
      dispatch(showToast({ message: "Error fetching Authorize.net transactions", type: "error" }));
    } finally {
      setLoader(false);
    }
  };

  const fetchTransDetail = async (transId) => {
    try {
      // Get transaction details
      const transDetailRequest = {
        getTransactionDetailsRequest: {
          merchantAuthentication: {
            name: merchant_name,
            transactionKey: transaction_key,
          },
          transId: transId,
        },
      };

      const response = await axios.post(authorize_api, transDetailRequest);
      const transaction = response?.data?.transaction;

      // Get customer profile details
      const profileRequest = {
        getCustomerProfileRequest: {
          merchantAuthentication: {
            name: merchant_name,
            transactionKey: transaction_key,
          },
          customerProfileId: subscriberData[0].subscription_id,
          includeIssuerInfo: true
        }
      };

      const profileResponse = await axios.post(authorize_api, profileRequest);
      const customerProfile = profileResponse?.data?.profile;

      // Combine transaction and customer profile data
      const enrichedTransDetail = {
        ...transaction,
        billTo: customerProfile?.paymentProfiles?.[0]?.billTo || {},
        paymentProvider: 'authorize.net',
        cardDetails: {
          cardType: customerProfile?.paymentProfiles?.[0]?.payment?.creditCard?.cardType,
          cardNumber: customerProfile?.paymentProfiles?.[0]?.payment?.creditCard?.cardNumber,
          expirationDate: customerProfile?.paymentProfiles?.[0]?.payment?.creditCard?.expirationDate
        },
        email: customerProfile?.email
      };

      setTransDetail(enrichedTransDetail);
    } catch (error) {
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error fetching transaction details:", error);
    }
  };

  const handleCloseTransDetailModal = () => {
    setOpenTransDetailModal(false);
    setTransDetail(null);
  };

  const handleOpenTransDetailModal = async (transId, paymentProvider) => {
    try {
      if (paymentProvider === 'stripe') {
        // Fetch Stripe payment intent details
        const [paymentIntent, refunds] = await Promise.all([
          stripeApi.get(`/payment_intents/${transId}`),
          stripeApi.get('/refunds', {
            params: {
              payment_intent: transId
            }
          })
        ]);

        const payment = paymentIntent?.data;
        const charge = payment?.charges?.data[0];
        const isRefunded = refunds?.data?.data?.length > 0;
        const refundAmount = isRefunded ?
          refunds?.data?.data?.reduce((sum, refund) => sum + refund?.amount, 0) / 100 : 0;

        // Format Stripe data to match the modal's expected structure
        const stripeTransDetail = {
          transId: payment?.id,
          submitDate: new Date(payment?.created * 1000).toISOString(),
          settleDate: charge ? new Date(charge?.created * 1000).toISOString() : null,
          transactionStatus: isRefunded ? 'refunded' :
            (payment?.status === 'succeeded' ? 'settledSuccessfully' : payment?.status),
          firstName: charge?.billing_details?.name || '',
          lastName: '',
          accountType: charge?.payment_method_details?.card?.brand || '',
          accountNumber: `**** **** **** ${charge?.payment_method_details?.card?.last4 || ''}`,
          settleAmount: payment?.amount / 100,
          refundedAmount: refundAmount,
          invoiceNumber: payment?.invoice || '-',
          paymentProvider: 'stripe',
          // Additional Stripe-specific details
          receiptUrl: charge?.receipt_url || null,
          cardExpiration: `${charge?.payment_method_details?.card?.exp_month || ''}/${charge?.payment_method_details?.card?.exp_year || ''}`,
          cardCountry: charge?.payment_method_details?.card?.country || '',
          refundHistory: refunds?.data?.data?.map(refund => ({
            id: refund?.id,
            amount: refund?.amount / 100,
            status: refund?.status,
            created: new Date(refund?.created * 1000).toISOString(),
            reason: refund?.reason,
            description: refund?.metadata?.description
          }))
        };

        setTransDetail(stripeTransDetail);
      } else {
        // Existing Authorize.net fetch
        const getRes = {
          getTransactionDetailsRequest: {
            merchantAuthentication: {
              name: merchant_name,
              transactionKey: transaction_key,
            },
            transId: transId,
          },
        };

        const response = await axios.post(authorize_api, getRes);
        setTransDetail({
          ...response?.data?.transaction,
          paymentProvider: 'authorize.net'
        });
      }

      setOpenTransDetailModal(true);
    } catch (error) {
      console.error('Error fetching transaction details:', error);
      dispatch(showToast({ message: error.message, type: "error" }));
    }
  };

  const handleCloseRefundModal = () => {
    setOpenRefundModal(false);
    setRefundAmount("");
    setIdToRefund(null);
  };

  const handleRefundAmount = (item) => {
    const currentAmount = parseFloat(item.price);
    if (item.paymentProvider === 'stripe') {
      if (item.status === 'refunded') {
        dispatch(
          showToast({
            message: "This payment has already been refunded",
            type: "error",
          })
        );
        return;
      }
    }

    setIdToRefund(item);
    setOpenRefundModal(true);
  };

  const handleConfirmRefund = async () => {
    if (refundAmount === "" || refundAmount === "0") {
      setRefundAmountError(true);
      return;
    }

    try {
      setOpenRefundModal(false);

      if (idToRefund?.paymentProvider === 'stripe') {
        const formData = new URLSearchParams();
        formData.append('payment_intent', idToRefund.transId);
        formData.append('amount', Math.round(parseFloat(refundAmount) * 100));
        formData.append('reason', 'requested_by_customer');

        formData.append('metadata[description]', refundDesc || 'Refund requested by customer');

        await stripeApi.post('/refunds', formData);
      } else {
        const refundVariable = {
          createTransactionRequest: {
            merchantAuthentication: {
              name: merchant_name,
              transactionKey: transaction_key,
            },
            transactionRequest: {
              transactionType: "refundTransaction",
              amount: `${refundAmount}.00`,
              currencyCode: "USD",
              payment: {
                creditCard: {
                  cardNumber: idToRefund?.accountNumber.slice(-4),
                  expirationDate: "XXXX",
                },
              },
              refTransId: idToRefund?.id,
              order: {
                invoiceNumber: idToRefund?.invoice || "",
                description: refundDesc,
              },
            },
          },
        };

        await axios.post(authorize_api, refundVariable);
      }

      setIdToRefund(null);
      setRefundAmount("");
      setRefundDesc("");

      dispatch(
        showToast({
          message: "Your refund operation is successfully completed",
          type: "success",
        })
      );

      if (idToRefund?.paymentProvider === 'stripe') {
        await fetchStripeTransactions(subscriberData);
      }

    } catch (error) {
      console.error('Refund error:', error);
      dispatch(
        showToast({
          message: error.response?.data?.error?.message || error.message,
          type: "error",
        })
      );
    }
  };

  const formatUtcDate = (utcDateString) => {
    const utcDate = new Date(utcDateString);
    const year = utcDate.getFullYear();
    const month = utcDate.getMonth() + 1;
    const day = utcDate.getDate();
    const hours = utcDate.getHours().toString().padStart(2, "0");
    const minutes = utcDate.getMinutes().toString().padStart(2, "0");

    return `${day}-${month
      .toString()
      .padStart(2, "0")}-${year} ${hours}:${minutes}`;
  };

  const formatString = (str) => {
    const formattedString = str
      ?.replace(/([a-z])([A-Z])/g, "$1 $2")
      ?.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
      ?.split(" ")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(" ");

    return formattedString;
  };

  const fetchStripeTransactions = async () => {
    try {
      setLoader(true);
      if (!subscriberData?.[0]?.stripe_customer_id) {
        console.log("No Stripe customer ID found");
        return;
      }
      const [paymentIntents, subscriptions] = await Promise.all([
        stripeApi.get('/payment_intents', {
          params: {
            customer: subscriberData?.[0]?.stripe_customer_id,
            limit: 1000,
          }
        }),
        stripeApi.get('/subscriptions', {
          params: {
            customer: subscriberData?.[0]?.stripe_customer_id,
            status: 'active',
          }
        })
      ]);

      const invoiceToRenewalMap = {};
      subscriptions?.data?.data?.forEach(subscription => {
        if (subscription?.latest_invoice) {
          invoiceToRenewalMap[subscription?.latest_invoice] = new Date(subscription?.current_period_end * 1000);
        }
      });
      const formattedStripeOrders = await Promise.all(paymentIntents?.data?.data.map(async (payment) => {
        const refunds = await stripeApi.get('/refunds', {
          params: {
            payment_intent: payment?.id
          }
        });

        const isRefunded = refunds?.data?.data?.length > 0;
        const refundAmount = isRefunded ?
          refunds?.data?.data?.reduce((sum, refund) => sum + refund?.amount, 0) / 100 : 0;

        const nextRenewalDate = payment?.invoice ? invoiceToRenewalMap[payment?.invoice] : null;
        return {
          transId: payment?.id,
          invoiceNumber: payment?.invoice || '-',
          transactionStatus: isRefunded ? 'refunded' :
            (payment?.status === 'succeeded' ? 'settledSuccessfully' : payment?.status),
          submitTimeUTC: new Date(payment?.created * 1000).toISOString(),
          firstName: payment?.charges?.data[0]?.billing_details?.name || '',
          lastName: '',
          accountType: payment?.charges?.data[0]?.payment_method_details?.card?.brand,
          accountNumber: `**** **** **** ${payment?.charges?.data[0]?.payment_method_details?.card?.last4 || ''}`,
          settleAmount: payment?.amount / 100,
          refundedAmount: refundAmount,
          paymentProvider: 'stripe',
          nextRenewal: nextRenewalDate,
          status: isRefunded ? 'refunded' : 'settledSuccessfully'
        };
      }));

      setStripeOrders(formattedStripeOrders);


    } catch (error) {
      console.error("Error fetching Stripe transactions:", error);
      dispatch(showToast({ message: "Error fetching some transaction data", type: "error" }));
    } finally {
      setLoader(false);
    }
  };

  const columns = [
    { field: "id", hide: true },
    {
      field: "transId",
      headerName: "Trans ID",
      width: 150,
      renderCell: (params) => (
        <p
          style={{
            margin: "0px",
            textDecoration: "underline",
            color: "#1976d2",
            cursor: "pointer",
          }}
          onClick={() => handleOpenTransDetailModal(params.value, params.row.paymentProvider)}
        >
          {params.value}
        </p>
      ),
    },
    {
      field: "invoice",
      headerName: "Invoice Number",
      width: 200,
      renderCell: (params) => params.value,
    },
    {
      field: "status",
      headerName: "Trans Status",
      width: 250,
      renderCell: (params) => {
        let displayStatus = params.value;
        let style = statusStyleInActive;

        if (params.value === "settledSuccessfully") {
          style = statusStyleActive;
        } else if (params.value === "refunded") {
          style = {
            ...statusStyleActive,
            color: "#FFA500"
          };
          displayStatus = "Refunded";
        }

        return (
          <p style={style}>
            {formatString(displayStatus)}
          </p>
        );
      },
    },
    {
      field: "submitDate",
      headerName: "Submit Date",
      width: 150,
      renderCell: (params) => formatUtcDate(params.value),
    },
    {
      field: "customer",
      headerName: "Customer",
      width: 150,
      renderCell: (params) => `${params?.row?.firstName}`,
    },
    {
      field: "card",
      headerName: "Card",
      width: 100,
      renderCell: (params) => (
        <p style={{ margin: "0px" }}>{formatString(params.value)}</p>
      ),
    },
    {
      field: "accountNumber",
      headerName: "Payment Method",
      width: 150,
      renderCell: (params) => params.value,
    },
    {
      field: "price",
      headerName: "Settlement Amount",
      width: 150,
      renderCell: (params) =>
        `$ ${Number(params.value ?? 0)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          .toLocaleString("en-US")}`,
    },

    {
      field: "nextRenewal",
      headerName: "Next Renewal",
      width: 150,
      renderCell: (params) => params.value ? formatUtcDate(params.value) : '-',
    },

    {
      field: "paymentProvider",
      headerName: "Payment Provider",
      width: 150,
      renderCell: (params) => (
        <p style={{ margin: "0px" }}>
          {params.value === 'stripe' ? 'Stripe' : 'Authorize.net'}
        </p>
      ),
    },
    {
      field: "refundedAmount",
      headerName: "Refunded Amount",
      width: 150,
      renderCell: (params) =>
        params.value ? `$ ${params.value.toFixed(2)}` : '-',
    }, {
      field: "refund",
      headerName: userData?.role === "super_admin" && "Refund Transaction",
      width: 200,
      renderCell: (params) => (
        <Grid container style={{ height: "100%" }}>
          {userData?.role === "super_admin" && (
            <>
              <Button
                disabled={!params.row.status}
                variant="outlined"
                style={buttonStyle}
                onClick={() => handleRefundAmount(params.row)}
              >
                Refund
              </Button>
            </>
          )}
        </Grid>
      ),
    },
  ];

  const rows = [
    ...(orderList?.map((item) => ({
      id: item.transId,
      transId: item.transId,
      invoice: item.invoiceNumber || "-",
      status: item.transactionStatus,
      submitDate: item.submitTimeUTC,
      firstName: item.firstName,
      lastName: item.lastName,
      card: item.accountType,
      accountNumber: item.accountNumber,
      price: item.settleAmount,
      paymentProvider: 'authorize.net',
      nextRenewal: null
    })) || []),
    ...(stripeOrders?.map((item) => ({
      id: item.transId,
      transId: item.transId,
      invoice: item.invoiceNumber,
      status: item.transactionStatus,
      submitDate: item.submitTimeUTC,
      firstName: item.firstName,
      lastName: item.lastName,
      card: item.accountType,
      accountNumber: item.accountNumber,
      price: item.settleAmount,
      paymentProvider: item.paymentProvider,
      nextRenewal: item.nextRenewal,
      refundedAmount: item.refundedAmount
    })) || [])
  ];

  return (
    <>
      <div style={container}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div>
              <p style={title}>Orders</p>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card style={organizationCard}>
              {loader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "50vh",
                  }}
                >
                  <PulseLoader color="#ACD6EB" />
                </div>
              ) : (
                <>
                  {isMobile ? (
                    <div
                      className="tableInnerWrapper"
                      style={{ background: "white" }}
                    >
                      <div className="table-info">
                        <table className="d-table">
                          <thead>
                            <tr>
                              <th style={{ width: "170px" }}>Title</th>
                              <th>Name</th>
                              <th>Created At</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {orderList?.length === 0 || !orderList ? (
                            <>
                              {" "}
                              <tbody>
                                <tr className="norecord">
                                  <td
                                    colSpan={7}
                                    style={{ textAlign: "center" }}
                                  >
                                    No Record Found
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          ) : (
                            <>
                              <tbody>
                                {orderList?.length > 0 &&
                                  orderList?.map((item, index, array) => (
                                    <>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginBottom: 8,
                                          marginTop: 8,
                                        }}
                                      >
                                        <div>
                                          <div className="td-name">
                                            <div className="itemtitle">
                                              <div className="td-name">
                                                Name:{" "}
                                                <span
                                                  style={{
                                                    ...nameContainerStyle,
                                                    color: "#359cd2",
                                                  }}
                                                >
                                                  {`${item.lastName}, ${item.firstName}`}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="itemtitle">
                                              <div className="td-name">
                                                Invoice Number:{" "}
                                                <span
                                                  style={{
                                                    ...nameContainerStyle,
                                                    color: "#359cd2",
                                                  }}
                                                >
                                                  {`${item.invoiceNumber || "-"
                                                    }`}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="itemtitle">
                                              <div className="td-name">
                                                Transaction Status:{" "}
                                                <span
                                                  style={{
                                                    ...nameContainerStyle,
                                                    color:
                                                      item.status ===
                                                        "settledSuccessfully"
                                                        ? "#1c984d"
                                                        : "#E35244",
                                                  }}
                                                >
                                                  {formatString(
                                                    item.transactionStatus
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="itemtitle">
                                              <div className="td-name">
                                                Submit Date:{" "}
                                                <span
                                                  style={{
                                                    ...nameContainerStyle,
                                                    color: "#359cd2",
                                                  }}
                                                >
                                                  {`${formatUtcDate(
                                                    item.submitTimeUTC
                                                  )}`}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="td-name">
                                            <div className="itemtitle">
                                              <div className="td-name">
                                                Card:{" "}
                                                <span
                                                  style={{
                                                    ...nameContainerStyle,
                                                    color: "#359cd2",
                                                  }}
                                                >
                                                  {`${item.accountType || "-"}`}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="itemtitle">
                                              <div className="td-name">
                                                Payment Method:{" "}
                                                <span
                                                  style={{
                                                    ...nameContainerStyle,
                                                    color: "#359cd2",
                                                  }}
                                                >
                                                  {`${item.accountNumber}`}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="itemtitle">
                                              <div className="td-name">
                                                Settlement Amount:{" "}
                                                <span
                                                  style={{
                                                    ...nameContainerStyle,
                                                    color: "#359cd2",
                                                  }}
                                                >
                                                  ${` ${item.settleAmount}`}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      {index < orderList.length - 1 && (
                                        <Divider />
                                      )}
                                    </>
                                  ))}
                              </tbody>
                            </>
                          )}
                        </table>
                      </div>
                    </div>
                  ) : (
                    <>
                      {(rows && rows.length > 0) ? (
                        <DataGrid
                          rows={rows}
                          columns={columns.filter((column) => column.field !== "id")}
                          getRowId={(row) => row.id}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 7,
                              },
                            },
                          }}
                          pageSizeOptions={[7, 25, 50, 100]}
                          density="comfortable"
                          sx={{
                            border: 0,
                            borderColor: "primary.light",
                            "& .MuiDataGrid-cell:hover": {
                              color: "primary.main",
                            },
                          }}
                        />
                      ) :

                        <Box sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "50vh",
                        }}>

                          <p
                            colSpan={7}
                            style={{ textAlign: "center" }}
                          >
                            No Record Found
                          </p>
                        </Box>
                      }
                    </>
                  )}
                </>
              )}
            </Card>
          </Grid>
        </Grid>
        <Dialog
          open={openRefundModal}
          disableBackdropClick
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Refund Details</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div>
                <TextField
                  error={refundAmountError}
                  size="small"
                  type="number"
                  fullWidth
                  style={{ marginTop: "1rem", width: "100%" }}
                  label="Refund Amount"
                  inputProps={{ min: 0 }}
                  value={refundAmount}
                  onChange={(e) => {
                    setRefundAmount(e.target.value);
                    setRefundAmountError(false);
                  }}
                />
                <TextField
                  size="small"
                  type="text"
                  fullWidth
                  multiline
                  rows={3}
                  style={{ marginTop: "1rem", width: "100%" }}
                  label="Reason (Optional)"
                  value={refundDesc}
                  onChange={(e) => {
                    setRefundDesc(e.target.value);
                  }}
                />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ margin: "0.5rem 0.5rem" }}>
            <Button
              onClick={handleCloseRefundModal}
              variant="contained"
              style={{ textTransform: "inherit" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleConfirmRefund}
              style={{ textTransform: "inherit" }}
            >{`Refund ${`$${Number(refundAmount)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              .toLocaleString("en-US")}`}`}</Button>
          </DialogActions>
        </Dialog>
        <TransactionDetailModal
          handleCloseTransDetailModal={handleCloseTransDetailModal}
          openTransDetailModal={openTransDetailModal}
          transDetail={transDetail}
          formatString={formatString}
          formatUtcDate={formatUtcDate}
          userBillingData={userBillingData}
        />
        {!isMobile && (
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Footer />
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default Order;